import React from "react";
import { observer } from "mobx-react";
import "primeflex/primeflex.css";    
import { BookOfBusinessViewModel } from "./BookOfBusinessViewModel";
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import "./BookOfBusiness.css"
export const BookOfBusinessView: React.FC<{
  vm: BookOfBusinessViewModel;
}> = observer((props) => {
  const vm = props.vm;
 
  return (
     <>
     {Utils.hasUserPermission(Permission.ViewBookOfBusiness) ? (
       <>
         <div className="p-col-12 p-md-10 p-lg-10">
           <div className="screen-main-title">Book Of Business</div>
         </div>

         <div className="p-card-body rts-search-wrap"> 
         </div> 
       </>
     ) : (
       <Card>
         <IMASMessagesHandler response={Utils.errorMsg()} />
       </Card>
     )}
     </>
  );
});
