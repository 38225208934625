import { observer } from "mobx-react";
import React, { createRef } from "react";
import classNames from "classnames";
import { Repeater } from "../Repeater";
import { Calendar } from "primereact/calendar";

export const CustomInputDate: React.FC<{
  dateFormat: "mm/dd/y" | "mm/dd/yy" | "mm/dd/yyyy" | "mmddyyyy";
  errors: string[] | undefined;
  label: string;
  id: string;
  isrequired: boolean;
  disabled?: boolean;
  isShowTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  value: Date | undefined;
  className?: string;
  keepInvalid?: boolean | undefined;
  placeholder?: string;
  monthNavigator?: boolean;
  yearNavigator?: boolean;
  showIcon?: boolean;
  yearRange?: string;
  showOnFocus?: boolean;
  onChange: (value: Date | undefined) => void;
}> = observer((props) => {
  let dateref = createRef<any>();
  const getClassNameError = () => {
    if (props.errors)
      return classNames({
        "p-error": props.errors !== null && props.errors.length > 0,
      });
    else return classNames("");
  };

  setTimeout(() => {
    if (dateref.current && dateref.current.panel) {
      dateref.current.panel.style.visibility = "hidden";
    }
  });

  const onKeyDown = (e: any) => {
    const cal = document.getElementById(props.id);
    let value: any;
    const separator = "/";
    const keyCode = e.keyCode;
    if (cal) {
      let input = cal.querySelector("input");
      setTimeout(() => {
        if (input && input.value.length < 11) {
          var textSoFar = input.value;
          console.log("input", input.value, keyCode);
          if (keyCode != 191) {
            if (keyCode != 8) {
              // when not backspace
              if (textSoFar.length == 2 || textSoFar.length == 5) {
                value = textSoFar + separator;
                input.value = value;
              } else if (keyCode == 86 && textSoFar.length == 8) {
                //to handle copy & paste of 8 digit
                value =
                  textSoFar.substr(0, 2) +
                  separator +
                  textSoFar.substr(2, 2) +
                  separator +
                  textSoFar.substr(4, 4);
                input.value = value;
              }
            } else {
              //  when backspace
              if (textSoFar.length == 5) {
                value = textSoFar.substring(0, 4);
              } else if (textSoFar.length == 2) {
                value = textSoFar.substring(0, 1);
              } else {
                value = textSoFar.substring(0, textSoFar.length);
              }
              input.value = value;
            }
          } else {
            value = textSoFar.substring(0, textSoFar.length - 1);
            console.log("last else", value);
            input.value = value;
          }
        }
        if (input && input.value.length === 0) {
          input.value = "";
        }
      }, 300);
    }
  };

  return (
    <>
      <label htmlFor={props.id}>
        {props.label} {props.isrequired && <span className="required">*</span>}{" "}
      </label>

      <div id={"div" + props.id} onKeyDown={onKeyDown}>
        <Calendar
          showTime={props.isShowTime}
          value={props.value}
          id={props.id}
          ref={dateref}
          className={props.className ? props.className : getClassNameError()}
          monthNavigator={props.monthNavigator || true}
          yearNavigator={props.yearNavigator || true}
          yearRange={props.yearRange || "1910:2040"}
          onChange={(event) => {
            if (event.value instanceof Date) {
              props.onChange(event.value);
            }
            else {
                const call = document.getElementById(props.id);
                if (call) {
                    var inputs = call.querySelector("input");
                    if (inputs && inputs.value.length === 0) {
                        props.onChange(undefined);
                    }
                }
            }
            setTimeout(() => {
              let cal = document.getElementById(props.id);
              if (cal) {
                let input = cal.querySelector("input");
                if (input) {
                  input.focus();
                }
              }
            }, 0);
          }}
          showButtonBar={true}
          todayButtonClassName={"custom-today"}
          disabled={props.disabled}
          showIcon={props.showIcon || true}
          minDate={props.minDate ? props.minDate : new Date("01/01/1910")} // as per request from QA, min date from 1910
          maxDate={props.maxDate ? props.maxDate : new Date("12/31/2040")} // as per request from QA,max date from 2040
          showOnFocus={props.showOnFocus || false}
          hourFormat={"12"}
          keepInvalid={props.keepInvalid}
          placeholder={props.placeholder || ""}
          onFocus={(e) => {
            const div = document.getElementById(props.id) as HTMLElement;
            if (div) {
              let dateDiv = div.querySelector(
                ".p-inputtext"
              ) as HTMLInputElement;
              if (dateDiv) {
                dateDiv.select();
              }
            }
          }}
        />
      </div>
      <Repeater
        errors={props.errors}
        viewId={props.id}
        child={(error) => <div className="invalid-feedback">{error}</div>}
      />
    </>
  );
});
