import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import ContractsStore from "../../store/ContractsStore";
import {
  CertificationsDropDownValuesResponse,
  CertificationsByAgentListModel,
} from "../../services/ContractService";
import Utils from "../../infrastructure/Utils";
import PageContext from "../../infrastructure/PageContext";
export class AgentCertificationsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @action Load = async (id: number) => {
    this.showInactiveCarriers = false;
    this.agentId = id
    this.defaultSelectedCompany = "0";
      this.defaultSelectedYear = "2024"; // new Date().getFullYear().toString();
    await this.loadItems()
    await this.certificationsDropDownValues();
  };

  @observable defaultSelectedCompany: string = "0";
  @observable
  defaultSelectedYear: string = new Date().getFullYear().toString();
  @observable CertificationList = new Collection<CertificationsByAgentListModel>();
  @observable rows: number = 5;
  @observable totalRecords: number = 100;
  @observable totalRecordsForCertList: number = 0;
  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable sortCert: boolean = true;
  @observable sortColumnCert: string = "company";
  @observable certId: number = 0;
  @observable agentId: number = 0;

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
             
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable yearList = new Collection<{
    label: string;
    value: string;
  }>();

  @action loadItems = async () => {
      try{
    let year = this.defaultSelectedYear ? Number(this.defaultSelectedYear) : 0;
    let companyId = this.defaultSelectedCompany
      ? Number(this.defaultSelectedCompany)
      : 0;
    let agentType = this.agentId ? Number(this.agentId) : 0;
    let searchText = "";
    let certList =  await ContractsStore.getAllCertificationsByAgent(
      year,
      companyId,
      agentType,
      searchText,
      this.startIndex,
      this.rows,
      this.sortColumnCert,
      this.sortCert
    );

    if (certList) {
        if (certList.recordCount) {
          this.totalRecordsForCertList = certList.recordCount;
          if (certList.data)
            this.CertificationList.values = certList.data;
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
    }
    catch(e){

    }
  };

  @action search = async () => {
    await this.loadItems();
  };

  @action reset = async () => {
    this.defaultSelectedCompany = "0";
    this.defaultSelectedYear = new Date().getFullYear().toString();
    await this.loadItems();
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    }
    else {
      return [];
    }
  }

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList.values = []; 
    this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  protected certificationsDropDownValues = async () => {
    setTimeout(() => {
      PageContext.setIsLoadingVisible(false);
    }, 3000);
    let result = await ContractsStore.certificationsDropDownValues();
    
    if (result) {
      let response: any = result;
      if (response.company) {
        this.companyList.values = [];
        this.companyList.values = this.mapListItemAndSort(response.company)
        this.companyList.values.splice(0, 0, { label: "All", value: "0" });
        this.carriersInitial.values = this.companyList.values;
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
        this.defaultSelectedCompany = "0";
      } else {
        this.companyList.values = [];
        this.companyList.values.splice(0, 0, { label: "All", value: "0" });
        this.defaultSelectedCompany = "0";
      }
      if (response.year) {
        this.yearList.values = []

        this.yearList.values = this.mapListItemAndSort(response.year)
        this.yearList.values.splice(0, 0, { label: "All", value: "0" });
      } else {
        this.yearList.values = [];
        this.yearList.values.splice(0, 0, { label: "All", value: "0" });
        this.defaultSelectedYear = "0";
      }
    }
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    //load method
    this.loadItems()
  }
  @action setSelectedCert = (certId: number) => {
    this.certId = certId;
  };

  @action setSortOrder() {
    this.sortCert = !this.sortCert;
  }
  @action setSortColumn(column: string) {
    this.sortColumnCert = column;
  }
  @action noRecordsFound() {
    this.totalRecordsForCertList = 0;
    this.CertificationList.values = [];
  }
}
