import { AbstractValidator } from "fluent-ts-validator";
import { observable, action, toJS } from "mobx";
import RouteList from "../../../infrastructure/RouteList";
import { routes } from "../../../router";
import Utils from "../../../infrastructure/Utils";
import UserContext from "../../../infrastructure/UserContext";
import { BaseAddEditViewModel } from "../../components/BaseAddEditViewModel";
import AgentStateLicensesStore from "../../../store/AgentStateLicensesStore";
import ContractsStore from "../../../store/ContractsStore";
import Collection from "../../../infrastructure/CollectionHelper";
import DocumentStore from "../../../store/DocumentStore";
import { AgentListModel } from "../../../services/ProducerSearchService";
import { CustomAttributesComponentViewModel } from "../../../views/customers/CustomAttributesComponentViewModel";
import { Permission } from "../../../infrastructure/enum/Permission";
import CustomAttributeStore from "../../../store/CustomAttributeStore";
import PageContext from "../../../infrastructure/PageContext";
import { Constants } from "../../../infrastructure/enum/Constants";
import { CustAttrToEntityModel } from "../../../services/ContractService";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../views/components/controls/IMASTemplates";

export class StateLicensesEditViewModel extends BaseAddEditViewModel {
  @observable IssueDate: Date | undefined;
  @observable ExpirationDate: Date | undefined;
  @observable agentId: number = 0;
  @observable stateLicense: string = "";
  @observable RenewalDate: Date | undefined;
  @observable notes: string = "";
  @observable isValid = true; 
  @observable response: any;
  @observable isCustomAttrActive: boolean = false;
  @observable isValidationToasterVisible: boolean = false;
  @observable resultDuplicate: boolean = false;
  @observable IssueDateCheck: Date | undefined | null = null;
  constructor() {
    super(
      "State License",
      routes.agentStateLicenses,
      new StateLicenseValidator()
    );
  }
  @observable stateList: any = [{ label: "Select Select", value: "0" }];
  @observable productLinesList: any;

  @observable StatusList: any = [{ label: "Select", value: "0" }];
  @observable selectedState: string = "";
  @observable selectedProductline: any;
  @observable stateLicenseId: number = 0;
  @observable selectedResidencyStatus: string = "0";
  @observable selectedStatus: string = "0";
  @observable yearRange: string = "";
  @observable ResidencyStatusList: any = [{ label: "Select", value: "0" }];

  @observable FilteredDocumentList = new Collection<any>();
  @observable documentList = new Collection<any>();
  @observable selectedDocument: string = "";
  @observable selectedDocumentId: number = 0;
  @observable isException: boolean = false;
  @observable exceptionMessage: any;
  @observable item: any;
  //custom attribute states
  @observable addAttributesModal: boolean = false;
  @observable CustomAttributesComponentViewModel: CustomAttributesComponentViewModel = new CustomAttributesComponentViewModel();
  @observable attributesScreenPath: number = 1;
  @observable attributesScreenPathId: number = 1;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();
  @observable customAttributeList: any = [];
    

  protected resetModel() {
    this.selectedProductline = [];
    this.selectedState = "0";
    this.IssueDate = undefined;
    this.ExpirationDate = undefined;
    this.RenewalDate = undefined;
    this.stateLicense = "";
    this.selectedResidencyStatus = "0";
    this.selectedStatus = "0";
    this.notes = "";
    this.documentList.values = [];
    this.selectedDocument = "";
    this.selectedDocumentId = 0;
    this.isCustomAttrActive = false;
    PageContext.isCustomAttribute = false;
    this.response = "";
  }
  protected async checkPermissions(): Promise<void> {
    localStorage.removeItem("CustomAttribute");
    // get Custom attribute screen permission
    var res = await this.getCustomAttributePermissionByScreen();
    if (res) {
      let data = res.filter(x => x.path === Permission.AddEditStateLicensesScreen
        && x.active === true)
      this.isCustomAttrActive = data.length > 0 ? true : false;

      var attributes = await this.getCustomAttributesByScreen(data[0].id || 1);
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.attributesScreenPathId = data[0].id || 1;
    }


  }

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    }
    catch (e) {
      throw e;
    }

  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(screenId, this.stateLicenseId);
      return res;
    }
    catch (e) {
      throw e;
    }

  }
  protected loadItem = async (Id: number): Promise<void> => {
    this.stateLicenseId = Id;
    let endYear = new Date().getFullYear() + 20;
    this.yearRange = new Date().getFullYear() - 20 + ":" + endYear;
    if (UserContext.userId && UserContext.userId > 0) {
      await this.loadLookups();

      var model = await AgentStateLicensesStore.getStateLicenseDetail(
        this.stateLicenseId
      );
      this.agentId = model.agentId || 0;
      localStorage.setItem("agentId", "" + this.agentId);
      this.selectedProductline =
        model.licenseLineOfAuthorityIds &&
          model.licenseLineOfAuthorityIds.length > 0
          ? model.licenseLineOfAuthorityIds.map((i: any) => i.toString())
          : [];
      this.selectedState = model.stateId!.toString();
      var issueDate = Utils.getDateInFormat(
        model.issueDate && Id !== 0 ? model.issueDate : undefined
        );
        this.IssueDate = new Date(issueDate);
        this.IssueDateCheck = issueDate ? new Date(issueDate) : null;
      var expirationDate = Utils.getDateInFormat(
        model.expirationDate && Id !== 0 ? model.expirationDate : undefined
      );
        this.ExpirationDate = new Date(expirationDate);
      this.stateLicense = model.stateLicense || "";
      this.selectedResidencyStatus = "" + model.residencyStatusId || "0";
      this.selectedStatus = "" + model.stateLicenseStatusId || "0";
      var renewalDate = Utils.getDateInFormat(
        model.renewalDate && Id !== 0 ? model.renewalDate : undefined
      );
      this.RenewalDate = new Date(renewalDate);
      this.notes = model.notes!.toString();
      this.documentList.values = model.documentAttachmentList || [];
    }
  };

  protected toServiceModel() {
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    if (attrData) {
      result = JSON.parse(attrData);
      let custAttrToEntityData: any = [];
      this.custAttrToEntityList.values =[];
      result.forEach((result: any) => {
        var data = {
          customAttributeScreenId: result.custAttrScrnId,
          entityId: this.attributesScreenPathId,
          value: result.attrValue+''
        };
        if (result.attrValue != null && result.attrValue != "" && result.attrValue != undefined) {
          custAttrToEntityData.push(data);
        }

      });
      this.custAttrToEntityList.values = custAttrToEntityData;
    }
    return {
      id: this.selectedId,
      issueDate: Utils.getValidDate(this.IssueDate),
      expirationDate: Utils.getValidDate(this.ExpirationDate),
      agentId: this.agentId,
      stateId: parseInt(this.selectedState),
      licenseLineOfAuthorityIds:
        this.selectedProductline.length > 0
          ? this.selectedProductline.map((i: any) => parseInt(i))
          : [],
      stateLicense: this.stateLicense,
      residencyStatusId: +this.selectedResidencyStatus,
      renewalDate: Utils.getValidDate(this.RenewalDate),
      stateLicenseStatusId: +this.selectedStatus,
      notes: "" + this.notes,
      documentAttachmentList: this.documentList.values || undefined,
    };
  }
  protected async loadLookups(): Promise<void> {
    this.documentList.values = [];
    if (UserContext.userId && UserContext.userId > 0) {
      let result = await AgentStateLicensesStore.getAllStatesLookup();

      if (result) {
        var companies = [...result].sort(Utils.compareListItem);
        for (let item of companies) {
          this.stateList.push({
            label: "" + item.abbreviation,
            value: "" + item.stateId,
            isHeader: item.isHeader,
          });
        }
      }
      let banks = await AgentStateLicensesStore.getProductLinesLookup();

      if (banks) {
        this.productLinesList = Utils.mapListItemsAsList(banks || [], "", "");
      }
      // Load Residency status
      var statuses = await ContractsStore.getAllResidencyStatus();
      if (result) {
        this.ResidencyStatusList = [];
        this.ResidencyStatusList = [{ label: "Select", value: "0" }].concat(
          this.mapListItemAndSort(statuses)
        );
      }
      // Load State License status
      var licenseStatuses = await ContractsStore.getAllStateLicenseStatus();
      if (result) {
        this.StatusList = [];
        this.StatusList = [{ label: "Select", value: "0" }].concat(
          this.mapListItemAndSort(licenseStatuses)
        );
      }
    }
  }

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action hideValidationToaster = () => {
    this.isValidationToasterVisible = false;
    toast.dismiss("validation-toaster");
  };

  protected async checkStateLicenseDuplicateByAgent() { 
    this.resultDuplicate = await AgentStateLicensesStore.checkStateLicenseDuplicateByAgent(this.item.id, this.item.agentId, this.item.stateId, this.item.issueDate, undefined,
    this.item.stateLicense, undefined, undefined, this.item.stateLicenseStatusId, undefined, this.item.licenseLineOfAuthorityIds, undefined, undefined, undefined, undefined);
    if (!this.resultDuplicate) {
      if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
        Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
        this.item.customAttributes = await this.createUpdateAttributes();
      } 

      if (this.item.id == 0){ 
        await AgentStateLicensesStore.addStateLicense(this.item);
      }else{  
        await AgentStateLicensesStore.editStateLicense(this.item);
      }
    } else {
        var data = "A state License record with the same details already exists.";
        toast.warning(`${data}`, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: true,
            closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
            className: "validation-toaster contract",
            draggable: false,
            toastId: "validation-toaster",
        });
    }    
  };

  protected async addItem(): Promise<void> {
    PageContext.isMessageVisible = false;
    var item = this.toServiceModel();
      if (item) {
          await this.checkStateLicenseDuplicateByAgent();
          PageContext.currentPage = this.resultDuplicate;
      }
  }

  protected async updateItem(): Promise<void> {
    PageContext.isCustomAttribute = false;
    PageContext.isMessageVisible = false;
    this.response = "";
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    this.isValid = true;
    if (attrData) {
      result = JSON.parse(attrData);
      var mandatoryCustomAttributeData = result.filter((x: any) => x.isMandatory == true && (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined));
        if (mandatoryCustomAttributeData != null && mandatoryCustomAttributeData.length > 0 && (Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute))) {
        PageContext.isCustomAttribute = true;
        this.isValid = false;
        window.scrollTo(0, 0);
        var responseModel = {
          status: 400,
          title: Constants.CustomAttributeValidationMessage,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    }
    if (this.isValid) {
        this.item = this.toServiceModel();
        if (this.item) {
            await this.checkStateLicenseDuplicateByAgent();
            PageContext.currentPage = this.resultDuplicate;
        }
    }
  };

  @action cancel() {
    localStorage.removeItem("CustomAttribute");
    routes.agentStateLicenses.replace({
      agentId: localStorage.getItem("agentId") || "0",
    });
  }
  @action setSelectState(value: string) {
    this.selectedState = value;
  }
  @action setSelectedProductline(value: string) {
    this.selectedProductline = value;
  }
  @action setNotes(value: string) {
    this.notes = value;
  }
  @action
  private loaded = () => {
    this.loading = false;
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };
  @observable
  private loading = true;

  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getAgentDocuments(
      parseInt("" + localStorage.getItem("agentIdForStateTab")) || 0,
      0,
      0,
      this.selectedDocument,        
      0,
      100,
      "id",
      false,
      Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments) ? null : false,
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result && result.recordCount && result.data) {
      result.data =
        this.documentList &&
        this.documentList.values &&
        result.data.filter((i) => {
          return (
            this.documentList.values.find(
              (j: {
                documentName: string | undefined;
                fileType: string | undefined;
                isDeleted: any;
              }) =>
                j.documentName == i.documentName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }

    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, {
            id: -1,
          });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action downloadDocumentLink = async (docId: number) => {
    this.isLoading = true;
    this.isException = false;
    this.exceptionMessage = {};
    try {
      var result = await DocumentStore.getDocumentLink(docId, 0);
      if (result != null) {
        if (result.pdfData) {
          Utils.downloadDocument(
            result.pdfData,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : "document.pdf",
            result.actualFileName ? result.actualFileName : ""
          );
        } else {
          if (result.message && result.message !== null) {
            var responseModel = {
              status: 400,
              title: result.message,
              errors: { "": [] },
            };
            this.exceptionMessage = JSON.stringify(responseModel);
            this.isException = true;
          }
        }
      }
    } catch (e: any) {
      this.isException = true;
      this.exceptionMessage = e.response;
    }
    this.isLoading = false;
  };

  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;
    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter(
        (item: any) => {
          return item.rowId != rowId;
        }
      );
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item: any) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
  }

  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }
  
  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value;
    setTimeout( async() => {
      value && this.CustomAttributesComponentViewModel.loadExistingAttributes(1);
      !value &&     this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900); 
  }

  @action AttributeSave =(type:number) =>{
     let data = this.CustomAttributesComponentViewModel.saveAttribute(type); 
     this.addAttributesModal = false; 
     
    setTimeout( async() => {
      var attributes = await this.getCustomAttributesByScreen(1);
      if (attributes) { 
        this.customAttributeList = attributes;
      } 
      this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);  
  }
  @action createUpdateAttributes = async () => {
    let data: any = []; 
    (this.customAttributeList || []).map((item: any) => { 
        item.attrValue = item.dataTypeId == 6 ? (item.attrValue ? Utils.getDateInFormat(new Date(item.attrValue)) : "") : item.attrValue;
        data.push({
            customAttributeScreenId: item.scrnCustAttrId || 0,
            entityId: this.stateLicenseId || 0,
            value: item.attrValue + "",
        });
    });
    return data;
  };
}

class StateLicenseValidator extends AbstractValidator<StateLicensesEditViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedState)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Please select State");
    this.validateIfString((input) => input.selectedProductline)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Please select Line of Authority");
    this.validateIfString((input) => input.stateLicense)
      .isNotEmpty()
      .isNotEqualTo("-1")
      .withFailureMessage("Enter State License #");
    this.validateIfDate((input) => input.IssueDateCheck)
      .isNotEmpty()
      .isNotNull()
      .withFailureMessage("Please select Start Date");
    this.validateIfString((input) => input.selectedStatus)
      .isNotEmpty()
      .isNotEqualTo("0")
      .withFailureMessage("Please select Status");
  }
}
