
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { AutoDialerConstants } from "../../infrastructure/enum/Constants";
import IMASLog from "../../infrastructure/IMASLog";
import Utils from "../../infrastructure/Utils";
import { Constants } from "../../infrastructure/enum/Constants";
import { Permission } from '../../infrastructure/enum/Permission';
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";
import { AutoDialerViewModel } from "../../viewModels/autoDialers/AutoDialerViewModel";
import { CustomDropdown } from "../components/controls/IMASDropdown";

import ActiveCallListView from "./ActiveCallListView";
import ExistingCallListView from "./ExistingCallListView";
import ProspectiveCallListView from "./ProspectiveCallListView";
import { CustomInputText } from "../components/controls/IMASInputText";
import CreateCustomCallListView from "./CreateCustomCallListView";

export const AutoDialerView: React.FC<{
    vm: AutoDialerViewModel
}> = observer(props => {
    const callingTypeChange = (value: string) => {
        props.vm.selectedCallingType = value;
        if (props.vm.selectedCallingType === AutoDialerConstants.Active) {
            props.vm.activeCallListViewModel.Load();
        }
        else if (props.vm.selectedCallingType === AutoDialerConstants.Existing) {
            props.vm.existingCallListViewModel.Load();
        }
        else if (props.vm.selectedCallingType === AutoDialerConstants.Prospective) {
            props.vm.propectiveCallListViewModel.Load();
        }
        else if (props.vm.selectedCallingType === AutoDialerConstants.CreateCustom) {
            props.vm.createCustomCallListViewModel.callerViewModel=props.vm;
            props.vm.createCustomCallListViewModel.Load();

        }

    };
    const warning = () => {
        let response = {
            status: 400,
            title: Constants.genericView,
            errors: { "": [] },
        };
        return JSON.stringify(response);
    };
    const onSort = (column: any) => {
        IMASLog.log("sort function " + JSON.stringify(column));
        var obj = JSON.parse(JSON.stringify(column));
        props.vm.setSortOrder();
        props.vm.setSortColumn(obj.sortField);
        props.vm.previewResult();
    };
    const onPageHandle = (e: { first: number; rows: number; }) => {
        props.vm.onPage(e.first, e.rows);
    };

    const backToFilter = () => {
        props.vm.isPreviewResultView = false;
    }
    const onMarketingEventChange = (value: string) => {
        props.vm.marketingEvent = parseInt(value);
    }
    const onCallListNameChange = (value: string) => {
        props.vm.callListName = value;
    }
    return (
        <>
            <div className="p-fluid">
                <div className="p-grid screen-title">
                    <div className="p-col-12 p-md-6 p-lg-6 t-table">
                        {props.vm.isPreviewResultView && <div className="back-icon t-cell">
                            <i className="fa fa-bx fa-arrow-circle-o-left cursor-pointer icon-fill-color"
                                onClick={() => { backToFilter() }} aria-hidden="true"></i>
                        </div>}
                        <div className="title-text t-cell">Dialer</div>                      
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6">
                        <div className="form-group text-right">
                            {props.vm.isPreviewResultView && Utils.hasUserPermission(Permission.CreateCalllist) && props.vm.CallingListList.values && props.vm.CallingListList.values.length > 0 && (props.vm.selectedCallingType === AutoDialerConstants.Active || props.vm.selectedCallingType === AutoDialerConstants.Prospective) &&
                                <Button id="btnGenerateCallList" className='primary-button margin-left-10' icon="fa fa-plus" label="Generate Call List" onClick={() => props.vm.ganerateCallList()} />}
                        </div>
                    </div>
                </div>
                <div className="editor-host">
                {props.vm.isMessgeVisible && (
                    <IMASMessagesHandler response={props.vm.response}></IMASMessagesHandler>
                )}
                {!Utils.hasUserPermission(Permission.ViewAutoDialer) ? (
                    <>
                        <div className="p-col-12">
                            <IMASMessagesHandler response={warning()}> </IMASMessagesHandler>
                        </div>
                    </>
                ) : !Utils.hasUserPermission(Permission.OnlyViewLockedDownAutoDialer) ?
                        (props.vm.isPreviewResultView ? <>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable
                                        value={props.vm.CallingListList.values}
                                        selectionMode="single"
                                        totalRecords={props.vm.totalRecords}
                                        lazy={true}
                                        autoLayout={true}
                                        responsive={true}
                                        paginator={true}
                                        onSort={onSort}
                                        rows={props.vm.rows}
                                        first={props.vm.first} onPage={onPageHandle}
                                        className="header-align-left datatable-width-auto app-table"
                                        emptyMessage={Constants.NoRecordFounds}
                                        paginatorTemplate={
                                            props.vm.CallingListList.values.length > 0
                                                ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                : ""
                                        }
                                        rowsPerPageOptions={
                                            props.vm.CallingListList.values.length > 0
                                                ? [5, 10, 25]
                                                : []
                                        }
                                        currentPageReportTemplate={
                                            props.vm.CallingListList.values.length > 0
                                                ? "Showing {first} to {last} of {totalRecords} records"
                                                : ""
                                        }
                                        sortOrder={(props.vm.sortOrder ? 0 : -1)}
                                        sortField={props.vm.sortColumn}
                                    >
                                        <Column field="agentId" header="Agent ID" sortable={true} sortField="agentId" />
                                        <Column field="firstName" header="First Name"/>
                                        <Column field="lastName" header="Last Name" />
                                        <Column field="state" header="State" sortable={true} sortField="state" />
                                    </DataTable>
                                </div>
                            </div>
                        </> : (!props.vm.isPreviewResultView && props.vm.isGenerateCallListResultView) ? <>
                            {Utils.hasUserPermission(Permission.CreateCalllist) && <div>

                                <div className="p-grid">
                                    {Utils.hasUserPermission(Permission.AssignMarketingEventToCallList) && <div className={Utils.isMobileView() ? "p-col-5" : "p-col-4"}>
                                            <div className="form-group">
                                            <CustomDropdown
                                                errors={undefined}
                                                label="Marketing Event"
                                                placeHolder="None"
                                                disabled={false}
                                                id="marketingEventDropdown"
                                                isrequired={false}
                                                onBlur={() => { }}
                                                value={props.vm.marketingEvent.toString()}
                                                options={props.vm.marketingEventList.values}
                                                onChange={(value) => {
                                                    onMarketingEventChange(value);
                                                }}
                                            />
                                        </div>
                                    </div>}
                                    <div className={Utils.isMobileView() ? "p-col-5" : "p-col-4"}>
                                        <div className="form-group">
                                            <CustomInputText
                                                errors={props.vm.ErrorModel.getError("callListName")}
                                                label="Call list Name"
                                                placeHolder=""
                                                id="marketingEventDropdown"
                                                maxLength={undefined}
                                                isrequired={true}
                                                value={props.vm.callListName.toString()}
                                                onChange={(value) => {
                                                    onCallListNameChange(value);
                                                }}
                                                onBlur={(value) => { }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 text-right">
                                        <Button id="btnCancelCallList" className='secondary-button margin-left-10' icon="fa fa-times" label="Cancel" onClick={() => props.vm.cancelCallList()} />
                                        <Button id="btnSaveCallList" className='primary-button margin-left-10' icon="fa fa-save" label="Save As Call List" onClick={() => props.vm.saveCallList()} />
                                    </div>
                                </div>
                                <div>*NOTE: Saving a Call List does not grant you exclusive access to the agents on that list. If an agent is contacted by another marketer, the agent will automatically be removed from this list</div>
                            </div>}
                        </> : <>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <div className="form-group margin-top-10" >
                                                <CustomDropdown
                                                    errors={undefined}
                                                    label="List Type"
                                                    isrequired={false}
                                                    placeHolder="Choose a Call List"
                                                    disabled={false}
                                                    id="agentsDropdown"
                                                    onBlur={() => { }}
                                                    value={props.vm.selectedCallingType}
                                                    options={props.vm.callingTypes}
                                                    onChange={callingTypeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={props.vm.selectedCallingType === "0" ? "p-grid" : "p-grid editor-host"}>
                                        <div className="p-col-12">
                                            <div>
                                                {props.vm.selectedCallingType === AutoDialerConstants.Prospective &&
                                                    <ProspectiveCallListView vm={props.vm.propectiveCallListViewModel}></ProspectiveCallListView>}
                                                {props.vm.selectedCallingType === AutoDialerConstants.Active &&
                                                    <ActiveCallListView vm={props.vm.activeCallListViewModel}></ActiveCallListView>}
                                                {props.vm.selectedCallingType === AutoDialerConstants.Existing &&
                                                    <ExistingCallListView vm={props.vm.existingCallListViewModel}></ExistingCallListView>}
                                                {props.vm.selectedCallingType === AutoDialerConstants.CreateCustom &&
                                                    <CreateCustomCallListView vm={props.vm.createCustomCallListViewModel}  ></CreateCustomCallListView>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid" style={{marginTop:"-30px"}}>
                                        <div className="p-col-12 text-right">
                                            <div>
                                                {(props.vm.selectedCallingType === AutoDialerConstants.Active || props.vm.selectedCallingType === AutoDialerConstants.Prospective) && Utils.hasUserPermission(Permission.ViewOptionsListPanel) &&
                                                    <Button id="btnSearch" className='primary-button margin-left-10' icon="fa fa-search" label="Preview Results" onClick={() => {  props.vm.generatePreviewResult()} } />}
                                                {Utils.hasUserPermission(Permission.GenerateIContactList) && props.vm.selectedCallingType === AutoDialerConstants.Active &&
                                                    <Button id="btnContactList" className='secondary-button margin-left-10' icon="fa fa-download" label="Export List to .CSV" onClick={() => props.vm.generateIContactList()} />}
                                            </div>
                                        </div>
                                    </div>
                                </>)
                        : Utils.hasUserPermission(Permission.OnlyViewLockedDownAutoDialer) ? (
                            <div className="p-grid">
                                <div className="p-col-12 mt-10">
                                    <div>
                                        <ExistingCallListView vm={props.vm.existingCallListViewModel}></ExistingCallListView>
                                    </div>
                                </div>
                            </div>) : (<></>)
                }
            </div>
            </div>
        </>
    );
});

export default AutoDialerView;
export interface ContractClick {
    (...args: any[]): void;
}
