import { getToken } from "../Auth0Authenticator";
import { Client as AccountingServiceClient } from "../../services/AccountingService";
import { Client as AddressServiceClient } from "../../services/AddressService";
import { Client as BusinessUnitConfigClient } from "../../services/BusinessUnitConfigService";
import { Client as ContractServiceClient } from "../../services/ContractService";
import { Client as DocumentServiceClient } from "../../services/DocumentService";
import { Client as InsuredServiceClient } from "../../services/InsuredService";
import { Client as IdentityServiceClient } from "../../services/IdentityService";
import { Client as LeadServiceClient } from "../../services/LeadService";
import { Client as ProducerServiceClient } from "../../services/ProducerService";
import { Client as ProducerSearchServiceClient } from "../../services/ProducerSearchService";
import { Client as ProductServiceClient } from "../../services/ProductService";
import { Client as SupplyOrderServiceClient } from "../../services/SupplyOrderService";
import { Client as TaskServiceClient } from "../../services/TaskService";
import { Client as WorkflowServiceClient } from "../../services/WorkflowService";
import { Client as CommissionServiceClient } from "../../services/CommissionService";
import { Client as AutoDialerServiceClient } from "../../services/AutoDialerService";
import { Client as MessagingServiceClient } from "../../services/MessagingService";
import { Client as ProductionServiceClient } from "../../services/ProductionService";
import { Client as ReportServiceClient } from "../../services/ReportService";
import { Client as AccountingAdjustmentServiceClient } from "../../services/AccountingAdjustmentService";
import { Client as InviteService } from "../../services/InviteService";
import { Client as NotificationClient } from "../../services/NotificationService";
import { Client as CustomAttributeServiceClient } from "../../services/CustomAttributeService";
import Utils from "../Utils";
import { ServiceType } from "../enum/ThemeSettings";
class ServiceClient {
  apiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  client: any;
  getClient(value: ServiceType) {
    switch (value) {
      case ServiceType.Accounting:
        this.client = new AccountingServiceClient(Utils.getServiceUrlByEnvironment());
        break;  
        case ServiceType.AccountingAdjustment:
              this.client = new AccountingAdjustmentServiceClient(Utils.getServiceUrlByEnvironment());
          break; 
          case ServiceType.InviteService:
                this.client = new InviteService(Utils.getServiceUrlByEnvironment());
            break; 
      case ServiceType.Address:
        this.client = new AddressServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.BusinessUnitConfig:
        this.client = new BusinessUnitConfigClient(Utils.getServiceUrlByEnvironment());
        break;
      //   case ServiceType.Commission:
      //     return new Client(Utils.getServiceUrlByEnvironment());
      case ServiceType.Contract:
        this.client = new ContractServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Document:
        this.client = new DocumentServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Insured:
        this.client = new InsuredServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Identity:
        this.client = new IdentityServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Lead:
       this.client = new LeadServiceClient(Utils.getServiceUrlByEnvironment());

        break;
      //   case ServiceType.Messaging:
      //     return new Client(Utils.getServiceUrlByEnvironment());
      case ServiceType.Producer:
        this.client = new ProducerServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.ProducerSearch:
        this.client = new ProducerSearchServiceClient(
          Utils.getServiceUrlByEnvironment()
        );
        break;
      case ServiceType.Product:
        this.client = new ProductServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.SupplyOrder:
        this.client = new SupplyOrderServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Task:
        this.client = new TaskServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Workflow:
        this.client =new WorkflowServiceClient(Utils.getServiceUrlByEnvironment());
         break;
      case ServiceType.Commission:
        this.client =new CommissionServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.AutoDialer:
        this.client = new AutoDialerServiceClient(Utils.getServiceUrlByEnvironment());
        break;
      case ServiceType.Messaging:
          this.client = new MessagingServiceClient(Utils.getServiceUrlByEnvironment());
            break;
      case ServiceType.Production:
          this.client = new ProductionServiceClient(Utils.getServiceUrlByEnvironment());
          break;
      case ServiceType.Report:
        this.client = new ReportServiceClient(Utils.getServiceUrlByEnvironment());
            break;
      case ServiceType.Notification:
        this.client = new NotificationClient(Utils.getServiceUrlByEnvironment());
            break;

      case ServiceType.CustomAttribute:
        this.client = new CustomAttributeServiceClient(Utils.getServiceUrlByEnvironment());
            break;
    }
    return this.client;  
  }
}

export default new ServiceClient();
