import { routes } from "../../../../router";
import { action, observable } from "mobx";
import { UserStatus } from "../../../../infrastructure/enum/Common";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import NotificationStore from "../../../../store/NotificationStore";
import { EmailViewModel } from "../../../emails/EmailViewModel";
import Utils from "../../../../infrastructure/Utils";
import * as NotificationsModel from "./Notifications.model";
import { Permission } from "../../../../infrastructure/enum/Permission";
import PageContext from "../../../../infrastructure/PageContext";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../../views/components/controls/IMASTemplates";

export class NotificationViewModel extends BaseListViewModel<any> {
  constructor() {
    super("Notifications", "Notifications", "", "", "id", "name");
    this.pageSize = 50;
  }
  @observable statusTypeList = [
    { label: "All", value: UserStatus.All },
    { label: "Active", value: UserStatus.Active },
    { label: "Inactive", value: UserStatus.Inactive },
  ];

  protected async getItems() {
    return await NotificationStore.getEmailTemplates(
      this.currentPage,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      this.searchNotificationValue,
      this.selectedStatus
    );
  }

  @action Load = async () => {
    if (!Utils.hasUserPermission(Permission.ViewTemplates)) {
      this.selectedTabIndex == 1;
      this.handleTabClick(1, "");
      this.titleName = "Email Logs";
    } else {
      this.handleTabClick(0, "");
      this.titleName = "Templates";
    }
  };

  @action handleTabClick = async (
    index: number | undefined,
    key: string
  ): Promise<void> => {
    this.selectedTabIndex = index;
    this.resetPaging();
    this.resetFiltersToDefault();
    if (index !== undefined) {
      localStorage.setItem("selectedUserTab", "" + index);
    }
    if (this.selectedTabIndex == 1) {
      this.EmailViewModel.searchValue = "";
      this.EmailViewModel.searchInputValue = "";
      this.EmailViewModel.resetPagingValues();
      this.EmailViewModel.loadEmails();
      this.selectedStatus = 2;
    } else {
      this.loadItems();
    }
  };

  @action goToAdmin = () => {
    routes.administration.push();
  };
  @observable EmailViewModel: EmailViewModel = new EmailViewModel();
  @observable selectedStatus: any = "2";
  @observable searchNotificationValue: any;
  @observable showViewTemplateModal: boolean = false;
  @observable selectedTemplateID: any;
  @observable emailBody: any;
  @observable footerBody: NotificationsModel.UserProperties = {
    to: "",
    cc: [],
    additionalBody: "",
    footerContent: "",
    notificationPurpose: "",
    conditions: "",
    triggerNotification: "",
  };
  @observable emailTo: any;
  @observable emailSubject: any;
  @observable emailTemplateName: any;
  @observable emailActive: any;
  @observable selectedTabIndex?: number = 0;
  @observable refreshVisible: boolean = false;
  @observable isAdditionalBodyRequired: boolean = false;
  @observable editTemplateFromView: boolean = false;
  @observable showDiscardChanges: boolean = false;
  @observable emailChecked: boolean | undefined = false;
  @observable ccEmailsValue: any;
  @observable ccEmailsValueTemp: any;
  @observable additionalEmailBody: any;
  @observable additionalEmailBodyTemp: any;
  @observable activationContent: string = "";
  @observable activateModalFromView: boolean = false;
  @observable updateTemplateToast: boolean = false;
  @observable activateTemplateToast: boolean = false;
  @observable timer: any;
  @observable toasterMessage: string = "";
  @observable activateToggleAlertText: string = "";
  @observable activateToggleAlertShow: boolean = false;
  @observable isTestEmailConfirmation: boolean = false;
  @observable disableTestEmailSaveDisabled: boolean = true;
  @observable testEmailTemplateName: string = "";
  @observable testEmail: string = "";
  @observable testEmailPlaceHolder: string = "Enter email ID";
  @observable testEmailErrorMsg: string =
    "Please enter the correct email address.";
  @observable testEmailValid: boolean = false;
  @observable testEmailSendResult: boolean = false;
  @observable testEmailSendResultFailed: boolean = false;
  @observable showViewEventDetailsModal: boolean = false;
  @observable titleName: string = "Templates";
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @action viewTemplateDetailsModal = (show: boolean, rowData: any) => {
    this.showViewEventDetailsModal = show;
    if (show && rowData) {
      this.emailTemplateName = rowData.name;
      this.footerBody = JSON.parse(rowData.userProperties);
    }
  };

  @action setCancelChangesConfirmation = (value: boolean) => {
    this.isTestEmailConfirmation = value;
  };

  @action checkTestEmailOnBlur = () => {
    if (this.testEmail) {
      const regx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regx.test(this.testEmail)) {
        this.testEmailValid = true;
        this.disableTestEmailSaveDisabled = true;
      } else {
        this.testEmailValid = false;
        this.disableTestEmailSaveDisabled = false;
      }
    }
  };
  @action checkTestEmail = () => {
    if (this.testEmail) {
      const regx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regx.test(this.testEmail)) {
        this.disableTestEmailSaveDisabled = true;
      } else {
        this.testEmailValid = false;
        this.disableTestEmailSaveDisabled = false;
      }
    }
  };

  @action setTestEmail = (value: string) => {
    this.checkTestEmail();
    this.testEmail = value;
  };

  @action viewTemplateModal = (show: boolean, id: number, isEdit: boolean) => {
    this.response =[];
    this.isMessgeVisible = false;
    this.ccEmailsValue = [];
    this.additionalEmailBody = "";
    this.activateToggleAlertShow = false;
    this.emailBody = "";
    this.showViewTemplateModal = show;
    this.selectedTemplateID = id;
    if (show) {
      this.getTemplateByID();
    }
    this.isEditTemplateFromView(isEdit, isEdit);
  };

  @action resetToasters = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      localStorage.removeItem("roleCloned");
      this.updateTemplateToast = false;
      this.activateTemplateToast = false;
      this.toasterMessage = "";
    }, 9000);
  };
  @action resetViewEmailModal = () => {
    this.response =[];
    this.isMessgeVisible = false;
    this.ccEmailsValue = [];
    this.additionalEmailBody = "";
    this.showViewTemplateModal = false;
    this.editTemplateFromView = false;
  };
  @action emailLogsClick = () => {
    this.titleName = "Email Logs";
    this.reset();
  };
  @action sendTestEmail = async () => {
    localStorage.setItem("testEmailNotification", "reject");
    var result = await NotificationStore.sendTestEmail(
      this.testEmail,
      this.selectedTemplateID
    );
    this.isTestEmailConfirmation = false;
    if (result)
      Utils.showSuccessToaster("Test Email has been sent.", 5000, 500);
    else this.testEmailSendResultFailed = !result;

    // clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    //   localStorage.removeItem("testEmailNotification");
    //   this.testEmailSendResultFailed = false;
    //   this.testEmailSendResult = false;
    //   this.toasterMessage = "";
    // }, 9000);
  };

  @action additionalEmailChange = (value: any) => {
    this.additionalEmailBody = value;
  };

  @action cancelEditFromView = (value: boolean) => {
    this.showDiscardChanges = value;
  };

  @action isEditTemplateFromView = (value: boolean, tableData: any) => {
    if (!value) {
      this.emailChecked = this.emailActive;
      this.activateToggleAlertShow = false;
      this.additionalEmailBody = this.additionalEmailBodyTemp;
      this.ccEmailsValue = this.ccEmailsValueTemp;
    }
    this.editTemplateFromView = value;

    if (tableData) {
      this.isAdditionalBodyRequired =
        this.emailTo && this.emailTo.isAdditionalBodyRequired;
    }
  };

  @action handleToggle = () => {
    this.emailChecked = !this.emailChecked;
    this.activateToggleAlertShow = true;
    // this.isActivateEmail(false, false);
    this.activateToggleAlertText = !this.emailChecked
      ? "Toggle Off will deactivate email triggering and users will no longer receive notifications."
      : "Toggle On will activate email triggering and users will start receiving automated emails.";
  };

  @action isActivateModal = (value: boolean) => {
    this.activateModalFromView = value;
  };

  @action isActivateEmailFromToggle = async (
    value: boolean,
    fromGrid: boolean
  ) => {
    let reqData = {
      id: this.selectedTemplateID,
      status: this.emailChecked,
    };
    let res = await NotificationStore.updateNotificationStatus(reqData);
    this.toasterMessage = !this.emailActive
      ? `Template '${this.emailTemplateName}' is activated.`
      : `Template '${this.emailTemplateName}' is Deactivated.`;
    this.getTemplateByID();
    // !fromGrid && this.getTemplateByID();
    this.loadItems();
    this.isActivateModal(false);
    value && localStorage.setItem("roleCloned", "cloned");
    if (value) {
      Utils.showSuccessToaster(this.toasterMessage, 5000, 500);
    }
    //this.activateTemplateToast = value;
    this.emailChecked = !this.emailActive;
    this.emailActive = !this.emailActive;
    this.resetToasters();
  };

  @action isActivateEmail = async (value: boolean, fromGrid: boolean) => {
    let reqData = {
      id: this.selectedTemplateID,
      status: !this.emailActive,
    };
    let res = await NotificationStore.updateNotificationStatus(reqData);
    this.toasterMessage = !this.emailActive
      ? `Template '${this.emailTemplateName}' is activated.`
      : `Template '${this.emailTemplateName}' is Deactivated.`;
    !fromGrid && this.getTemplateByID();
    this.loadItems();
    this.isActivateModal(false);
    value && localStorage.setItem("roleCloned", "cloned");
    if (value) {
      Utils.showSuccessToaster(this.toasterMessage, 5000, 500);
    }
    this.emailChecked = !this.emailActive;
    this.emailActive = !this.emailActive;
    this.resetToasters();
  };

  @action LoadPreview = (id: any) => {
    this.selectedTemplateID = id;
    this.getTemplateByID();
  };

  @action getTemplateByID = async () => {
    let res = await NotificationStore.getEmailDetailsById(
      this.selectedTemplateID
    );
    if (res) this.emailBody = res.bodyContent;
    this.footerBody = res.userProperties && JSON.parse(res.userProperties);
    this.emailTo = (res.userProperties && JSON.parse(res.userProperties)) || [];
    this.emailSubject = res.subject;
    this.emailTemplateName = res.name;
    this.isAdditionalBodyRequired =
      this.emailTo && this.emailTo.isAdditionalBodyRequired;
    this.emailActive = res.active;
    this.emailChecked = res.active;
    this.activateToggleAlertText = res.active
      ? "Toggle Off will deactivate email triggering and users will no longer receive notifications"
      : "Toggle On will activate email triggering and users will start receiving automated emails.";
    this.activationContent = res.active
      ? "Users will no longer receive notifications. Are you sure you want to deactivate?"
      : "Users will start receiving notifications. Are you sure you want to activate?";
    this.ccEmailsValue = (this.emailTo && this.emailTo.cc) || [];
    this.ccEmailsValueTemp = (this.emailTo && this.emailTo.cc) || [];
    this.additionalEmailBody =
      (this.emailTo && this.emailTo.additionalBody) || undefined;
    this.additionalEmailBodyTemp =
      (this.emailTo && this.emailTo.additionalBody) || undefined;
  };

  @action updateTemplate = async () => {
    try
    {
      let reqData = {
        id: this.selectedTemplateID,
        name: "",
        subject: "",
        bodyContent: "",
        module: "",
        active: this.emailChecked,
        notificationTypeId: 0,
        userProperties: JSON.stringify({
          cc: this.ccEmailsValue,
          additionalBody: this.additionalEmailBody,
          to: this.footerBody.to,
          footerContent: this.footerBody.footerContent,
        }),
      };
      // this.isActivateEmailFromToggle(false, false);
      let res = await NotificationStore.updateNotification(reqData);
      this.toasterMessage = `Template '${this.emailTemplateName}' is updated.`;
      localStorage.setItem("roleCloned", "cloned");
      Utils.showSuccessToaster(this.toasterMessage, 5000, 500);
      //this.updateTemplateToast = true;
      this.activateToggleAlertShow = false;
      // this.resetViewEmailModal();
      this.editTemplateFromView = false;
      // this.isEditTemplateFromView(false, false);
      //this.resetToasters();
    }
    catch(e:any) {
      this.isLoading = false; 
      e.text().then((_responseText: any) => {
          let _title = JSON.parse(_responseText).title;
          this.showErrorMessageinToaster(`${_title}`);
      });
      this.isLoading = false;
      PageContext.setIsMessageVisible(false);
  }
  
  };

  @action hideValidationToaster = () => {
    toast.dismiss("validation-toaster");
};

showErrorMessageinToaster(message: string) {
    toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
        closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
        className: "validation-toaster website",
        draggable: false,
        toastId: "validation-toaster",
        autoClose: 7000,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
        this.hideValidationToaster();
    }, 7000);
}

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClickEmailView = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById("222");
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list-email"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== "222") {
          menuListAllDivs[i].style.display = "none";
        }
      }
      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-email-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== "222") {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-email-" + "222"
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    var dialogelement = document.querySelector<any>(".p-dialog-content");
    if (dialogelement) {
      dialogelement.onclick = () => {
        Utils.hideActionDropDown();
      };
    }
    window.onclick = function (event) {
      Utils.hideActionDropDown();
    };
    this.isShown = showState;
  };

  @action hideActionDropDown = () => {
    let showState = true;
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list-email"
      ) as HTMLCollectionOf<HTMLElement>
    );

    const svgDiv = document.getElementById(
      "custom-temp-email-" + "222"
    ) as HTMLElement;
    const menulistDiv = document.getElementById("222");
    if (showState && menulistDiv) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        menuListAllDivs[i].style.display = "none";
        menulistDiv.style.display =
          menulistDiv.style.display === "block" ? "none" : "none";
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menuspan"
            : "custom-report-menuspan";
        showState = false;
      }

      const activeClassName = document.querySelector(
        ".custom-report-menu-active"
      );
    } else {
      showState = true;
    }
  };

  @action handlePreviewTemplate = (id: any) => {
    window.open("/administration/general/notifications/preview/" + id, "");
  };

  @action setStatusDropdown = (value: string) => {
    this.selectedStatus = value;
    this.resetRefesh();
    this.loadNotifications();
  };

  @action onFieldChange = (value: any) => {
    this.searchNotificationValue = value;
    if (
      this.searchNotificationValue.length == 0 ||
      this.searchNotificationValue.length > 2
    ) {
      this.loadNotifications();
    }
    this.resetRefesh();
  };
  @action loadNotifications = async () => {
    const templates = await NotificationStore.getEmailTemplates(
      this.currentPage,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      this.searchNotificationValue,
      this.selectedStatus
    );
    this.itemList.values = templates.data || [];
    this.totalRowsCount = templates.recordCount || 0;
  };

  @action search = async () => {
    this.resetPaging();
    this.loadItems();
  };
  @action reset = async () => {
    this.selectedStatus = "2";
    this.searchNotificationValue = "";
    this.resetPaging();
    this.resetFiltersToDefault();
    this.resetRefesh();
    this.loadItems();
  };

  resetFiltersToDefault() {
    this.pageSize = 50;
  }
  @action resetRefesh() {
    if (
      this.selectedStatus == "2" &&
      (this.searchNotificationValue == "" || !this.searchNotificationValue)
    )
      this.refreshVisible = false;
    else this.refreshVisible = true;
  }
}
