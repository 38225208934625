import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { routes } from "../../router";
import IMASLog from "../../infrastructure/IMASLog";
import utils from "../../infrastructure/Utils";
import { Constants } from "../../infrastructure/enum/Constants";
import WorkflowStore from "../../store/WorkflowStore";
import productStore from "../../store/ProductStore";
import { WorkflowDocumentListModel } from "../../services/WorkflowService";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import {
  AddEditDocumentViewModel,
  DocumentListener,
} from "../workflow/AddEditDocumentViewModel";
import { DocumentInfoViewModel } from "../workflow/DocumentInfoViewModel";
import {
  MainWorklowTab,
  WorkflowStatusType,
} from "../../infrastructure/enum/WorkflowDetails";
import { setTimeout } from "timers";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import UserContext from "../../infrastructure/UserContext";
import IdentityStore from "../../store/IdentityStore";
import { AgentDocsViewModel } from "../agents/documents/AgentDocsViewModel";

export class DocWorkflowViewModel implements ViewModel, DocumentListener {
  @observable selectedTabIndex?: number = 0;
  @observable selectedMainTabIndex?: number = 0;
  @observable selectedKey: string | undefined = "";
  @observable documentId: number = 0;
  @observable productDisabled: boolean = true;
  @observable isLookupsLoaded: boolean = false;
  @observable AgentDocsViewModel:AgentDocsViewModel = new AgentDocsViewModel();

  Route = async (currentRoute: RouteList): Promise<void> => {
    if (currentRoute.name === routes.documentInfo.name) {
      this.selectedTabIndex = 0;
    }
  };

  @observable
  private loading = false;
  private onClose?: () => void;

  @observable isNotReceivedQueue: boolean = false;

  @computed
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @computed
  get IsLoaded(): boolean {
    return true;
  }
  @computed
  get IsLoading(): boolean {
    return this.loading;
  }
  Close = (): void => {
    if (this.onClose) this.onClose();
  };

  @observable SelectedViewModel: ViewModel | undefined;
  @observable title: string = "";
  @observable agentId: number | undefined;
  @observable companyId: number | undefined;
  @observable docId: number = 0;
  @observable rows: number = 50; // rows is pagesize
  @observable totalRecords: number = 0;
  @observable first: number = 0; // first is pageindex
  @observable startIndex: number = 0;

  @observable searchInputValue: string = "";
  @observable selectedCompany: any;
  @observable selectedType: any;
  @observable selectedAssignedToUser: any;
  @observable selectedAssignedToUserTeam: any;
  @observable selectedProduct: any;
  @observable selectedDivision: any;
  @observable selectedDocumentList: any;
  @observable fileData: any = null;

  @observable sortColumn: string = "appDate";
  @observable sortOrder: boolean = false;
  @observable isException: boolean = false;
  @observable isWorkflowApiCall: boolean = true;
  @observable isWarning: boolean = false;
  @observable exceptionMessage: string = Constants.Error;
  @observable isSuccess: boolean = false;
  @observable hideFileName: boolean = false;
  @observable hideReplaceFileName: boolean = false;
  @observable selectedAgent: string = "";
  @observable selectedAgentId: number | undefined;
  @observable searchValue: string = "";
  @observable isStealConfirm: boolean = false;
  @observable isDeleteFileConfirm: boolean = false;
  @observable isScrubSelected: boolean = false;
  @observable element: any;
  @observable isSteal: boolean = false;
  @observable statusCode: number = 0;
  @observable message: string = "";
  @observable innerError: string = "";
  @observable isReplaceConfirmDisplay: boolean = false;
  @observable isReplaceUploadDisplay: boolean = false;
  @observable replaceFileName: string = "";
  @observable replaceFileData: string = "";
  @observable selectedExistingFileNameForReplace: string = "";

  // Declare and define menuitem

  @observable actionItemsForDocTypeContract: MenuItem[] = [];
  @action getActionItemsForDocTypeContract = () => {
    this.actionItemsForDocTypeContract = [];
    let edit = {
      label: "Edit",
      icon: "fa fa-edit",
      command: () => {
        setTimeout(() => {
          this.setAddDocumentDialogVisibility(
            true,
            true,
            this.selectedDocRow.insuredName,
            this.selectedWorkflowStatusType,
            false
          );
        }, 300);
      },
    };
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };
    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.EditDocumentMetadata) &&
        this.actionItemsForDocTypeContract.push(edit);
    }
    this.actionItemsForDocTypeContract.push(download);
  };

  @observable actionItemsForDocTypeContractInvite: MenuItem[] = [];
  @action getActionItemsForDocTypeContractInvite = () => {
    this.actionItemsForDocTypeContractInvite = [];
    let edit = {
      label: "Edit",
      icon: "fa fa-edit",
      command: () => {
        setTimeout(() => {
          this.setAddDocumentDialogVisibility(
            true,
            true,
            this.selectedDocRow.insuredName,
            this.selectedWorkflowStatusType,
            false
          );
        }, 300);
      },
    };
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };
    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.EditDocumentMetadata) &&
        this.actionItemsForDocTypeContractInvite.push(edit);
    }
    this.actionItemsForDocTypeContractInvite.push(download);
  };

  @observable actionItemsForTeamWithDocTypeContract: MenuItem[] = [];
  @action getActionItemsForTeamWithDocTypeContract = () => {
    this.actionItemsForTeamWithDocTypeContract = [];

    let edit = {
      label: "Edit",
      icon: "fa fa-edit",
      command: () => {
        setTimeout(() => {
          this.setAddDocumentDialogVisibility(
            true,
            true,
            this.selectedDocRow.insuredName,
            this.selectedWorkflowStatusType,
            false
          );
        }, 300);
      },
    };
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };
    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.EditDocumentMetadata) &&
        this.actionItemsForTeamWithDocTypeContract.push(edit);
    }
    this.actionItemsForTeamWithDocTypeContract.push(download);
  };

  @observable actionItemsForTeamWithOtherDocTypes: MenuItem[] = [];
  @action getActionItemsForTeamWithOtherDocTypes = () => {
    this.actionItemsForTeamWithOtherDocTypes = [];

    let edit = {
      label: "Edit",
      icon: "fa fa-edit",
      command: () => {
        setTimeout(() => {
          this.setAddDocumentDialogVisibility(
            true,
            true,
            this.selectedDocRow.insuredName,
            this.selectedWorkflowStatusType,
            false
          );
        }, 300);
      },
    };
    let download = {
      label: "Download",
      icon: "fa fa-download",
      command: () => {
        setTimeout(() => {
          this.downloadDocumentLink(this.docId);
        }, 300);
      },
    };

    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.EditDocumentMetadata) &&
        this.actionItemsForTeamWithOtherDocTypes.push(edit);
    }
    this.actionItemsForTeamWithOtherDocTypes.push(download);
  };

  @action Load = async () => {
    localStorage.removeItem("workFlowBackItem");
    this.AddEditDocumentViewModel.setDocumentListener(this);
    this.DocumentInfoViewModel.setDocumentInfoListener(this);
    let delay = 0;
    if (UserContext.permissions.length <= 0) {
      delay = 2000;
    }
    setTimeout(async () => {
      this.valuesInWorkflowStatusListBasedOnPermission();
      this.loadAssignedToUsers();
      await this.handleLoad();
      this.getActionItemsForDocTypeContractInvite();
      this.getActionItemsForTeamWithDocTypeContract();
      this.getActionItemsForTeamWithOtherDocTypes();
    }, delay);

    if (this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue || this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue) {
      localStorage.setItem("isLoadUpline", "true");
    }
    else
    {
      localStorage.setItem("isLoadUpline", "false");
    }
  };

  @action handleLoad = async () => {
    let previousValues = Utils.getDocInfo();
    if (previousValues.isDocInfo) {
      if (previousValues.isNotReceivedQueue) {
        this.retainPreviousValues(previousValues);
        await this.loadDocs();
      } else {
        await this.loadDocs();
        this.retainPreviousValues(previousValues);
      }
    } else {
      localStorage.removeItem("queueRows");
      this.startIndex = 0;
      this.first = 0;
      this.sortColumn = "appDate";
      this.sortOrder = false;
      this.resetFilters();
    }
  };

  @action retainPreviousValues = (previousValues: any) => {
    this.selectedAssignedToUser = previousValues.selectedAssignedToUser;
    this.selectedAssignedToUserTeam = previousValues.selectedAssignedToUserTeam;
    this.selectedType = previousValues.selectedType;
    this.selectedCompany = previousValues.selectedCompany;
    this.selectedDivision = previousValues.selectedDivision;
    this.searchValue = previousValues.searchValue;
    // this.selectedDocumentList = previousValues.selectedDocumentList
    this.selectedDocumentList = parseInt(
      localStorage.getItem("queueRows") || "50"
    );
  };

  @action handleMainTabClick = (
    index: number | undefined,
    key: string
  ): void => {
    if (index === 0) routes.docWorkflow.push();
    else routes.searchWorkflowDocs.push();
  };

  @action viewDocumentInfo(rowData: any) {
    this.documentId = rowData.id;
    this.workFlowStatusType = rowData.workFlowStatus;
    if (this.documentId != null) {
      // Assigning the User on selecting the Document.
      this.assign();
    }
    let storeValuesObj = {
      selectedAssignedToUser: this.selectedAssignedToUser,
      selectedAssignedToUserTeam: this.selectedAssignedToUserTeam,
      selectedType: this.selectedType,
      selectedCompany: this.selectedCompany,
      selectedProduct: this.selectedProduct,
      selectedDivision: this.selectedDivision,
      searchValue: this.searchValue,
      isNotReceivedQueue: this.isNotReceivedQueue,
      selectedDocumentList: parseInt(localStorage.getItem("queueRows") || "50"),
    };
    Utils.setDocInfo(true, storeValuesObj);
    this.setStates();
    routes.documentInfo.push({
      documentId: this.documentId ? this.documentId : 0,
      workflowStatus: this.workFlowStatusType ? this.workFlowStatusType : "",
    });
  }

  @action setStates = () => {
    let selectedAssignedToUser = this.selectedAssignedToUser;
    this.selectedAssignedToUser = selectedAssignedToUser;
  };
  @action assign = async () => {
    try {
      await WorkflowStore.assign(Number(this.documentId));
    } catch (error) {
      IMASLog.log("exception while assign the document.");
      this.isException = true;
    }
  };

  @action updatefile = async (fileName: string, isSteal: boolean) => {
    try {
      this.isMessgeVisible = false;
      this.response = "";

      if (this.isWorkflowApiCall) {
        this.isWorkflowApiCall = false;
        // set the user metadata to notify other users when the file is stealed.
        await WorkflowStore.updateFile(fileName, isSteal);
        this.setStealConfirmation(false);
        //await this.loadDocs();
      }
      this.isWorkflowApiCall = true;
    } catch (error) {
      this.isException = true;
      this.isWorkflowApiCall = true;
      this.isAddDocumentDialogVisible = false;
      //await this.loadDocs();
    }
  };

  @action handleMainLinkClick = (item: string | undefined): void => {
    if (item) {
      this.selectedKey = item;
      switch (item) {
        case MainWorklowTab.DocWorkflow:
          routes.docWorkflow.push();
          return;
        case MainWorklowTab.SearchWorkflowDocs:
          routes.searchWorkflowDocs.push();
          return;
        default:
          return;
      }
    }
  };

  @action isScrubButtonSelected = (value: boolean, workFlowStatusType: any) => {
    this.isScrubSelected = value;
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  };
  scrollToBottom() {
    this.element && this.element.scrollIntoView({ behavior: "smooth" });
  }
  @action setSelectedCompany = (value: string) => {
    this.selectedCompany = value;
  };

  @action setSelectedAssignedToUser = (value: string) => {
    this.selectedAssignedToUser = value;
    if (this.selectedAssignedToUser !== "ALL") {
      this.selectedAssignedToUserTeam = "0";
    }
    this.loadWorkflowData();
  };
  @action setSelectedAssignedToUserTeam = (value: string) => {
    this.selectedAssignedToUserTeam = value;
    if (this.selectedAssignedToUserTeam !== "ALL") {
      this.selectedAssignedToUser = "0";
    }
    this.loadWorkflowData();
  };
  onDropToReplaceDoc = async (value: any, fileName: string) => {
    this.replaceFileData = value;
    this.replaceFileName = fileName;
  };
  replaceDocument = async () => {
    var fileNameExtension = this.replaceFileName
      .substring(this.replaceFileName.lastIndexOf(".") + 1)
      .toLowerCase();
    var fileNamewithoutExtension = this.replaceFileName.substring(
      0,
      this.replaceFileName.lastIndexOf(".")
    );
    var formattedFileName = fileNamewithoutExtension + "." + fileNameExtension;
    var blob = null;
    if (
      this.replaceFileData != null &&
      this.replaceFileData !== "" &&
      this.replaceFileData !== undefined
    ) {
      // Split the base64 string in data and contentType
      var block = this.replaceFileData ? this.replaceFileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }

    await WorkflowStore.replaceWorkflowDocument(
      blob,
      formattedFileName,
      this.selectedExistingFileNameForReplace
    );

    IMASLog.log("file upload is successfully..");
    this.setIsSuccess(true);

    setTimeout(() => {
      this.setIsSuccess(false);
      this.setReplaceConfirmation(false);
      this.setReplaceUploadConfirmation(false);
      this.hideReplaceFileName = true;
    }, 3000);
    await this.loadDocs();
    this.hideReplaceFileName = false;
  };

  onDrop = async (value: any, fileName: string) => {
    this.fileData = value;
    var fileNameExtension = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    var fileNamewithoutExtension = fileName.substring(
      0,
      fileName.lastIndexOf(".")
    );
    var formattedFileName = fileNamewithoutExtension + "." + fileNameExtension;
    //const blob = new Blob([this.fileData.data], { type: 'application/pdf' });
    await this.UploadFile(this.fileData, formattedFileName);
  };

  private async UploadFile(fileData: any, fileName: string) {
    await this.uploadFile(fileData, fileName);
    IMASLog.log("file upload is successfully..");
    this.setIsSuccess(true);

    setTimeout(() => {
      this.setIsSuccess(false);
      this.hideFileName = true;
    }, 3000);
    await this.loadDocs();
    this.hideFileName = false;
  }

  @action uploadFile = async (fileData: any, fileName: string) => {
    var authorization = await WorkflowStore.getApiKey();
    var blob = null;
    if (fileData != null && fileData !== "" && fileData !== undefined) {
      // Split the base64 string in data and contentType
      var block = fileData ? fileData.split(";") : "";
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      blob = Utils.b64toBlob(realData, contentType, 512);
    }

    let result = await WorkflowStore.postFormData(
      WorkflowStore.getApiVersion(),
      blob,
      fileName,
      null,
      authorization,
      new Date().getTimezoneOffset()
    );
    return result;
  };

  @action setSearchByName(name: string) {
    this.searchValue = name;
  }

  @observable documentList = new Collection<WorkflowDocumentListModel>();

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable documentTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable workflowAssignedToUsersList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable workflowAssignedToUserTeamList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable divisionList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable selectedWorkflowStatusType: string = "Received";
  @observable workflowStatusTypeList: any = [];
  @action valuesInWorkflowStatusListBasedOnPermission = () => {
    this.workflowStatusTypeList = [];
    if (UserContext.permissions.length > 0) {
      Utils.hasUserPermission(Permission.AccessReceivedQueue) &&
        this.workflowStatusTypeList.push({
          label: "Received",
          value: "Received",
        });
      Utils.hasUserPermission(Permission.AccessScrubQueue) &&
        this.workflowStatusTypeList.push({ label: "Scrub", value: "Scrub" });
      Utils.hasUserPermission(Permission.AccessMissingInfoQueue) &&
        this.workflowStatusTypeList.push({
          label: "Missing Info",
          value: "Missing Info",
        });
      Utils.hasUserPermission(Permission.AccessDataEntryQueue) &&
        this.workflowStatusTypeList.push({
          label: "Data Entry",
          value: "Data Entry",
        });
      if (Utils.hasUserPermission(Permission.AccessPendingQueue)) {
        this.workflowStatusTypeList.push({
          label: "Pending",
          value: "Pending",
        });
      }
      this.workflowStatusTypeList.push({
        label: "Complete",
        value: "Complete",
      });
    }
  };

  @action resetPagingValues() {
    localStorage.removeItem("queueRows");
    this.rows = 50;
    this.totalRecords = 0;
    this.sortOrder = false;
    this.startIndex = 0;
    this.first = 0;
    this.sortColumn = "appDate";
  }

  @action goSearch = async () => {
    if (this.searchValue.length > 2) {
      this.loadWorkflowData();
    }
  };
  @action loadAssignedToUsers = async () => {
    var defaultItem = { label: "ALL", value: "0" };
    try {
      var result = await IdentityStore.getAllWorkflowUsersLookup();
      if (result != null) {
        this.workflowAssignedToUsersList.values = [];
        result.forEach((obj: any) => {
          var workflowAssignedToUsersLookup = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value : "",
          };
          this.workflowAssignedToUsersList.values.push(
            workflowAssignedToUsersLookup
          );
        });
        if (
          !Utils.hasUserPermission(Permission.ShowOnUserDropdowns) &&
          UserContext.getUserId() &&
          this.workflowAssignedToUsersList.values.length > 0
        ) {
          this.workflowAssignedToUsersList.values =
            this.workflowAssignedToUsersList.values.filter(
              (item) => +item.value !== UserContext.getUserId()
            );
        }
        this.AddEditDocumentViewModel.assignedToUsers = [
          { label: "ALL", value: "0" },
        ].concat(this.mapListItemAndSort(result));

        this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
        this.selectedAssignedToUser = "0";
      } else {
        this.workflowAssignedToUsersList.values = [];
        this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
        this.selectedAssignedToUser = "0";
      }
    } catch (e) {
      this.workflowAssignedToUsersList.values = [];
      this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
      this.selectedAssignedToUser = "0";
    }
  };
  @observable AgentId: number = 0;
  @action loadLookups = async () => {
    if (!this.isLookupsLoaded) {
      var defaultItem = { label: "ALL", value: "0" };
      try {
        var result = await WorkflowStore.getAllLookup();
        if (result != null) {
          this.isLookupsLoaded = true;
          if (result.companyList != null) {
            this.companyList.values = [];
            result.companyList.forEach((obj: any) => {
              var data = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.companyList.values.push(data);
            });
            this.AddEditDocumentViewModel.carriers = [
              { label: "ALL", value: "0" },
            ].concat(this.mapListItemAndSort(result.companyList));
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
          } else {
            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
          }

          if (result.documentTypeList != null) {
            this.documentTypeList.values = [];
            result.documentTypeList.forEach((obj: any) => {
              var documentData = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.documentTypeList.values.push(documentData);
            });
            this.documentTypeList.values.splice(0, 0, defaultItem);
            this.selectedType = "0";
          } else {
            this.documentTypeList.values = [];
            this.documentTypeList.values.splice(0, 0, defaultItem);
            this.selectedType = "0";
          }

          if (result.workflowAssignedToUsersList != null) {
            this.workflowAssignedToUsersList.values = [];
            result.workflowAssignedToUsersList.forEach((obj: any) => {
              var workflowAssignedToUsersLookup = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.workflowAssignedToUsersList.values.push(
                workflowAssignedToUsersLookup
              );
            });
            if (
              !Utils.hasUserPermission(Permission.ShowOnUserDropdowns) &&
              UserContext.getUserId() &&
              this.workflowAssignedToUsersList.values.length > 0
            ) {
              this.workflowAssignedToUsersList.values =
                this.workflowAssignedToUsersList.values.filter(
                  (item) => +item.value !== UserContext.getUserId()
                );
            }
            this.AddEditDocumentViewModel.assignedToUsers = [
              { label: "ALL", value: "0" },
            ].concat(
              this.mapListItemAndSort(result.workflowAssignedToUsersList)
            );

            this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
            this.selectedAssignedToUser = "0";
          } else {
            this.workflowAssignedToUsersList.values = [];
            this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
            this.selectedAssignedToUser = "0";
          }

          if (result.workflowAssignedToUserTeamList != null) {
            this.workflowAssignedToUserTeamList.values = [];
            result.workflowAssignedToUserTeamList.forEach((obj: any) => {
              var workflowAssignedToUserTeamLookup = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.workflowAssignedToUserTeamList.values.push(
                workflowAssignedToUserTeamLookup
              );
            });

            this.AddEditDocumentViewModel.assignedToUserTeam = [
              { label: "ALL", value: "0" },
            ].concat(
              this.mapListItemAndSort(result.workflowAssignedToUserTeamList)
            );

            this.workflowAssignedToUserTeamList.values.splice(
              0,
              0,
              defaultItem
            );
            this.selectedAssignedToUserTeam = "0";
          } else {
            this.workflowAssignedToUserTeamList.values = [];
            this.workflowAssignedToUserTeamList.values.splice(
              0,
              0,
              defaultItem
            );
            this.selectedAssignedToUserTeam = "0";
          }

          if (result.divisionList != null) {
            this.divisionList.values = [];
            result.divisionList.forEach((obj: any) => {
              var divisionList = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.divisionList.values.push(divisionList);
            });
            this.divisionList.values.splice(0, 0, defaultItem);
            this.selectedDivision = "0";
          } else {
            this.divisionList.values = [];
            this.divisionList.values.splice(0, 0, defaultItem);
            this.selectedDivision = "0";
          }
        }
        this.loadProducts();
      } catch (e) {
        IMASLog.log("exception from store: " + e.value);
        this.documentTypeList.values = [];
        this.documentTypeList.values.splice(0, 0, defaultItem);
        this.selectedType = "0";

        this.companyList.values = [];
        this.companyList.values.splice(0, 0, defaultItem);
        this.selectedCompany = "0";

        this.workflowAssignedToUsersList.values = [];
        this.workflowAssignedToUsersList.values.splice(0, 0, defaultItem);
        this.selectedAssignedToUser = "0";

        this.workflowAssignedToUserTeamList.values = [];
        this.workflowAssignedToUserTeamList.values.splice(0, 0, defaultItem);
        this.selectedAssignedToUserTeam = "0";

        this.divisionList.values = [];
        this.divisionList.values.splice(0, 0, defaultItem);
        this.selectedDivision = "0";
      }
    }
  };
  @action companyChange = async () => {
    await this.loadProducts();
    this.loadWorkflowData();
  };
  @action loadProducts = async () => {
    var defaultItem = { label: "ALL", value: "0" };
    try {
      if (this.selectedCompany && this.selectedCompany > 0) {
        var res = await productStore.getProductLookup(this.selectedCompany);
        if (res != null) {
          if (res.data != null) {
            this.productList.values = [];
            res.data.forEach((obj: any) => {
              var objdata = {
                label: obj.text ? obj.text : "",
                value: obj.value ? obj.value : "",
              };
              this.productList.values.push(objdata);
            });
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = "0";
          } else {
            this.productList.values = [];
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = "0";
          }
        }
      } else {
        this.productList.values = [];
        this.productList.values.splice(0, 0, defaultItem);
        this.selectedProduct = "0";
      }
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.productList.values = [];
      this.productList.values.splice(0, 0, defaultItem);
      this.selectedProduct = "0";
    }
  };

  @action openDocumentLink = async (docId: number, id?: number) => {
    this.isWarning = false;
    this.isException = false;
    this.isMessgeVisible = false;
    this.response = "";
    try {
      if (id !== undefined && id !== null && id > 0) {
        window.open(window.location.origin + "/workflowviewer/" + id, "_new");
      } else {
        this.response = "Invalid document Id. PDF File not found!";
        this.isMessgeVisible = true;
        setTimeout(() => {
          this.isMessgeVisible = false;
          this.response = "";
        }, 2500);
      }
    } catch (e) {
      var response = e.response;
      this.response = e.response;
      this.isMessgeVisible = true;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.response = "PDF File for this document not found!";
          this.response = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @action loadDocument = (base64: string, id?: number, filename?: string) => {
    let base = window.location.href;
    let url = `${base}/pdfViewer/id/${id}`;
    let win = window.open(url, "_blank");
    let html = "";
    html += "<html>";
    html += '<body style="margin:0!important">';
    html += '<div style="width: 100%;height: 100%; position: relative; ">';
    html +=
      '<div style="width: 100%;height: 100%; position: absolute;top: 0;left: 0; z-index: 10;">';
    html +=
      '<embed width="100%" height="100%" src="data:application/pdf;base64,' +
      base64 +
      '" type="application/pdf" />';
    html += "</div>";
    html += "</div>";
    html += "</body>";
    html += "</html>";
    setTimeout(() => {
      if (win !== null) {
        win.document.write(html);
        win.document.title = filename ? filename : "PDF file";
      }
    }, 0);
  };

  @action downloadDocumentLink = async (docId: number) => {
    this.isWarning = false;
    this.isException = false;
    this.isMessgeVisible = false;
    this.response = "";
    try {
      var result = await WorkflowStore.downloadWorkflowDocument(docId);
      if (result != null) {
        if (result.pdfData) {
          this.downloadPDFFile(result);
        } else {
          // this.response = "PDF File for this document not found!";
          //   this.isMessgeVisible = true;
          //   setTimeout(() => {
          //  this.isMessgeVisible = false;
          //  this.response = ''
          //   }, 1500)
          let successResponse = {
            status: 400,
            title: result.message,
            errors: { "": [] },
          };
          this.response = JSON.stringify(successResponse);
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        }
      }
    } catch (e) {
      var response = e.response;
      this.response = e.response;
      this.isMessgeVisible = true;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.response = "PDF File for this document not found!";
          this.isMessgeVisible = true;
          setTimeout(() => {
            this.isMessgeVisible = false;
            this.response = "";
          }, 2500);
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };
  @action redirectToAgentEdit = async (agentId: any, docId: number) => {
    localStorage.setItem("SumitFromWorkFlow", "Sumit"); 
    localStorage.setItem("SubmitFromWorkFlowDocId", docId+''); 
    setTimeout(() => { 
      this.AgentDocsViewModel.Load(agentId, docId);
      routes.agentDocs.push({
        agentId: "" + agentId,
      });
    }, 400);
  };

  @action downloadDocument = (base64: string) => {
    setTimeout(() => {
      var link = document.createElement("a");
      link.download = "document.pdf";
      link.href = "data:application/pdf;base64," + base64;
      link.textContent = "Download PDF";
      link.click();
      document.body.appendChild(link);
    }, 0);
  };

  @action onStealClick = async (value: any) => {
    try {
      var docId = value;
      if (this.isWorkflowApiCall) {
        this.isWorkflowApiCall = false;
        await WorkflowStore.stealDocument(docId);
        this.setStealConfirmation(false);
        await this.loadDocs();
      }
      this.isWorkflowApiCall = true;
    } catch (error) {
      this.isWorkflowApiCall = true;
      IMASLog.log("exception while steal the document.");
    }
  };

  @action setStealConfirmation(value: boolean) {
    this.isStealConfirm = value;
  }

  @action setDeleteFileConfirmation(value: boolean) {
    this.isDeleteFileConfirm = value;
  }
  @action setReplaceConfirmation(value: boolean) {
    this.isReplaceConfirmDisplay = value;
  }
  @action setReplaceUploadConfirmation(value: boolean) {
    this.isReplaceUploadDisplay = value;
  }

  @action setExistingFileName(selectedFileName: string) {
    this.selectedExistingFileNameForReplace = selectedFileName;
  }

  @action onDeleteFileClick = async (value: any) => {
    try {
      this.selectedDocRow = value;
      var obj = JSON.parse(JSON.stringify(value));
      if (this.isWorkflowApiCall) {
        this.isWorkflowApiCall = false;
        var fileName = obj.insuredName;
        await WorkflowStore.deleteFile(fileName);
        this.setDeleteFileConfirmation(false);
        await this.loadDocs();
      }
      this.isWorkflowApiCall = true;
    } catch (error) {
      IMASLog.log("exception while deleting task.");
      this.isException = true;
      this.isWorkflowApiCall = true;
      this.setDeleteFileConfirmation(false);
      await this.loadDocs();
    }
  };

  @action downloadFile = async (value: any) => {
    this.isWarning = false;
    this.isException = false;
    try {
      var fileName = value;
      var result = await WorkflowStore.downloadFile(fileName);
      if (result != null) {
        if (result.pdfData) {
          this.downloadPDFFile(result);
        } else {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        }
      }
    } catch (e) {
      var response = e.response;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @action openFile = async (value: any, id?: number) => {
    this.isWarning = false;
    this.isException = false;
    try {
      var fileName = value;
      var result = await WorkflowStore.downloadFile(fileName);
      if (result != null) {
        if (result.pdfData && result.pdfData !== null) {
          this.loadDocument(result.pdfData, id, result.filename);
        } else {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        }
      }
    } catch (e) {
      var response = e.response;
      if (response !== undefined && response !== null) {
        var obj = JSON.parse(response);
        IMASLog.log("exception from store: " + obj.title);
        if (obj.status === 500 && obj.title.includes("PDF File for document")) {
          this.exceptionMessage = "PDF File for this document not found!";
          this.isWarning = true;
        } else {
          this.isException = true;
        }
      } else {
        this.isException = true;
      }
    }
  };

  @action downloadPDFFile = (result: any) => {
    if (result.pdfData) {
      setTimeout(() => {
        var link = document.createElement("a");
        let n = result.filename ? result.filename.split(".")[0] : "";
        link.href = "data:application/pdf;base64," + result.pdfData;
        link.textContent = "Download PDF";
        link.download = n !== "" ? n : "document.pdf";
        link.click();
        document.body.appendChild(link);
        link.remove();
      }, 0);
    }
  };

  @action loadDocs = async () => {
    this.isException = false;
    this.isWorkflowApiCall = false;
    try {
      var searchdto: any = {};
      searchdto.WorkFlowStatus =
        this.selectedWorkflowStatusType === WorkflowStatusType.CompletedQueue
          ? 0
          : this.selectedWorkflowStatusType ===
            WorkflowStatusType.ReceivedQueue &&
            Utils.hasUserPermission(Permission.AccessReceivedQueue)
            ? 1
            : this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue &&
              Utils.hasUserPermission(Permission.AccessScrubQueue)
              ? 2
              : this.selectedWorkflowStatusType ===
                WorkflowStatusType.MissingInfoQueue &&
                Utils.hasUserPermission(Permission.AccessMissingInfoQueue)
                ? 3
                : this.selectedWorkflowStatusType ===
                  WorkflowStatusType.DataEntryQueue &&
                  Utils.hasUserPermission(Permission.AccessDataEntryQueue)
                  ? 4
                  : this.selectedWorkflowStatusType ===
                    WorkflowStatusType.PendingQueue &&
                    Utils.hasUserPermission(Permission.AccessPendingQueue)
                    ? 100
                    : 102;
      searchdto.AssignedTo =
        this.selectedAssignedToUser && this.selectedAssignedToUser > 0
          ? this.selectedAssignedToUser
          : undefined;
      searchdto.AssignedToUserTeam =
        this.selectedAssignedToUserTeam && this.selectedAssignedToUserTeam > 0
          ? this.selectedAssignedToUserTeam
          : undefined;
      searchdto.FileTypeId =
        this.selectedType && this.selectedType > 0
          ? this.selectedType
          : undefined;
      searchdto.CompanyId =
        this.selectedCompany && this.selectedCompany > 0
          ? this.selectedCompany
          : undefined;
      searchdto.ProductId =
        this.selectedProduct && this.selectedProduct > 0
          ? this.selectedProduct
          : undefined;
      searchdto.DivisionId =
        this.selectedDivision && this.selectedDivision > 0
          ? this.selectedDivision
          : undefined;
      searchdto.Search = this.searchValue ? this.searchValue : undefined;

      var result = await WorkflowStore.getWorkflowDocsByQueue(
        searchdto,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (result != null) {
        if (result.data) {
          this.totalRecords = result.recordCount ? result.recordCount : 0;
          this.documentList.values = result.data;
        } else {
          this.documentList.values = [];
          this.totalRecords = 0;
        }
      }
      this.isWorkflowApiCall = true;
    } catch (e) {
      IMASLog.log("exception from store: " + e.value);
      this.documentList.values = [];
      this.isException = true;
      this.isWorkflowApiCall = true;
    }
  };

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    localStorage.setItem("queueRows", "" + this.rows);
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadDocs();
  }

  @action setSelectedDoc = (docId: number) => {
    this.docId = docId;
  };
  @action setSelectedRow = (value: any) => {
    this.selectedDocRow = value;
  };

  @observable isNoOfDaysVisible: boolean = false;
  @action setNoOfDaysVisibility(value: boolean) {
    this.isNoOfDaysVisible = value;
  }
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setIsWarning(value: boolean) {
    this.isWarning = value;
  }

  @observable isSidebarLogVisible: boolean = false;
  @observable selectedDocRow: any = {};
  @observable selectedFile: any;

  @action setSidebarLogVisibility(value: boolean) {
    this.isSidebarLogVisible = value;
  }

  @action setSelectedFile = (file: string) => {
    this.selectedFile = file;
  };

  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action resetFilters = () => {
    this.selectedWorkflowStatusType = "Received";
    this.searchValue = "";
    this.selectedCompany = "0";
    this.selectedProduct = "0";
    this.selectedDivision = "0";
    this.selectedType = "0";
    this.selectedAssignedToUser = "0";
    this.selectedAssignedToUserTeam = "0";
    this.documentList.values = [];
    this.response = "";
    this.isMessgeVisible = false;
    this.isNotReceivedQueue = false;
    this.productDisabled = true;
    if (this.isWorkflowApiCall) {
      this.isWorkflowApiCall = false;
      this.resetPagingValues();
      this.loadDocs();
    }
    this.valuesInWorkflowStatusListBasedOnPermission();
    if (this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue || this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue) {
      localStorage.setItem("isLoadUpline", "true");
    }
    else
    {
      localStorage.setItem("isLoadUpline", "false");
    }
  };
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action setSelectedType = (value: string) => {
    this.selectedType = value;
    this.loadWorkflowData();
  };
  @action setWorkflowStatusselectedType = (value: string) => {
    this.selectedWorkflowStatusType = value;
    this.loadLookups();
    this.loadWorkflowData();

    if (this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue || this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue) {
      localStorage.setItem("isLoadUpline", "true");
    }
    else
    {
      localStorage.setItem("isLoadUpline", "false");
    }

  };
  @action loadWorkflowData = () => {
    this.startIndex = 0;
    this.rows = parseInt(localStorage.getItem("queueRows") || "50");
    setTimeout(() => {
      this.loadDocs();
    }, 100);
    if (this.selectedWorkflowStatusType === WorkflowStatusType.ReceivedQueue || this.selectedWorkflowStatusType === WorkflowStatusType.ScrubQueue) {
      localStorage.setItem("isLoadUpline", "true");
    }
    else
    {
      localStorage.setItem("isLoadUpline", "false");
    }
  };

  @action setSelectedProduct = (value: string) => {
    this.selectedProduct = value;
    this.loadWorkflowData();
  };
  @action setSelectedDivision = (value: string) => {
    this.selectedDivision = value;
  };

  @observable
  DocumentInfoViewModel: DocumentInfoViewModel = new DocumentInfoViewModel();

  @observable
  AddEditDocumentViewModel: AddEditDocumentViewModel =
    new AddEditDocumentViewModel();
  @observable documentDialogTitle: string = "";
  @observable isAddDocumentDialogVisible: boolean = false;
  @observable workFlowStatusType: any;

  @action setAddDocumentDialogVisibility = async (
    value: boolean,
    isEdit: boolean | undefined,
    fileName: any,
    workFlowStatusType: any,
    isCancel: boolean
  ) => {
    await this.loadLookups();
    this.AddEditDocumentViewModel.isReplaceDeleteFileName = false;
    this.workFlowStatusType = workFlowStatusType;
    this.AddEditDocumentViewModel.setWorkFlowStatusType(
      this.workFlowStatusType
    );

    if (
      value === false &&
      isCancel &&
      this.workFlowStatusType === WorkflowStatusType.ReceivedQueue
    ) {
      //clear the user metadata on cancel so that other users can work on the Received Queue file.
      if (fileName !== null && fileName !== undefined && fileName !== "") {
        await WorkflowStore.cancelFile(fileName);
        this.loadDocs();
      }
    }
    if (value) {
      this.AddEditDocumentViewModel.ResetValidate();
      this.AddEditDocumentViewModel.reset();
      if (isEdit) {
        this.documentDialogTitle = "Edit Document Information";
        var docId = this.docId;
        var result = await WorkflowStore.getWorkflowDocInfo(docId);
        if (result) {
          this.AddEditDocumentViewModel.Load(
            true,
            result.companyId ? result.companyId : 0,
            result.productId ? result.productId : 0,
            this.companyList.values
          );
          this.AddEditDocumentViewModel.setSelectedDocumentId(
            result.id ? result.id : 0
          );
          this.AddEditDocumentViewModel.setSelectedFile(
            result.filename ? result.filename : ""
          );
          this.AddEditDocumentViewModel.setFirstName(
            result.fName ? result.fName : ""
          );
          this.AddEditDocumentViewModel.setLastName(
            result.lName ? result.lName : ""
          );
          var date = utils.getDateInFormat(
            result.appDate ? result.appDate : new Date()
          );
          this.AddEditDocumentViewModel.setAppDate(new Date(date));
          this.AddEditDocumentViewModel.setSelectedAgent(
            result.agentName,
            result.agentId ? result.agentId : 0
          );
          this.AddEditDocumentViewModel.setSelectedUplineAgent(
            result.uplineAgentName,
            result.uplineAgentId ? result.uplineAgentId : 0
          );
          this.AddEditDocumentViewModel.setSelectedMarketerAgent(
            result.marketerAgentName,
            result.marketerAgentId ? result.marketerAgentId : 0
          );
          this.AddEditDocumentViewModel.setSelectedType(
            result.typeName ? result.typeName.trim() : ""
          );
          // this.AddEditDocumentViewModel.setSelectedCompany(
          //   result.companyId ? result.companyId : 0
          // );
          this.AddEditDocumentViewModel.setSelectedFlag(
            result.flag ? result.flag.trim() : ""
          );
          this.AddEditDocumentViewModel.setComments(
            result.notes ? result.notes : ""
          );
          // this.AddEditDocumentViewModel.setSelectedProduct(
          // result.productId ? result.productId : 0
          // );
        }
      } else {
        // set the user metadata to notify other users when the file is stealed.
        this.resetAddDocumentDialog();
        this.isSteal = false;
        this.updatefile(fileName, this.isSteal);

        this.documentDialogTitle = "Add Document to Workflow";
        this.AddEditDocumentViewModel.appDate = new Date();
        this.AddEditDocumentViewModel.setSelectedAssignedToUser("");
        this.AddEditDocumentViewModel.setSelectedAssignedToUserTeam("");
        this.AddEditDocumentViewModel.setSelectedCompany(0);
        this.AddEditDocumentViewModel.loadLookups(this.companyList.values);
        this.AddEditDocumentViewModel.setSelectedProduct(0);
        this.AddEditDocumentViewModel.setSelectedFile(fileName);
      }
      this.AddEditDocumentViewModel.setDialogVisibility(value);
    }
    this.isAddDocumentDialogVisible = value;
    // PageContext.setIsOverlay(!this.isAddDocumentDialogVisible)
  };

  @action resetAddDocumentDialog() {
    this.AddEditDocumentViewModel.exceptionMsg = "";
    this.AddEditDocumentViewModel.isException = false;
    this.AddEditDocumentViewModel.selectedAgent = "";
    this.AddEditDocumentViewModel.selectedAgentId = 0;
    this.AddEditDocumentViewModel.firstName = "";
    this.AddEditDocumentViewModel.lastName = "";
    this.AddEditDocumentViewModel.selectedType = "Application";
    this.AddEditDocumentViewModel.selectedFlag = "-1";
    this.AddEditDocumentViewModel.comments = "";
  }

  @observable response: any = "";
  @observable isMessgeVisible: boolean = false;

  cancelAddEditDocument(): void {
    this.setAddDocumentDialogVisibility(
      false,
      false,
      this.selectedDocRow.insuredName,
      this.workFlowStatusType,
      true
    );
  }

  closeDialogAfterSaveDocument(): void {
    this.setAddDocumentDialogVisibility(
      false,
      false,
      undefined,
      this.workFlowStatusType,
      false
    );
  }

  addEditDocumentListener(): void {
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, 2000);
    this.loadDocs();
  }

  documentInfoListener(): void {
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, 2000);
    this.loadDocs();
  }
  downloadFileListener(value: any): void {
    setTimeout(() => {
      this.isMessgeVisible = false;
      this.response = "";
    }, 2000);
    this.downloadFile(value);
  }
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @observable
  public ErrorModel = new ErrorModel(new Validator());
}

class Validator extends AbstractValidator<DocWorkflowViewModel> {
  public constructor() {
    super();
  }
}
