import React from 'react';
import { observer } from 'mobx-react';
import { PropectiveCallListViewModel } from '../../viewModels/autoDialers/ProspectiveCallListViewModel';
import { CustomDropdown } from '../components/controls/IMASDropdown';
import { CustomInputText } from "../components/controls/IMASInputText";
import { IMASMultiSelect } from '../components/controls/IMASMultiSelect';
import { IMASTreeNode } from '../components/controls/IMASTreeNode';
import { ResidentStateTypeContants } from '../../infrastructure/enum/Constants';
import { CustomInputTextArea } from '../components/controls/IMASInputTextArea';
import { IMASFormattedInput } from '../components/controls/IMASFormattedInput';
import { InputNumber } from 'primereact/inputnumber';
import { Repeater } from '../components/Repeater';
import { IMASCheckbox } from '../components/controls/IMASCheckbox';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { groupMultiselectItemsTemplate } from '../components/controls/IMASTemplates';
import { CreateCustomCallListViewModel } from '../../viewModels/autoDialers/CreateCustomCallListViewModel';
import Utils from '../../infrastructure/Utils';
import { IMASAutoComplete } from '../components/controls/IMASAutoComplete';
import { IMASCancelSaveView } from '../components/controls/IMASCancelSaveView';
import { AutoDialerViewModel } from '../../viewModels/autoDialers/AutoDialerViewModel';
import { IMASFilePicker } from '../components/controls/IMASFilePicker';
import { RadioButton } from 'primereact/radiobutton';

export const CreateCustomCallListView: React.FC<{ vm: CreateCustomCallListViewModel }> = observer(props => {
    const onMarketingEventChange = async (value: string) => {
          props.vm.marketingEvent = value;
         
    }
  
    const onAgentIDsChange = (value: any) => {
        let agentIds:string[]=[];
        let ids= value.toString();
        if(ids.length>0)
        {
           ids= ids.trimStart();
        }
       
        if( ids!='' && ids.indexOf(',')==-1)
        {
            agentIds = ids.split("\n");
        }
        else{ 
            agentIds =  ids.split(",");
       
        }
        props.vm.agentIds=ids;
   
    if(agentIds && agentIds.length>0)
        props.vm.agentList= agentIds.map(str=>Number(str));
        if(props.vm.agentList)
        { 
        for(let i=0; i< props.vm.agentList.length; i++)
        {
            if(isNaN( props.vm.agentList[i]))
            {
                props.vm.isInvalidAgentIds =true;
                props.vm.invalidAgentIDsMsg="Agent Ids can not be alphanumeric values"; 
                break;
            }
            else{
                props.vm.isInvalidAgentIds =false;
                props.vm.invalidAgentIDsMsg=""; 
            }
        }}
    }
  
    // const itemsTemplate = (option: any) => {
    //     if (option) {
    //         return <div className="list-item">{option.label}</div>;
    //     }
    //     return <div className="list-item">No Records.</div>;
    // };
    const callListNamechange = (value: string) => {
        props.vm.callListName=value.trim();
      };
          
      const setUser = (value: string) => {
        props.vm.selectedUser = value;
      };
    
    
    const setUserId = (value: string) => {
        props.vm.selectedUserId = value;
        };
    
        const onSelectUser = async(value: string) => {
            let obj = Utils.getObject(value);
            if (obj) {
                setUser(obj.text);
                setUserId(obj.value);
                
            } else {
                setUser("");
                setUserId("");
            }
        };
    
        const itemsTemplate = (option: any) => {
            if (option) {
              return <div className="list-item margin-bottom-5">{option.text}</div>;
            }
            return <div className="list-item">No Records.</div>;
        };
        
        const filterUser = (value: string) => {
            setTimeout(() => {
              setUserId("");
              props.vm.loadUsers(value);
            }, 250);
        };
        const onDrop = (value: any, filename: string) => {
            props.vm.onDrop(value, filename);
        }
    // refs all multi-select
    let leadSourceTarget = React.createRef<any>();
    let residenseStateTarget = React.createRef<any>();
    
    return (
        <div>
            
                 
                        <div className="p-grid">
                        <div className='p-col-12 p-md-4 p-lg-4'>
                                                <div className='form-group'>
                                                    <CustomInputText
                                                        errors={props.vm.ErrorModel.getError("callListName")}
                                                        label="Call List Name"
                                                        placeHolder=""
                                                        isrequired={true}
                                                        maxLength={50}
                                                        id='nickName'
                                                        onBlur={callListNamechange}
                                                        value={props.vm.callListName}
                                                        onChange={callListNamechange}
                                                        className=  "p-inputtext p-component"
                                                    />
  
                                                </div>

                                          
                            
                                <div className="form-group">
                                <IMASAutoComplete
                        errors={undefined}
                        label="Call List Created For"
                        placeHolder="All Users"
                        value={props.vm.selectedUser}
                        onChange={setUser}
                        onSelect={onSelectUser}
                        itemTemplate={itemsTemplate}
                        onComplete={filterUser}
                        suggestions={props.vm.userList.values}
                        isrequired={false}
                        id="user"
                        /> 
                                </div>
                               
                                 
                                <div className="form-group">
                                <CustomDropdown
                                        errors={undefined}
                                        label="Marketing Event for Call List"
                                        placeHolder=""
                                        disabled={false}
                                        id="marketingEventDropdown"
                                        isrequired={false}
                                        onBlur={() => { }}
                                        value={props.vm.marketingEvent}
                                        options={props.vm.marketingEventList.values}
                                        onChange={(value) => {
                                            onMarketingEventChange(value);
                                        }}
                                    />
                                     
                                        </div> 
                                         </div>

                        <div className="p-col-12 p-md-5 p-lg-3">
                        {props.vm.isUpload &&    <div className="form-group">
                        <label>Upload Agent IDs <span className="required">*</span></label>
                        <IMASFilePicker id="uploadFilePicker"
                            onDrop={(value: string, fileName: string) => onDrop(value, fileName)}
                            acceptFiles={['.xls','.xlsx','.csv']}
                            acceptMessage="File formats .xls or .xlsx or .csv having Agent IDs as column header in top row should be uploaded."
                        />
                        <Repeater errors={props.vm.ErrorModel.getError("fileData")} viewId="uploadFilePicker" 
                        child={(error) => <div className="invalid-feedback">{error}</div>} />
                    </div>}
                     {props.vm.isManual &&   <div className="form-group">
                                  
                                     <CustomInputTextArea
                                    
                                            errors={props.vm.ErrorModel.getError("agentIds")}
                                            label="Enter Agent Ids"
                                            
                                            placeHolder=""
                                            id="AgentIds"
                                            isrequired={true}
                                            rows={7}
                                            maxLength={undefined}
                                            onBlur={() => { }}
                                            value={props.vm.agentIds}
                                            onChange={(value) => {
                                                onAgentIDsChange(value)
                                            }}
                                        />
                                      
                                      <div className="validation-error">
                                            {props.vm.ErrorModel.getError("agentList")}
                                            </div> 
                                       
                           
                            </div> 
                            }
                            </div>
                            <div className="p-col-12 p-md-5 p-lg-5 validation-error">
                                            
                                         {  props.vm.isInvalidAgentIds && 
                                                <div className="form-group" style={{maxHeight:"180px", overflow:"auto"}}>
                                                {props.vm.invalidAgentIDsMsg}  
                                                </div>  
                                        }
                                </div>
                      <div className="p-col-12 p-md-12 p-lg-12">
                                <RadioButton
                                    name="agentsdownline"
                                    inputId="rb1"
                                    value="0"
                                    checked={ props.vm.isUpload}
                                    onChange={(value) => {
                                        props.vm.isUpload=value.checked;
                                        props.vm.isManual=!value.checked;                                        
                                    }} ></RadioButton>
                                <label htmlFor="rb1" className="p-checkbox-label"> Upload File</label> &nbsp; &nbsp;&nbsp;&nbsp;

                        
                                <RadioButton
                                    name="agentsdownline"
                                    inputId="rb1"
                                    value="0"
                                    checked={ props.vm.isManual}
                                    onChange={(value) => {
                                        props.vm.isManual=value.checked;
                                        props.vm.isUpload=!value.checked;
                                    }} ></RadioButton>
                                <label htmlFor="rb1" className="p-checkbox-label"> Manually List Agent IDs</label> &nbsp; &nbsp;&nbsp;&nbsp;

                        </div>
                      
                            <div className="p-col-12">
              <IMASCancelSaveView
                onSave={props.vm.onSubmit}
                onCancel={props.vm.goBack}
              />
            </div>
        </div >
        </div>
    );
});

export default CreateCustomCallListView;