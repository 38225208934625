import { observable, action } from 'mobx';
import { AppMenuItems } from './enum/Common';
import { Constants } from './enum/Constants';
import RouteParamDetails from './RouteParamDetails';
import Utils from './Utils';
type NewType = undefined;
class PageContext {
    @observable isMessageVisible?: boolean;
    @observable isCustomAttribute?: boolean;
    @observable currentPage?: boolean = false;
    @observable isException?: boolean;
    @observable isOverlayRequired: boolean = false;
    @observable isFromAddContract: string = 'Add';
    @observable isFromEdit: boolean = false;
    @observable responseMessage?: any  = [];
    @observable masterCarrierGroupData?: any  = [];
    @observable upLineAgentId: number = 0;
    @observable moduleName: AppMenuItems = AppMenuItems.Agents;
    @observable hideNextResponse: boolean = false;
    @observable hasUserAccess: boolean = false;
    @observable isAddRenewal: boolean = false;
    @observable isMovedToDataEntry: boolean = false;
    @observable isBackgroundMessageDisable: boolean = false;
    getTimeOutResponse(){
      let message = '';
      switch(this.moduleName)
      {
        case AppMenuItems.SelfServiceReports:
          message = Constants.ReportsTimeoutIssue; 
          break;
          default:
           message = Constants.BadGatewayError;
      }
      let response = {
        status: 400,
        title: message,
        errors: { "": [] },
      };
      return response;
    }
    @action setModuleName = (value: AppMenuItems) => {
      this.moduleName = value;
    } 
    @action setIsMessageVisible = (value: boolean) => {
      if(!this.isBackgroundMessageDisable)      
      this.isMessageVisible = value;
      if(value)
      {      
        window.scrollTo(0,0);
      }     
    }
    @action setResponseMessage = (message: string, isException?: boolean, isFromReport:boolean = false) => {     
      // if(this.moduleName === AppMenuItems.SelfServiceReports && !isFromReport) {
      //   return;
      // }

      this.isException = isException ? true :false;
      this.responseMessage = message;

      if(this.hideNextResponse) {
        return;
      }

      // Below condition is for only add/edit customer response
      if (message){
        let error_title = JSON.parse(message).title
        if (error_title && error_title !== undefined && error_title.includes('=')) {
          var local_url = window.location;
          var er = error_title.split("=")
          if(er && er.length === 2 && (local_url.href.includes('addCustomer') || local_url.href.includes('/customers/'))){
            this.isLoading = false;
            this.isException = true;
            var responseModel = {
                status: 400,
                title: "A Insured with this same "+ er[0] +" already exists in the system. <a target='_blank' href='"+Utils.getServiceUrlByEnvironment()+"/customers/"+er[1]+"'> Click here to view </a>",
                errors: { "": [] },
            };
            this.responseMessage = JSON.stringify(responseModel);
          }
          if (er && er[0] && er[0] === "NAME"){
            RouteParamDetails.customerIgnoreNameValidation = true
          }
          else{
            RouteParamDetails.customerIgnoreNameValidation = false
          }
        
        }
      }
    }
    @action getResponseMessage(): any {
      return this.responseMessage;
    }
    @action setHideNextResponse(value: boolean) {
      this.hideNextResponse = value;
    }
    @action setIsOverlay(isOverlay: boolean)
    {
      this.isOverlayRequired = isOverlay;
    }
    @action setIsFromAddContract(value: string)
    {
      this.isFromAddContract = value;
    }
    @action setIsFromEdit(isFromEdit: boolean)
    {
      this.isFromEdit = isFromEdit;
    }
    @action setIsMovedToDataEntry(isMovedToDataEntry: boolean){
      this.isMovedToDataEntry = isMovedToDataEntry
    }

    @action setUpLineAgentId(value: number)
    {
      this.upLineAgentId = value;
    }
    @action setUserAccess = (value: boolean) => {
      this.hasUserAccess = value;
    } 
    @observable isLoading : boolean = false;
    private loadWaitCount: number = 0;
    private static readonly ShowAfterDelay: number = 2500;
    private isOverlayEnabled: boolean = true;
    @action setIsLoadingVisible = (value: boolean) => {
      if(!this.isOverlayEnabled)
        return;

      if(value) {
        this.loadWaitCount++;
        if(this.loadWaitCount === 1) {
          setTimeout(() => {
            this.isLoading = (this.loadWaitCount > 0) && (this.isOverlayEnabled);
          }, PageContext.ShowAfterDelay);
        }
      }
      else {
        this.loadWaitCount--;
        if(this.loadWaitCount < 1) {
          this.isLoading = false;
          this.loadWaitCount = 0;
        }
      }
    }
    @action setIsOverlayEnabled(value: boolean) {
      this.isOverlayEnabled = value;
      if(value) {
        this.isLoading = false;
        this.loadWaitCount = 0;
      }
    }
    @action setAutoCompleteScroll()
    {
      var element = document.querySelector(".p-autocomplete-panel");
      setTimeout(() => {
        if (element) {
          element.scrollTo(0, 0);
        }
       }, 10)
    }
  }
  
  export default new PageContext();