import React from "react";
import { observer } from "mobx-react";
import { AddEditMarketingEventListViewModel } from "./../../viewModels/home/AddEditMarketingEventListViewModel";
import { CustomInputText } from "../components/controls/IMASInputText";
import { CustomInputDate } from "../components/controls/CustomInputDate";
import { IMASCancelSaveView } from "../components/controls/IMASCancelSaveView";
export const AddEditMarketingEventListView: React.FC<{
  vm: AddEditMarketingEventListViewModel;
  eventId: number | undefined
}> = observer((props) => {
  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="form-group">
            <CustomInputText
              errors={props.vm.ErrorModel.getError("addListTitle")}
              id={"title"}
              isrequired={false}
              maxLength={undefined}
              label={"Add Name"}
              value={props.vm.addListTitle}
              placeHolder=""
              onBlur={() => {}}
              onChange={(value) => props.vm.setAddListTitle(value)}
            />
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="form-group">
            <CustomInputDate
              value={props.vm.startTime}
              onChange={(value) => props.vm.setStartTime(value)}
              dateFormat="mm/dd/yy"
              errors={props.vm.ErrorModel.getError("startTime")}
              label="Start Time"
              isrequired={false}
              id="startTime"
              minDate={new Date(props.vm.a)}
              isShowTime={true}
            />
            {props.vm.validationErrorDateTime && (
                <div className="invalid-feedback">{`Start Date time should be less than End Date time`}</div>
              )}
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="form-group">
            <CustomInputDate
              value={props.vm.endTime}
              onChange={(value) => props.vm.setEndTime(value)}
              dateFormat="mm/dd/yy"
              errors={props.vm.ErrorModel.getError("endTime")}
              label="End Time"
              isrequired={false}
              id="endTime"
              minDate={props.vm.startTime || new Date(props.vm.a)}
              isShowTime={true}
            />
          </div>
        </div>
        <div className="p-col-12">
            <IMASCancelSaveView onSave={() => props.vm.save(props.eventId)} onCancel={() => props.vm.cancel()} />
        </div>
       
      </div>
    </>
  );
});
