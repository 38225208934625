import { observable, action, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import ContractsStore from "../../store/ContractsStore";
import {
  AgentListModel,
  AutoRTSRequest,
} from "../../services/ProducerSearchService";
import Utils from "../../infrastructure/Utils";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import * as mockData from "./MockData.json";
import {
  ApiException,
  ImmediateUplineResponse,
  InconsistencyAgentsRequest,
  InconsistencyContractsRequest,
  LookupsForInconsistencyContractRequest,
  MasterCarrierGroupModel,
  ProductDetailsModel2,
} from "../../services/ContractService";
import UserContext from "../../infrastructure/UserContext";
import axios from "axios";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../components/controls/IMASTemplates";
import ProducerStore from "../../store/ProducerStore";
import { googleTagManager } from "../../infrastructure/tracking";
import { routes } from "../../router";
import { data } from "azure-maps-control";
import { NotificationTemplateName } from "../../infrastructure/enum/Contract";
import NotificationStore from "../../store/NotificationStore";
import { NotificationListModel } from "../../services/NotificationService";
import { createRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Events, RowNode } from "ag-grid-community";
import PageContext from "../../infrastructure/PageContext";
// import useCallbackPrompt from "./useCallbackPrompt";
// import useExitPrompt from "./UnsavedChangesDialog";
export class RtsReviewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable isLoading: boolean = false;

  @observable editedRowIndexes: any;
  @observable selectedCarrierId: any;
  @observable selectedCarrierListFilters: any = [];
  @observable selectedCarrierNameFilters: any = [];
  @observable selectedCarrierInfo: any;
  @observable selectedCarrierIDFilters: any = [];
  @observable selectedAgentNameFilters: any = [];
  @observable accordionExpandIndex: any;
  @observable selectedAgentIdFilters: any = [];
  @observable carrierList = new Collection<MasterCarrierGroupModel>();
  @observable isSelectedAllCarriers: boolean = false;
  @observable refreshVisible: boolean = false;
  @observable selectedAgent: string = "";
  @observable searchInputValue: string = "";
  @observable AgentsList = new Collection<AgentListModel>();
  @observable totalRecords: number = 0;
  @observable selectedAgentList: any;
  @observable hasAgentInvalidValue: boolean = false;
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable agentId: number | undefined;
  @observable agentIdCheck: number = 0;
  @observable agentsSearchList: number = 0;
  @observable actionData: any=[];
  @observable actionResetData: any;
  @observable isSearchedFilter: boolean = false;
  @observable filteredUplineList = new Collection<ImmediateUplineResponse>();
  @observable isUplineData: boolean = false;
  @observable gridRTSData: any;
  @observable clickedNPNforRTSData: any;
  @observable gridApi: any;
  @observable gridApiNoRows: string = "<span></span>";
  @observable statusList: any = [];
  @observable statusReasonList: any = [];

  @observable productCountAfterSave: number = 0;
  @observable isUpdateDownlineDNx: boolean = false;
  @observable isAgentStatusUpdated: boolean = false;
  @observable isUpdateDownlineDiloagDisplay: boolean = false;
  @observable terminateDownlineContract: boolean = false;
  @observable notificationSendVisibility: boolean = false;
  @observable isUpdateAgentStatusConfirm: boolean = false;
  @observable selectedAgentUpdateStatusId: string = "";
  @observable updateAgentStatusBtn: boolean = true;
  @observable isEmailNotificationActive: boolean = false;
  @observable isEmailNotificationSend: boolean = false;
  @observable rowDataForSave: any;
  @observable isAWNDiloagDisplay: boolean = false;
  @observable rowDataAWN: any;
  @observable deleteTitle: string = "";
  @observable isAWN: boolean = true;
  @observable isUnsavedChanges: boolean = false;
  @observable unsavedchangesContractId: any = [];
  @observable agentUpdateStatuses = new Collection<{
    label: string;
    value: string;
  }>();
  @observable keyRef: any= Math.random();
  @observable isShowMore: boolean = true;
  @observable showMoreCounter: number = 10;
  @observable scrollPosition: number = window.scrollY;
  @observable showMoreSkipCounter: number = 0;
  @observable inconsistancyCount: number = 0;

  @action Load = async () => {
    this.resetFilters();
    this.loadEmailNotificationTemplates();
    this.isUnsavedChanges = false;
    window.addEventListener("beforeunload", this.onRefresh);
    this.handleRouteOnUnsaved();
    localStorage.removeItem("rtsUnsavedChanges"); 
    // var result = PageContext.masterCarrierGroupData;
    // await this.loadCarriers(result);
    await this.loadCarriers();
  };

  @action loadCarriers = async ( ) => {
    var defaultItem = { label: "ALL", value: "0" };
    // var result = PageContext.masterCarrierGroupData;
    // console.log("LVC carriers context ", toJS(PageContext.masterCarrierGroupData));
    var result = await ContractsStore.getAllMasterCarrierGroupLookup();
    if (result) {
      var assign: any = [];

      if (result != null) {
        result.forEach((item) => {
          var data = {
            label: item.name ? item.name : "",
            value: item.id ? item.id : "",
            groupName: item.carrierGroupName ? item.carrierGroupName : "",
          };
          assign.push(data);
        });
      }
      this.carrierList.values = assign;
      setTimeout(() => {
        this.isSelectedAllCarriers = true;
        this.selectedCarrierNameFilters = [];
        this.selectedCarrierIDFilters = [];
        this.selectedCarrierId = this.carrierList.values.map((item: any) => {
          return item.value + "";
        });
        this.updateSelectedCarrierFilters(
          this.selectedCarrierId,
          0,
          "selectedAll",
          0,
          ""
        );
      }, 200);
    }
  };

  @observable NotificationTemplatesList =
    new Collection<NotificationListModel>();
  @action loadEmailNotificationTemplates = async () => {
    const activeNotificationTemplates =
      await NotificationStore.getEmailTemplates(0, 100, "id", false, "", 1);
    if (activeNotificationTemplates) {
      this.NotificationTemplatesList.values =
        activeNotificationTemplates.data || [];

      if (this.NotificationTemplatesList.values.length > 0) {
        var results = this.NotificationTemplatesList.values.filter(
          (item: any) => {
            return (
              item.name.toLowerCase() ==
                NotificationTemplateName.ContractUpdateTOH.toLowerCase() ||
              item.name.toLowerCase() ==
                NotificationTemplateName.ContractUpdateLoginUser.toLowerCase()
            );
          }
        );
        this.isEmailNotificationActive =
          results && results.length > 0 ? true : false;
      } else {
        this.isEmailNotificationActive = false;
      }
    } else {
      this.isEmailNotificationActive = false;
    }
  };

  @action resetRefesh() {
    if (this.selectedCarrierId == "0") this.refreshVisible = false;
    else this.refreshVisible = true;
  }
  @action searchAgents = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookupForRTSReview(
      1,
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        // this.selectedAgent = "";
      }
    } else {
      this.agentsSearchList = 0;
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
    }
    this.isLoading = false;
  };

  @action searchReportsTo = async (
    contractId: number,
    agentId: number,
    companyId: number,
    value: string
  ) => {
    try {
      const result = await ContractsStore.getImmediateUpline(
        contractId,
        agentId,
        0,
        100,
        "Id",
        true,
        value,
        +companyId,
        true
      );
      return this.loadReportsToList(result);
    } catch (e) {
      const noAgent: ImmediateUplineResponse = { agentId: 0 };
      this.filteredUplineList.values = [noAgent];
    }
  };

  @action loadReportsToList = async (data?: any[]) => {
    let list = [];
    if (data != null && data.length > 0) {
      list = data;
    } else {
      const noAgent: ImmediateUplineResponse = { agentId: 0 };
      list = [noAgent];
    }
    return list;
  };

  @action setCarrierSelection = (value: any) => {
    let selectedCarrierTemp = this.carrierList.values.filter((item: any) => {
      return item.value == value[value.length - 1];
    });
    this.selectedCarrierId = value;
    if (this.selectedCarrierId.length == this.carrierList.values.length) {
      this.updateSelectedCarrierFilters(
        value,
        0,
        "selectedAll",
        0,
        selectedCarrierTemp
      );
    } else {
      this.updateSelectedCarrierFilters(
        value,
        0,
        this.selectedCarrierId.length < 1 ? "empty" : "search",
        0,
        selectedCarrierTemp
      );
    }
  };

  @action resetCarrierFilters = () => {
    this.selectedCarrierId = [];
    this.selectedCarrierNameFilters = [];
    this.selectedCarrierIDFilters = [];
    this.isSelectedAllCarriers = false;
  };
  @action resetFilters = () => {
    this.selectedAgent = "";
    this.selectedCarrierNameFilters = [];
    this.selectedCarrierIDFilters = [];
    this.selectedAgentNameFilters = [];
    this.selectedAgentIdFilters = [];
    this.selectedCarrierId = "";
    this.actionData = undefined;
    this.actionResetData = undefined;
    this.isSearchedFilter = false;
    this.setAllCaariersToSelect();
    this.clickedNPNforRTSData = [];
    this.isUnsavedChanges = false;
    localStorage.removeItem("rtsUnsavedChanges");
    this.showMoreCounter = 10;
    this.showMoreSkipCounter = 0;
    this.isShowMore = true;
    this.scrollPosition = window.scrollY;
    this.inconsistancyCount = 0;
  };

  @action setAllCaariersToSelect = () => {
    this.selectedCarrierId = "";
    this.selectedCarrierId = this.carrierList.values.map((item: any) => {
      return item.value + "";
    });
    this.isSelectedAllCarriers = true;
    this.selectedCarrierNameFilters = [];
    this.selectedCarrierIDFilters = [];
    this.carrierList.values.map((item: any) => {
      this.selectedCarrierNameFilters.push(item.label);
      this.selectedCarrierIDFilters.push(item.value);
    });
  };

  @action updateSelectedCarrierFilters = async (
    value: string,
    id: any,
    searchSelect: string,
    idx: number,
    removedID: any
  ) => {
    if (searchSelect == "empty") {
      this.resetCarrierFilters();
      return false;
    }
    if (searchSelect == "selectedAll") {
      this.setAllCaariersToSelect();
    } else {
      this.isSelectedAllCarriers = false;
    }
    if (searchSelect == "search") {
      let selectedCarrierTemp = this.carrierList.values.filter((item: any) => {
        return item.value == value[value.length - 1];
      });
      if (
        !this.selectedCarrierNameFilters.includes(
          selectedCarrierTemp[0].label
        )
      ) {
        this.selectedCarrierNameFilters.push(selectedCarrierTemp[0].label);
        this.selectedCarrierIDFilters.push(selectedCarrierTemp[0].value);
      } else {
        setTimeout(() => {
          if (this.selectedCarrierInfo) {
            this.selectedCarrierIDFilters =
              this.selectedCarrierIDFilters.filter(
                (e) => e !== this.selectedCarrierInfo.value
              );
            this.selectedCarrierNameFilters =
              this.selectedCarrierNameFilters.filter(
                (e) => e !== this.selectedCarrierInfo.label
              );
          } else {
            var final: any = [];
            final = this.carrierList.values.filter((item) => {
              return !this.selectedCarrierId.includes(item.value);
            });
            this.selectedCarrierIDFilters =
              this.selectedCarrierIDFilters.filter(
                (e) => e !== final[final.length - 1].value
              );
            this.selectedCarrierNameFilters =
              this.selectedCarrierNameFilters.filter(
                (e) => e !== final[final.length - 1].label
              );
          }
        }, 450);
      }
    }
    if (searchSelect == "remove") {
      this.selectedCarrierIDFilters = this.selectedCarrierIDFilters.filter(
        (e) => e !== this.selectedCarrierInfo.value
      );
      this.selectedCarrierNameFilters = this.selectedCarrierNameFilters.filter(
        (e) => e !== this.selectedCarrierInfo.label
      );
      this.selectedCarrierId = "";
      this.selectedCarrierId = this.selectedCarrierIDFilters;
    }
  };
  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
  }
  @action updateSelectedAgentFilters(
    value: string,
    id: any,
    searchSelect: boolean,
    idx: number
  ) {
    if (searchSelect) {
      if (!this.selectedAgentNameFilters.includes(value)) {
        this.selectedAgentNameFilters.push(value);
        this.selectedAgentIdFilters.push(id);
      }
      this.selectedAgent = "";
    } else {
      this.selectedAgentNameFilters.splice(
        this.selectedAgentNameFilters.indexOf(value),
        1
      );
      this.selectedAgentIdFilters.splice(idx, 1);
    }
  }

  @action setSelectedAgentId(id: any) {
    // this.agentId = id; // selected agent id array here
  }
  @action searchRTS = async (isShowMore:boolean) => {
    PageContext.isLoading = true;
    let reqData: any = {
      // let reqData: InconsistencyAgentsRequest = {
      carrierIds: this.isSelectedAllCarriers ? null : this.selectedCarrierId,
      agentIds: this.selectedAgentIdFilters,
      PageIndex: this.showMoreSkipCounter,
      PageSize: 10,
      // skip: this.showMoreSkipCounter,
      SortColumn: '',
      SortAscending: false,
      FullTextFilter: ''
    };
    var res = await ContractsStore.getInconsistencyAgentsForAutoRTS(reqData);

    if (res) {
      if(isShowMore){
        
        let tempArray = [];
        tempArray = [...this.actionData, ...res.data];
        this.actionData = [];
        this.actionData = tempArray;
        // console.log("LVC carriers context ", toJS(this.actionData), toJS(res.data));
        this.actionResetData = JSON.stringify(tempArray); 
          var cusrrentScrollPosition = window.scrollY;  //your current y position on the page
          let ScrollToView = this.scrollPosition - cusrrentScrollPosition;
          window.scrollTo(0, ScrollToView + 210);
          // console.log("LVC -- ", this.scrollPosition, cusrrentScrollPosition, ScrollToView  ); 
      }else{
        this.actionData = undefined;
        this.actionData = res.data;
        this.actionResetData = JSON.stringify(res);
      }
      this.isSearchedFilter = true;
      this.isSearchedFilter = res.data && res.data.length >0;
      this.isShowMore  = res.data &&  !(res.data.recordCount == this.showMoreCounter ||  this.showMoreCounter > res.data.recordCount);
      PageContext.isLoading = false;
      if(this.selectedAgentNameFilters && this.selectedAgentNameFilters.length ==0){
        this.inconsistancyCount = res.recordCount;
      }
    }
  };

  @action getRTSContracts = async (e: any) => {
    let rowData = this.actionData[e.index];
    let agentName = "";
    if (rowData.name.includes("-")) {
      agentName = rowData.name.split("-")[1].trim();
    }
    if (!rowData.isContractsAdded) {
      let reqData: InconsistencyContractsRequest = {
        carrierIds: this.isSelectedAllCarriers ? null : this.selectedCarrierId,
        agentId: rowData.agentId,
        npn: rowData.npn,
      };

      this.getAutoRTSData(rowData.npn, rowData);
      var res = await ContractsStore.getInconsistencyContracts(reqData);
      if (res) {
        let resContracts =
          res.contracts &&
          res.contracts.map((data: any) => {
            data.initialData = JSON.stringify(data);
            data.setIsChanged = this.setIsChanged;
            data.agentName = agentName;
            data.immediateUplineNamePlaceHolder =
              data.immediateUplineName || "";
            return data;
          });
        this.actionData = this.actionData.map((obj: any) => {
          if (obj.agentId == rowData.agentId && res) {
            obj.contractData = resContracts || [];
            obj.statusList = res.statusList;
            obj.statusReasonList = res.statusReasonList;
            obj.isContractsAdded = true;
          }
          return obj;
        });

        this.statusList = res.statusList;
        this.statusReasonList = res.statusReasonList;
        this.actionResetData = JSON.stringify(this.actionData);
        this.isSearchedFilter = true;
      }
    }
  };

  @action validateEndDate = (rowData: any) => {
    if (!rowData.contractEndDate && rowData.status + "" === "2") {
      rowData.isEndDateRequired = true;
    } else {
      rowData.isEndDateRequired = false;
    }

    if (
      rowData.contractEndDate &&
      new Date(rowData.contractStartDate) <= new Date(rowData.contractEndDate)
    ) {
      rowData.isEndDateInvalid = false;
    } else {
      if (rowData.statusId + "" !== "2") {
        rowData.isEndDateInvalid = false;
      } else {
        rowData.isEndDateInvalid = true;
      }
    }
  };
  @action setIsChanged = (rowData: any, rowParams: any) => {
    this.validateEndDate(rowData);
    let initialData = JSON.parse(rowData.initialData);
    rowData.isChangeDetected = this.findChangeDetection(rowData, initialData);
    this.isUnsavedChanges = rowData.isChangeDetected;
    this.isUnsavedChanges && this.handleRouteOnUnsaved();
    let tempArray = this.unsavedchangesContractId;
    if (rowData.isChangeDetected) {
      if (!tempArray.includes(rowData.contractId)) {
        tempArray.push(rowData.contractId);
      }
    } else {
      tempArray.splice(tempArray.indexOf(rowData.contractId), 1);
    }
    this.unsavedchangesContractId = [];
    this.unsavedchangesContractId = [...new Set(tempArray)];
    tempArray = [];
    let setChangeFlag = this.isUnsavedChanges ? "change" : "";
    if (this.isUnsavedChanges) {
      localStorage.setItem("rtsUnsavedChanges", setChangeFlag);
    } else {
      localStorage.removeItem("rtsUnsavedChanges");
    }

    rowData.isValid = this.validateFields(rowData);
    let action = rowData.action ? true : false;
    if (rowParams && rowParams.node) {
      rowParams.node.setDataValue &&
        rowParams.node.setDataValue("action", !action);
    } else {
      rowParams &&
        rowParams.setDataValue &&
        rowParams.setDataValue("action", !action);
    }
  };

  @action getAutoRTSData = async (npn: string, rowData: any) => {
    let carrierGroupNames = this.isSelectedAllCarriers
      ? null
      : this.selectedCarrierNameFilters;
    let reqData: AutoRTSRequest = {
      carriers: this.isSelectedAllCarriers
        ? null
        : this.selectedCarrierNameFilters,
      npn: npn,
      buname: Utils.getBUName().toUpperCase(),
    };
    var result = await ProducerSearchStore.getAutoRTSData(reqData);
    if (result && result.data) {
      this.actionData = this.actionData.map((obj: any) => {
        if (obj.agentId == rowData.agentId && result) {
          obj.gridRTSData = result.data;
        }
        return obj;
      });
    }
    // }
  };

  @action onCellValueChanged(params: any) {
    if (
      this.editedRowIndexes &&
      !this.editedRowIndexes.includes(params.node.rowIndex)
    ) {
      this.editedRowIndexes.push(params.rowIndex);
    }
    this.editedRowIndexes.push(params.rowIndex);
    var p = {
      force: true,
    };
    this.gridApi.refreshCells(p);
  }

  @action async onCellExpanded(event: any, applyExpand: any) {
    if (!event.node.data.isDetailLoaded) {
      let reqData = {
        contractId: event.node.data.contractId,
        carrierId: event.node.data.companyId,
      } as LookupsForInconsistencyContractRequest;
      var res = await ContractsStore.getLookupsForInconsistencyContract(
        reqData
      );
      if (res) {
        event.node.data.isDetailLoaded = true;
        event.node.data.products = res.products || [];
        event.node.data.category = res.category || [];
        event.node.data.subCategory = res.subCategory || [];
        event.node.data.productList = res.productList || [];
        event.node.data.levelList = res.levelList || [];
        event.node.data.marketerList = res.marketerList || [];
        event.node.data.writingNumberTypeList = res.writingNumberTypeList || [];
        event.node.data.writingNumbers = res.writingNumbers || [];
        let initialData = JSON.parse(event.node.data.initialData);
        let products =
          res.products &&
          res.products.map((i: any) => {
            i.productId =
              (i.productIds &&
                i.productIds.map((k: any) => {
                  return k.productId;
                })) ||
              [];
            return i;
          });
        initialData.products = products || [];
        initialData.writingNumbers = res.writingNumbers || [];
        event.node.data.initialData = JSON.stringify(initialData);
        this.handleHiddenProducts(event.node.data);
      }
    }

    if (applyExpand) {
      let nodeExpanded = !event.node.expanded;
      event.node.setExpanded(nodeExpanded);
      event.onValueChange && event.onValueChange(nodeExpanded);
      event.setValue && event.setValue(nodeExpanded);
    }

    if (event.node.expanded && event.node.detailNode) {
      let detailNode = event.node.detailNode;
      let prodLength = (event.data.products && event.data.products.length) || 1;
      let awnLength =
        (event.data.writingNumbers && event.data.writingNumbers.length) || 1;
      setTimeout(() => {
        let height = detailNode.detail
          ? 260 + (prodLength + awnLength) * 50
          : detailNode.rowHeight;
        detailNode.setRowHeight && detailNode.setRowHeight(height, true);
        detailNode.eventService.dispatchEvent(
          detailNode.createLocalRowEvent(RowNode.EVENT_EXPANDED_CHANGED)
        );
        var eventExpand = Object.assign(
          {},
          detailNode.createGlobalRowEvent &&
            detailNode.createGlobalRowEvent(Events.EVENT_ROW_GROUP_OPENED),
          {
            expanded: true,
            event: null,
          }
        );
        detailNode.beans.rowNodeEventThrottle.dispatchExpanded(eventExpand);
      }, 0);
    }
  }

  @action handleHiddenProducts = (data: any) => {
    if (data.products && data.products.length > 0) {
      let i = 1;
      data.products.forEach((element: any) => {
        i++;
        element.rowId = new Date().getTime() + i;
        element.productId = [];
        element.categoryList = [];

        element.profileProductIds = [];
        element.profileSubcategoryIds = [];
        element.profileCategoryIds = [];
        element.productIds!.forEach((item: any) => {
          element.productId.push(item.productId + "");
          if (item.profileAssociation) {
            element.profileProductIds.push(item.productId + "");
          }
        });

        element.profileAssociation =
          element.profileProductIds && element.profileProductIds.length > 0;
        element.existingProductIds = element.productId;
        element.categoryList = JSON.parse(JSON.stringify(data.category));
        let subcategoryList = JSON.parse(JSON.stringify(data.subCategory));
        let productNameList = JSON.parse(JSON.stringify(data.productList));

        subcategoryList = subcategoryList.filter((item: any) => {
          return (
            element.categoryIds &&
            element.categoryIds.indexOf(item.categoryId) > -1
          );
        });
        productNameList =
          productNameList &&
          productNameList.filter((item: any) => {
            return (
              element.subCategoryIds &&
              element.subCategoryIds.indexOf(item.subCategoryId) > -1
            );
          });
        element.categoryIds = element.categoryIds.map((item: any) => {
          return item + "";
        });
        element.subCategoryIds = element.subCategoryIds.map((item: any) => {
          return item + "";
        });

        let activeProductList = productNameList.filter((i) => {
          return !i.hidden || element.productId.indexOf(i.value) > -1;
        });

        ///// Profile association code changes
        if (element.profileProductIds && element.profileProductIds.length > 0) {
          activeProductList = activeProductList.map((i: any) => {
            i.disabled = element.profileProductIds.indexOf(i.value) > -1;
            return i;
          });

          element.profileSubcategoryIds = data.productList
            .filter((item: any) => {
              return (
                element.profileProductIds &&
                element.profileProductIds.indexOf(item.value) > -1
              );
            })
            .map((item: any) => {
              return item.subCategoryId + "";
            });
          element.profileCategoryIds = data.subCategory
            .filter((item: any) => {
              return (
                element.profileSubcategoryIds &&
                element.profileSubcategoryIds.indexOf(item.value) > -1
              );
            })
            .map((item: any) => {
              return item.categoryId + "";
            });

          subcategoryList = subcategoryList.map((i: any) => {
            i.disabled = element.profileSubcategoryIds.indexOf(i.value) > -1;
            return i;
          });

          element.categoryList = element.categoryList.map((i: any) => {
            i.disabled = element.profileCategoryIds.indexOf(i.value) > -1;
            return i;
          });
        }

        element.subcategoryList = subcategoryList;
        element.productNameList = activeProductList;
        element.existingMarketerId = element.marketerId;
      });
      data.isProductLoaded = true;
    }
  };

  @action resetContractPopups = () => {
    this.isUpdateDownlineDNx = false;
    this.isAgentStatusUpdated = false;
    this.isUpdateDownlineDiloagDisplay = false;
    this.terminateDownlineContract = false;
    this.notificationSendVisibility = false;
    this.isUpdateAgentStatusConfirm = false;
    this.selectedAgentUpdateStatusId = "";
    this.updateAgentStatusBtn = true;
    this.isEmailNotificationActive = false;
    this.isEmailNotificationSend = false;
  };

  @action async setUpdateContractwithDNxDisplay(val: boolean) {
    this.isUpdateDownlineDiloagDisplay = val;
  }

  @action terminateDownlineContractDialog = (value: boolean) => {
    this.terminateDownlineContract = value;
  };

  @action setNotificationSendVisibility = (value: boolean) => {
    this.notificationSendVisibility = value;
  };
  @action setUpdateAgentStatusConfirmation = async (value: boolean) => {
    this.isUpdateAgentStatusConfirm = value;
  };

  @action agentUpdateStatusChange = async (value: string) => {
    this.selectedAgentUpdateStatusId = value;
    this.updateAgentStatusBtn =
      this.rowDataForSave.agentStatusId == value ? true : false;
  };

  @action loadAgentStatus = async () => {
    let res = await ProducerStore.reportAgentStatus();
    if (res) {
      res.forEach((obj: any) => {
        var data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value.toString() : "",
        };

        this.agentUpdateStatuses.values.push(data);
      });
      this.selectedAgentUpdateStatusId =
        this.rowDataForSave.agentStatusId ||
        this.agentUpdateStatuses.values[0].value;
    } else {
      this.agentUpdateStatuses.values = [];
    }
  };

  @action setKeepItAsIsConfirmation = async () => {
    this.selectedAgentUpdateStatusId = "0";
    this.isUpdateAgentStatusConfirm = false;
    await this.saveChanges();
  };

  @action updateAgentStatus = async () => {
    this.isAgentStatusUpdated = true;
    await this.saveChanges();
  };
  @observable saveRowParams: any;
  @observable gridContractRef = createRef<AgGridReact>();
  @action agentStatusCheckAndSave = async (rowData: any, rowParams: any) => {
    this.resetContractPopups();
    let initialData = JSON.parse(rowData.initialData);
    this.rowDataForSave = rowData;
    this.saveRowParams = rowParams;

    if (
      initialData.statusId &&
      initialData.statusId !== "2" &&
      initialData.activeLicenseCount === 1 &&
      rowData.statusId === "2"
    ) {
      await this.loadAgentStatus();
      this.isUpdateAgentStatusConfirm = true;
    } else {
      await this.saveChanges();
    }
  };

  @action resetAgentFilters = () => { 
    this.selectedAgentNameFilters = undefined;
  };

  @action saveChanges = async () => {
    this.isUpdateAgentStatusConfirm = false;
    var writingNumbers =
      this.rowDataForSave.writingNumbers &&
      this.rowDataForSave.writingNumbers.filter(
        (item: any) => item.licenseNumberTypeId && item.number
      );
    var newlyAWNAdded =
      this.rowDataForSave.writingNumbers &&
      this.rowDataForSave.writingNumbers.filter(
        (item: any) => item.writingNumberId == 0
      );
    var isWritingNumberCheck = newlyAWNAdded && newlyAWNAdded.length > 0;
    googleTagManager.trackAction("contract_created", {
      feature: routes.rtsReview.name,
      user_id: UserContext.userId,
      agent_id: this.rowDataForSave.agentId,
      carrier_id: this.rowDataForSave.companyId,
    });

    if (
      this.isEmailNotificationActive &&
      this.rowDataForSave.statusId === "1" &&
      isWritingNumberCheck
    ) {
      var commaSeperatedWrittingNumbers = writingNumbers
        .map((item: any) => {
          return item.number;
        })
        .join(",");
      googleTagManager.trackAction("awn_added_to_contract", {
        feature: routes.rtsReview.name,
        user_id: UserContext.userId,
        agent_id: this.rowDataForSave.agentId,
        Awn: commaSeperatedWrittingNumbers,
      });
      await this.setNotificationSendVisibility(true);
    } else {
      await this.showSuccess(false);
    }
    let initialData = JSON.parse(this.rowDataForSave.initialData);
    this.unsavedchangesContractId.splice(
      this.unsavedchangesContractId.indexOf(initialData.contractId),
      1
    );
    this.isUnsavedChanges = this.unsavedchangesContractId.length > 0;

    this.unsavedchangesContractId.length < 1 &&
      localStorage.removeItem("rtsUnsavedChanges");
  };

  @action showSuccess = async (value: boolean) => {
    this.isEmailNotificationSend = value;
    this.notificationSendVisibility = false;
    await this.saveContractwithDNx();
  };

  @action async saveContractwithDNx() {
    let initialData = JSON.parse(this.rowDataForSave.initialData);
    if (
      this.rowDataForSave.contractId !== 0 &&
      (this.rowDataForSave.doNotCallDownlineDefault !==
        initialData.doNotCallDownlineDefault ||
        this.rowDataForSave.doNotEmailDownlineDefault !==
          initialData.doNotEmailDownlineDefault ||
        this.rowDataForSave.doNotMarketDownlineDefault !==
          initialData.doNotMarketDownlineDefault)
    ) {
      this.setUpdateContractwithDNxDisplay(true);
    } else {
      await this.terminateDownlineContractAndSave();
    }
  }

  @action async updateContractwithDNx(val: boolean) {
    this.setUpdateContractwithDNxDisplay(false);
    this.isUpdateDownlineDNx = val;
    this.terminateDownlineContractAndSave();
  }

  @action terminateDownlineContractAndSave() {
    let initialData = JSON.parse(this.rowDataForSave.initialData);
    if (
      !this.terminateDownlineContract &&
      this.rowDataForSave.contractId != 0 &&
      (initialData.statusId == 1 || initialData.statusId == 3) &&
      parseInt(this.rowDataForSave.statusId) == 2 &&
      this.rowDataForSave.downlineContractCount != undefined &&
      this.rowDataForSave.downlineContractCount > 0
    ) {
      this.terminateDownlineContractDialog(true);
      return;
    }
    this.terminateDownlineContractDialog(false);
    this.saveRTSData(this.rowDataForSave);
  }

  @observable contractEditableFields: any = [
    "contractEndDate",
    "contractStartDate",
    "immediateUplineId",
    "statusId",
    "statusReasonId",
    "products",
    "categoryIds",
    "subCategoryIds",
    "productId",
    "marketerId",
    "payoutLevelId",
    "specialInstructions",
    "writingNumbers",
    "licenseNumberTypeId",
    "number",
    "isWritable",
  ];

  @action findChangeDetection = (o1: any, o2: any) => {
    for (var p in o2) {
      if (this.contractEditableFields.includes(p)) {
        if (p == "products" && o1.isDetailLoaded) {
          if (o1.products.length == o2.products.length) {
            let res = false;
            o2.products.forEach((element: any, index: number) => {
              let result = this.findChangeDetection(
                o1.products[index],
                element
              );
              if (result) {
                res = result;
                return;
              }
            });
            if (res) {
              return true;
            }
          } else return true;
        } else if (p == "writingNumbers" && o1.isDetailLoaded) {
          if (o1.writingNumbers.length == o2.writingNumbers.length) {
            let res = false;
            o2.writingNumbers.forEach((element: any, index: number) => {
              let result = this.findChangeDetection(
                o1.writingNumbers[index],
                element
              );
              if (result) {
                res = result;
                return;
              }
            });
            if (res) {
              return true;
            }
          } else return true;
        } else if (
          p == "categoryIds" ||
          p == "subCategoryIds" ||
          p == "productId"
        ) {
          let isChanged = false;
          if (p == "categoryIds")
            isChanged = this.isArrayChanged(o1.categoryIds, o2.categoryIds);

          if (p == "subCategoryIds")
            isChanged = this.isArrayChanged(
              o1.subCategoryIds,
              o2.subCategoryIds
            );
          if (p == "productId")
            isChanged = this.isArrayChanged(o1.productId, o2.productId);

          if (isChanged) return true;
        } else if (
          o2.hasOwnProperty(p) &&
          p != "products" &&
          p != "writingNumbers"
        ) {
          if (o1[p] + "" !== o2[p] + "") {
            return true;
          }
        }
      }
    }
    return false;
  };

  @action isArrayChanged(arrA: any, arrB: any) {
    //check if lengths are different
    if (!arrA || !arrB || arrA.length !== arrB.length) return true;

    return !(arrA.sort().toString() === arrB.sort().toString());
  }

  @action validateFields = (rowData: any) => {
    let isValid = false;
    let productList =
      (rowData.products &&
        rowData.products.filter((item: any) => {
          return (
            !(item.categoryIds && item.categoryIds.length > 0) ||
            !(item.subCategoryIds && item.subCategoryIds.length > 0) ||
            !item.productId ||
            !(item.productId && item.productId.length > 0) ||
            !item.marketerId ||
            !item.payoutLevelId ||
            item.payoutLevelId + "" == "0"
          );
        })) ||
      [];
    let licenseNumbers =
      (rowData.writingNumbers &&
        rowData.writingNumbers.filter((item: any) => {
          return !item.licenseNumberTypeId || !item.number || item.isDuplicate;
        })) ||
      [];

    if (
      rowData.statusId != "0" &&
      rowData.statusReasonId != "0" &&
      rowData.immediateUplineId &&
      rowData.immediateUplineId != "0" &&
      rowData.contractStartDate &&
      productList.length == 0 &&
      licenseNumbers.length == 0 &&
      !this.isValidationToasterVisible &&
      !(rowData.isEndDateRequired || rowData.isEndDateInvalid)
    )
      isValid = true;

    return isValid;
  };

  @action saveRTSData = async (rowData: any) => {
    this.productCountAfterSave =
      (rowData.products && rowData.products.length) || 0;
    let request: any = this.createModel(rowData);
    var result = await this.createUpdateContract(request, rowData);
  };

  @action updateDeleteRowData = async (result: any, rowData: any) => {
    if (!result.data) {
      this.actionData = this.actionData.map((obj: any) => {
        if (obj.agentId == rowData.agentId) {
          obj.contractData = obj.contractData.filter(
            (i) => i.contractId != rowData.contractId
          );
        }
        return obj;
      });
    } else {
      if (this.saveRowParams) {
        let isDetailLoaded = this.saveRowParams.node.data.isDetailLoaded;
        if (this.saveRowParams.node.data.isDetailLoaded) {
          this.saveRowParams.data.isDetailLoaded = false;
          this.saveRowParams.node.data.isDetailLoaded = false;
          this.saveRowParams.node.data.nodeExpanded =
            !this.saveRowParams.node.data.nodeExpanded;
          this.saveRowParams.node.setExpanded(false);
          this.saveRowParams.node.expanded = false;
        }
        let initialData = JSON.parse(this.saveRowParams.node.data.initialData);
        initialData.status = rowData.status;
        initialData.statusId = rowData.statusId;
        initialData.statusReason = rowData.statusReason;
        initialData.statusReasonId = rowData.statusReasonId;
        initialData.immediateUplineId = rowData.immediateUplineId;
        initialData.immediateUplineName = rowData.immediateUplineName;
        initialData.contractStartDate = rowData.contractStartDate;
        initialData.contractEndDate = rowData.contractEndDate;
        initialData.doNotCallDownlineDefault = rowData.doNotCallDownlineDefault;
        initialData.doNotEmailDownlineDefault =
          rowData.doNotEmailDownlineDefault;
        initialData.doNotMarketDownlineDefault =
          rowData.doNotMarketDownlineDefault;
        let awn = "",
          sap = "";
        if (rowData.writingNumbers && rowData.writingNumbers.length > 0) {
          rowData.writingNumbers.map((i: any) => {
            awn += sap + i.number + " (" + i.licenseNumberTypeName + ")";
            sap = ", ";
          });
        }
        if (isDetailLoaded) {
          initialData.awNs = awn;
          this.saveRowParams.data.awNs = initialData.awNs;
          this.saveRowParams.node.data.awNs = initialData.awNs;
          this.saveRowParams.node.data.productCount =
            this.productCountAfterSave;
          this.saveRowParams.data.productCount = this.productCountAfterSave;
        }
        this.saveRowParams.data.initialData = JSON.stringify(initialData);
        this.saveRowParams.node.data.initialData = JSON.stringify(initialData);
        //await this.onCellExpanded(this.saveRowParams, false);
        this.setIsChanged(this.saveRowParams.node.data, this.saveRowParams);
        this.saveRowParams.data.isChangeDetected =
          this.saveRowParams.node.data.isChangeDetected;
        this.saveRowParams.data.isValid = this.saveRowParams.node.data.isValid;

        //// redraw row
        let rows = [];
        rows.push(this.saveRowParams.node);
        this.gridContractRef.current!.api.refreshCells({
          force: true,
          rowNodes: rows,
        });
      }
    }
  };

  @action getProductsData = (products: any) => {
    let productDetailsList: ProductDetailsModel2[] = [];
    this.productCountAfterSave = 0;
    if (products && products.length > 0) {
      products.forEach((element: any, index: number) => {
        this.productCountAfterSave += element.productId.length;
        element.productId.forEach((pid: any) => {
          let selectedProduct: ProductDetailsModel2;
          let levelId = 0;
          if (element.productIds && element.productIds.length > 0) {
            let level = element.productIds.filter((l: any) => {
              return l.productId == pid;
            });
            levelId = level && level.length > 0 ? level[0].levelId : 0;
          }

          selectedProduct = {
            levelId: levelId,
            productId: pid,
            marketerId: element.marketerId,
            existingMarketerId: element.existingMarketerId,
            payoutLevelId: element.payoutLevelId,
            specialInstructions: element.specialInstructions,
            sortOrder: index + 1,
          } as ProductDetailsModel2;
          productDetailsList.push(selectedProduct);
        });
      });
    }
    return productDetailsList;
  };

  private createModel(element: any) {
    let reqData: any = {
      id: element.contractId,
      agentId: element.agentId,
      companyId: element.companyId,
      statusId: element.statusId,
      statusReasonId: element.statusReasonId,
      apptDate: element.contractStartDate
        ? new Date(element.contractStartDate)
        : undefined,
      termDate: element.contractEndDate
        ? new Date(element.contractEndDate)
        : undefined,
      reportsToLicenseId: element.immediateUplineId,
      writingNumbers: element.isDetailLoaded ? element.writingNumbers : [],
      doNotCall: element.doNotCall,
      doNotEmail: element.doNotEmail,
      doNotMarket: element.doNotMarket,
      doNotCallDownlineDefault: element.doNotCallDownlineDefault,
      doNotEmailDownlineDefault: element.doNotEmailDownlineDefault,
      doNotMarketDownlineDefault: element.doNotMarketDownlineDefault,
      isUpdateDownlineDNx: this.isUpdateDownlineDNx,
      agentStatusId: this.isAgentStatusUpdated
        ? +this.selectedAgentUpdateStatusId
        : 0,
      isDetailLoaded: element.isDetailLoaded ? true : false,
      productDetails: element.isDetailLoaded
        ? this.getProductsData(element.products)
        : [],
      isEmailNotificationSend: this.isEmailNotificationSend,
    };
    return reqData;
  }

  @action discardChanges = (rowData: any, rowParams: any) => {
    if (rowData.isChangeDetected) {
      let initialData = JSON.parse(rowData.initialData);
      rowParams.data.contractStartDate = initialData.contractStartDate;
      rowParams.data.contractEndDate = initialData.contractEndDate;
      rowParams.data.immediateUplineId = initialData.immediateUplineId;
      rowParams.data.immediateUplineName = initialData.immediateUplineName;
      rowParams.data.status = initialData.status;
      rowParams.data.statusId = initialData.statusId;
      rowParams.data.statusReason = initialData.statusReason;
      rowParams.data.statusReasonId = initialData.statusReasonId;
      rowParams.data.isChangeDetected = false;
      this.unsavedchangesContractId.splice(
        this.unsavedchangesContractId.indexOf(initialData.contractId),
        1
      );
      this.isUnsavedChanges = this.unsavedchangesContractId.length > 0;

      this.unsavedchangesContractId.length < 1 &&
        localStorage.removeItem("rtsUnsavedChanges");
      rowParams.data.isEndDateInvalid = false;
      rowParams.data.isEndDateRequired = false;
      rowParams.data.isValid = false;
      rowParams.data.isDetailLoaded = false;
      rowParams.data.nodeExpanded = false;
      rowParams.node.setExpanded(false);
      var rowNodes = [rowParams.node]; // params needs an array
      var params: any = {
        force: true,
        rowNodes: rowNodes,
      };
      return params;
    }
  };

  @action async createUpdateContract(body: any, rowData: any): Promise<any> {
    let url_ =
      Utils.getServiceUrlByEnvironment() +
      "/api/contract/v1/contracts/updatertscontracts";
    url_ = url_.replace(/[?&]$/, "");

    const content = JSON.stringify(body);
    const authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    let impersonated_User = UserContext.getUserId();
    let headers = {
      Impersonated_User:
        impersonated_User !== undefined && impersonated_User !== null
          ? "" + impersonated_User
          : "",
      "Content-Type": "application/json",
      Accept: "text/plain",
      HOSTNAME: host,
      authorization: authKey,
    };

    let promiseObj: any;
    promiseObj = axios.post(url_, content, { headers: headers });
    let status: boolean = false;
    await promiseObj
      .then(async (response: any) => {
        this.updateDeleteRowData(response, rowData);
        status = true;
        Utils.showSuccessToaster(
          "Changes saved for contract " +
            rowData.contractId +
            " for (NPN " +
            rowData.npn +
            " - " +
            rowData.agentName +
            ").",
          3000,
          500
        );
      })
      .catch((error: any) => {
        var data = error && error.response ? error.response.data : "";
        if (data && data.status === 400) {
          this.isValidationToasterVisible = true;
          toast.warning(`${data.title}`, {
            icon: null,
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: false,
            closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
            className: "validation-toaster contract",
            draggable: false,
            toastId: "validation-toaster",
          });
        } else {
          return this.throwException(
            "An unexpected server error occurred.",
            data.status,
            data.title,
            headers,
            null
          );
        }
        status = false;
      });
    return status;
  }

  @observable isValidationToasterVisible: boolean = false;
  @action hideValidationToaster = () => {
    this.isValidationToasterVisible = false;
    toast.dismiss("validation-toaster");
    //this.enableSave();
  };
  @action throwException = (
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ) => {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
  };

  @action onRefresh = (e: Event) => {
    if (this.isUnsavedChanges) {
      e.preventDefault();
    }
  };
  @action handleRouteOnUnsaved = () => {
    window.addEventListener("beforeunload", this.onRefresh);
    return () => {
      window.removeEventListener("beforeunload", this.onRefresh);
    };
  };
  @action loadInconsitancyByShowMore =() =>{
    this.scrollPosition = window.scrollY;
    PageContext.isLoading = true;
    this.showMoreCounter +=10; 
    this.showMoreSkipCounter += 1;
    this.searchRTS(true);
  }
}
