import { AbstractValidator } from "fluent-ts-validator";
import { action, computed, observable } from "mobx";
import { PageMode } from "../../infrastructure/enum/Common";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import IMASLog from "../../infrastructure/IMASLog";
import PageContext from "../../infrastructure/PageContext";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import ViewModel from "../../infrastructure/ViewModel";
import { Constants } from "../../infrastructure/enum/Constants";
import { Permission } from "../../infrastructure/enum/Permission";


export class BaseAddEditViewModel implements ViewModel {

    @observable readonly title: string = "";
    @observable readonly itemType: string = "";

    @observable pageTitle: string = "";
    @observable pageMode: PageMode = PageMode.Edit;
    @observable selectedId: number = 0;

    @observable isLoading: boolean = false;
    @observable hasException: boolean = false;
    @observable isMessgeVisible: boolean = false;
    @observable message: any = null;

    @observable ErrorModel: ErrorModel<any>;
    
    private listRoute: any;
    private isLookupsLoaded: boolean = false;

    constructor(itemType: string, listRoute: any, validator: AbstractValidator<any>)
    {
        this.itemType = itemType;
        this.listRoute = listRoute;
        this.ErrorModel = new ErrorModel(validator);
    }

    @computed
    get CanClose(): boolean {
      return true;
    }

    @computed
    get CanRoute(): boolean {
      return true;
    }

    @computed
    get IsLoaded(): boolean {
      var isloading = !this.isLoading
      throw isloading;
    }

    @computed
    get IsLoading(): boolean {
      throw this.isLoading;
    }

    // Mundane tasks
    @action showLoading() {
      this.isLoading = true;
    }
    @action hideLoading() {
      this.isLoading = false;
    }
    @action showMessage = (value: boolean, message: any = null) => {
      this.isMessgeVisible = value;
      if(value) {
        window.scrollTo(0,0);
      }
      if(message !== null) {
        this.message = message;
      }
    }
    @action hideMessage = () => {
      this.isMessgeVisible = false;
    }
    @action clearMessage = (alsoHide: boolean = false) => {
      this.message = {};
      if(alsoHide) {
        this.hideMessage();
      }
    }
    @action setMessage(message: any) {
      this.message = message;
    }

    @action
    setHasException = () => {
      this.hasException = true;
    }
    @action
    clearHasException = () => {
      this.hasException = false;
    }


    Route = async (_currentRoute: RouteList): Promise<void> => {};
    Close = (): void => {
      if (this.onClose)
        this.onClose();
    }
    
    @action 
    Load = async (id: any) => {
        if (id) {
            this.pageTitle = 'Edit ' +  this.itemType;
            this.pageMode = PageMode.Edit;
            this.selectedId = +id;
        }
        else {
            this.pageTitle = 'Add ' + this.itemType;
            this.pageMode = PageMode.Add;
            this.selectedId = 0;
        }
        
        try {
          this.showLoading();
          this.clearHasException();
          this.resetModel();
          await this.ResetValidation();
          if(!this.isLookupsLoaded) {
            await this.loadLookups();
            this.isLookupsLoaded = true;
          }

          if(this.pageMode !== PageMode.Add) {
            await this.loadItem(this.selectedId);
          }
          
          if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
            await this.checkPermissions();
          }

          this.hideLoading();
        }
        catch(error) {
          this.hideLoading()
          this.setHasException();
          IMASLog.log('Exception while loading.. ' + error)
        }
    };

    @action
    public Cancel = async () => {       
      this.listRoute.replace();
    }

    @action
    public goBack = () => {
      Utils.goBack();
    }
    

    @action
    public Save = async () => {
      await this.ResetValidation();
      if (!await this.checkIsModelValid()) {
        return false;
      }

      this.showLoading();
      this.clearMessage(true);
      try {
        
        await this.saveCurrentItem();
        this.hideLoading();
        setTimeout(async() => {
          if (PageContext.currentPage) {
              this.showMessage(false, "")
              return false;
          }
          if(PageContext.isCustomAttribute){
           // this.showMessage(true, Constants.CustomAttributeValidationMessage);
            return false;
          }  
          if(!PageContext.isMessageVisible){
            await this.goBack();
          }
        }, 300);
      } 
      catch (e) {
        this.hideLoading();
        this.showMessage(true, e.response);
      }
    }
  
    @action
    public ResetValidation = async () => {
        return await this.ErrorModel.ResetValidation(this);
    }
    @action checkIsModelValid = async () => {
      var hasErrors = await this.ErrorModel.Validate(this)
      return !hasErrors;
    }

    private async saveCurrentItem(): Promise<void> {
      if(this.pageMode === PageMode.Add) {
        try {
            await this.addItem();
            // await this.goBack();
        } catch(_e) {
            // Need to see how to resolve this
        }
      } 
      else {
        try {
            await this.updateItem();
            // await this.goBack();
        } catch (_e) {

        }
      }
    }


    // Protected that needs overriding by subclasses
    protected onClose?: () => void;

    // Convert current ViewModel to ServiceModel
    protected toServiceModel():any {}

    // Automic operations to be implemented by subclasses
    protected resetModel() {}
    protected async loadLookups(): Promise<void> {}
    protected async loadItem(id: number): Promise<void> {}
    protected async addItem(): Promise<void> {}
    protected async updateItem(): Promise<void> {}
    protected async checkPermissions(): Promise<void> {}
}