import { observable, action } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import Collection from "../../../../infrastructure/CollectionHelper";
import RouteList from "../../../../infrastructure/RouteList";
import IMASLog from "../../../../infrastructure/IMASLog";
import { routes } from "../../../../router";
import DocumentStore from "../../../../store/DocumentStore";
import Utils from "../../../../infrastructure/Utils";

export class DocumentViewModel implements ViewModel {

    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async (fromAddPacket: boolean | undefined) => {
        this.title = "Document Management System";
        this.fromAddPacket = fromAddPacket ? fromAddPacket : false;
        this.sortColumnOrder = "id";
        this.sortOrder = false;
        this.first = 0;
        this.startIndex = 0;
        this.rows = 200;
        await this.loadAllLookupList();
        if (!fromAddPacket) {
            await this.loadDocuments();
        }        
    };

    Route = async (currentRoute: RouteList): Promise<void> => { }
    @action backToAdmin = () => {
        routes.administration.push();
    }

    @observable rows: number = 200;
    @observable title: string = "";
    @observable totalRecords: number = 100;
    @observable first: number = 0;   
    @observable startIndex: number = 0;
    @observable sortOrder: boolean = false;
    @observable sortColumnOrder: string = "id";
    @observable isDeleteDocumentConfirm: boolean = false;
    @observable fromAddPacket: boolean = false;
    @observable selectedDocuments: any;

    @observable documentsList = new Collection<any>();
    @observable types = new Collection<any>();
    @observable categoryList = new Collection<any>();
    @observable companyList = new Collection<any>();         
    @observable showInactiveCarriers: boolean = false;
    @observable carriersInitial = new Collection<{
      label: string;
      value: string;
    }>();
    @observable productList = new Collection<any>();
    @observable selectedType: number = 0;
    @observable selectedCategory: number = 0;
    @observable selectedCompany: number = 0;
    @observable selectedProduct: number = -1;
    @observable documentTitle: string = '';
    @observable isException: boolean = false;
    @observable isLoading: boolean = false;
    @observable documentId: number = 0;
    @observable packetDocumentSortOrder: number = 0;
    @observable packetsSelectedDocs: any;
    @observable isAlertDocumentConfirm: boolean = false;
    @observable isArchivedDocumentsVisible: boolean = false;
    @observable archivedDocumentsList = new Collection<any>();
    @observable isMessgeVisible: boolean = false;
    @observable response: any;
    @observable archivedResponse: any;
    @observable isArchivedException: boolean = false;

    @action handleInactiveCarriers = async (e: any) => {
        this.showInactiveCarriers = e.checked;
        this.companyList.values = []; 
        this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
      };
      
    @action loadAllLookupList = async () => {
        //this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "All", value: "0" };
        var alldefaultItem = { label: "All", value: "-1" };
        try {
            var result = await DocumentStore.getAllDocumentLookup();
            if (result != null) {
                this.types.values = [];
                this.categoryList.values = [];
                this.companyList.values = [];
                this.productList.values = [];
                // Company
                if (result.carriers != null) {
                    result.carriers.forEach((obj: any) => {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.companyList.values.push(data);
                    });
                    
                }

                // category
                if (result.documentTypeGroups != null) {
                    result.documentTypeGroups.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.categoryList.values.push(documentData);
                    });
                }
                
                // type
                if (result.documentTypes != null) {
                    this.types.values = [];
                    result.documentTypes.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.types.values.push(documentData);
                    });
                }
                

                // Product
                this.productList.values.push(alldefaultItem);
                var noProductItem = { label: "No Specific Product", value: "0" };
                this.productList.values.push(noProductItem);
            }
        } catch (e) {
            this.companyList.values.splice(0, 0, defaultItem);
            this.types.values.splice(0, 0, defaultItem);
            this.categoryList.values.splice(0, 0, defaultItem);            
            this.productList.values.push(alldefaultItem);
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.companyList.values.splice(0, 0, defaultItem);
        this.carriersInitial.values = this.companyList.values;
        this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
        this.categoryList.values.splice(0, 0, defaultItem);
        this.types.values.splice(0, 0, defaultItem);
        this.isLoading = false;
    }

    @action loadDocuments = async () => {
        try {
            //this.isLoading = true;
            var documentResponse = await DocumentStore.searchAdminDocuments(this.selectedType, this.selectedCategory,
                this.selectedCompany, this.selectedProduct, this.documentTitle, this.startIndex, this.rows, this.sortColumnOrder, this.sortOrder);

            if (documentResponse && documentResponse.data) {
                this.totalRecords = documentResponse.recordCount
                    ? documentResponse.recordCount
                    : 0;
                this.documentsList.values = documentResponse.data;
            }
            this.isLoading = false;
        } catch (e) {
           
        }
    }
    async setSortColumn(sortField: any) {
        this.sortOrder = !this.sortOrder;
        this.sortColumnOrder = sortField;
        await this.loadDocuments();
    }
    @action addDocument() {
        routes.addDocument.push();
    }
    @action redirectDocumentPacket() {
        routes.packets.push();
    }
    @action editDocument(id: number) {
        routes.editDocument.push({ documentId: id });
    }
    @action updateDocument(id: number) {
        routes.updateDocument.push({ documentId: id });
    }
    @action cloneDocument(id: number) {
        routes.cloneDocument.push({ documentId: id });
    }

    @action async onPage(firstIndex: number, rows: number) {
        this.rows = rows;
        this.first = firstIndex;
        this.startIndex = firstIndex / this.rows;
        await this.loadDocuments();
    }
    @action setSelectedType = (typeId: number) => {
        this.selectedType = typeId;
    }
    @action setSelectedCategory(categoryId: number) {
        this.selectedCategory = categoryId;
    }
    @action async setSelectedCompany(companyId: number) {
        this.selectedCompany = companyId;
        //this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "All", value: "-1" };
        try {
            var result = await DocumentStore.getLookUpByCompanyId(companyId,0,0);
            if (result != null) {
                // Product
                if (result.products != null) {
                    this.productList.values = [];
                    result.products.forEach((obj: any) => {
                        var data = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.productList.values.push(data);
                    });
                    this.productList.values.splice(0, 0, defaultItem);
                    var noProductItem = { label: "No Specific Product", value: "0" };
                    this.productList.values.push(noProductItem);
                    this.selectedProduct = -1;
                }
                else {
                    this.productList.values = [];
                    this.productList.values.splice(0, 0, defaultItem);
                    this.selectedProduct = -1;
                }
            }
        } catch (e) {
            this.productList.values = [];
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = -1;
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.isLoading = false;
    }
    @action setSelectedProduct(productId: number) {
        this.selectedProduct = productId;
    }
    @action setSelectedTitle(title: string) {
        this.documentTitle = title;
    }
    @action setIsException(value: boolean) {
        this.isException = value;
    }
    @action setSelectedDocuments(value: any) {
        this.selectedDocuments = value;
    }
    @action setDocumentsSortOrder(value: any) {
        this.packetDocumentSortOrder = value;
    }

    @action setDeleteDocumentConfirmation = (value: boolean) => {
        this.isDeleteDocumentConfirm = value;
    }
    @action setAlertDocumentConfirm = (value: boolean) => {
        this.isAlertDocumentConfirm = value;
    }
    @action setArchivedDocumentsVisible = (value: boolean) => {
        this.isArchivedDocumentsVisible = value;
    }

    @action viewArchivedDocument = async (documentId: number) => {
        this.isArchivedDocumentsVisible = true;
        this.isArchivedException = false;
        this.archivedDocumentsList.values = [];
        try {
           // this.isLoading = true;
            var documentResponse = await DocumentStore.viewArchivedDocuments(documentId);

            if (documentResponse && documentResponse.data) {
                this.archivedDocumentsList.values = documentResponse.data;
            }
            this.isLoading = false;
        } catch (e) {
            let response = {
                status: 500,
                title: e.message,
                errors: { "": [] },
            };
            this.isArchivedException = true;
            this.archivedResponse = JSON.stringify(response);
            IMASLog.log("exception: " + e.message);
            this.isLoading = false;
        }
    }

    @action viewDocument = async (docId: number, fromArchived:boolean) => {
        this.isArchivedException = false;
        this.isMessgeVisible = false;
        this.archivedResponse = {};
        try {
            this.isMessgeVisible = false;
            window.open(window.location.origin + "/documentviewer/"+docId+"/"+0, '_new');
        } catch (e) {
            this.isArchivedException = true;
            var responseModels = {
                status: 400,
                title: "",
                errors: { "ValidationError": ["Document with Id "+ docId +" not found"] },
            };
            this.archivedResponse = JSON.stringify(responseModels);
            this.isArchivedException = fromArchived;
            this.isMessgeVisible = !fromArchived;
        }
    }
    @action setPacketsSelectedDocs = (value: any) => {
        this.packetsSelectedDocs = value;
        this.selectedDocuments = [];
    }
    @action setSelectedDocument = (value: number) => {
        this.documentId = value;
    }
    @action onDeleteDocumentClick = async () => {
        this.isDeleteDocumentConfirm = false;
        await DocumentStore.withdrawdocument(this.documentId);
        setTimeout(async () => {
            await this.loadDocuments();
        }, 500);
    }
    @action goSearch = async () => {
        this.startIndex = 0;
        this.first = 0;
        await this.loadDocuments()
        this.setIsException(false);
    }

    @action resetFilters = async () => {
        this.showInactiveCarriers = false;
       this.selectedType = 0;
       this.selectedCategory = 0;
       this.selectedCompany = 0;
       this.selectedProduct = -1;
       this.documentTitle = '';
       this.sortColumnOrder = "id";
       this.first = 0;
       this.startIndex = 0;
       this.rows = 200;
       this.setIsException(false);
       this.selectedDocuments = [];
       this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
       await this.loadDocuments();
    }
}