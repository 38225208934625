import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  PrincipalAgentRequest,
  Client as ProducerServiceClient,
} from "../services/ProducerService";
import {
  CreateUpdateAppointmentModel,
  CreateUpdateContractModel,
  CreateUpdateLevelRequest,
  LocationCreateRequest,
  CommunicationRequest,
  CarrierModel,
  CarrierGroupModel,
  Client as ContractServiceClient,
  CertificationModel,
  LicenseStatusReasonModel,
  CompanyLicenseNumberDefaultModel,
  BuildAllCommandRequest,
  FileType2,
  SaveSplittLicenseRequest,
  CreateUpdateCarrierModel,
  ColoringRuleModel,
  FileParameter,
  LicensePersistencyModel,
  LicenseStateModel,
  ContractInviteRequest,
  ContractingSentRequest,
  ContractingSentNoFUPIn30DaysRequest,
  DownlineAgentsRequest,
  ContractsByMarketerRequest,
  ContractsWithNoBusinessRequest,
  FaxConfirmationRequest,
  LicenseCountByCompanyRequest,
  OrdersByDivisionRequest,
  CarrierUpdatesRequest,
  ContractDetailsRequest,
  CreateUpdateCarrierModel2,
  CreateUpdateAddressModel,
  CreateUpdateNoteModel,
  DeleteRequest,
  ContractRequest,
  ContractGridColumnRequest,
  LookupsForInconsistencyContractRequest,
  InconsistencyAgentsRequest,
  InconsistencyContractsRequest,
  ContractRequestModel,
  // MoveIsLocationDataRequest,
} from "../services/ContractService";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import { Client as DocumentServiceClient } from "../services/DocumentService";
import Utils from "../infrastructure/Utils";

class ContractsStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): ContractServiceClient => {
    return ServiceClient.getClient(ServiceType.Contract);
  };
  getProducerClient = (): ProducerServiceClient => {
    return ServiceClient.getClient(ServiceType.Producer);
  };
  getDocumentClient = (): DocumentServiceClient => {
    return ServiceClient.getClient(ServiceType.Document);
  };

  getHostname = () => {
    return Utils.getServiceUrlByEnvironment();
  };
  @action getContractContactOptions = async (
    agentId: number,
    contractId: number
  ) => {
    // TODO:
    // var result = this.getClient().contactOptions(
    //   agentId,
    //   contractId,
    //   authorization
    // );
    var result = { doNotCall: false, doNotEmail: false, doNotMarket: false };
    return result;
  };

  @action getContracts = async (model: ContractRequestModel) => {
    var result = await this.getClient().getAllContract(
      this.getApiVersion(),      
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };
  @action downloadContracts = async (
    agentId: number,
    policyStatusId: number,
    searchParameter: string,
    marketerId: number | undefined,
    divisionId: number | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string | undefined,
    sortAscending: boolean | undefined
  ) => {
    var param: ContractRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortAscending: sortAscending ? true : false,
      agentId: agentId,
      policyStatusId: policyStatusId,
      searchParameter: searchParameter,
      marketerId: marketerId,
      divisionId: divisionId,
      isDownloadCall: true,
    };

    var result = await this.getClient().downloadContract(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      param
    );
    return result;
  };

  @action getContractAssocaitionsById = async (contractId: number) => {
    var result = await this.getClient().getContractAssocaitionsById(
      contractId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getProductDetailsLookup = async (
    companyId: number,
    agentId: number
  ) => {
    var result = await this.getClient().getProductDetailsLookup(
      companyId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getContractDetails = async (contractId: number, agentId: number) => {
    var result = await this.getClient().getContractById(
      contractId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getContractDetailsById = async (contractId: number) => {
    var result = await this.getClient().getContractDetailsById(
      contractId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getContractSummary = async (
    contractId: number,
    includeTerminatedDownlines: boolean
  ) => {
    var result = await this.getClient().getContractSummaries(
      contractId,
      includeTerminatedDownlines,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getContractSummaryDetails = async (
    agentId: number,

    pageIndex?: number,

    pageSize?: number,

    sortColumn?: string,

    sortAscending?: boolean,

    carrierId?: number | null | undefined,

    statusId?: number | null | undefined,

    fullTextFilter?: string | null | undefined
  ) => {
    let result = await this.getClient().getContractSummaryDetails(
      this.getApiVersion(),
      agentId,
      carrierId,
      statusId,
      0,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };

  @action getContractLocationLookUp = async (searchFor: string) => {
    var result = await this.getClient().getLocationLookup(
      this.getApiVersion(),
      searchFor,
      UserContext.getUserId()
    );
    return result;
  };

  

  

  @action getContractPrincipalAgentLookUp = async (
    agentId: number,
    id: number
  ) => {
    var result = await this.getProducerClient().getPrinicpalAgentLookup(
      agentId,
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };

  @action getCarriers = async (startIndex: number, pageSize: number) => {
    var result = await this.getClient().getAllCarrierss(
      this.getApiVersion(),
      false,
      undefined,
      startIndex,
      pageSize,
      "carrierName",
      true,
      undefined,
      UserContext.getUserId(),
      null
    );
    return result.data;
  };

  @action getCompanyLookupBasedOnAgent = async (agentId: number) => {
    var result = await this.getClient().getAgentPolicyScreenLookups(
      +agentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action getCompanyLookupBasedOnAgentAppointments = async (
    agentId: string
  ) => {
    var result = await this.getClient().getAppointmentCarriersByAgent(
      this.getApiVersion(),
      0,
      1000,
      undefined,
      undefined,
      agentId,
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllCompanyGroupLookup = async () => {
    var result = await this.getClient().getAllCarrierGroupLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action GetActiveInactiveAllCarrierLookup = async () => {
    var result = await this.getClient().getActiveInactiveAllCarrierLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getCompanyByCompanyGroupLookup = async (companyGroupId: number) => {
    var result = await this.getClient().getCarriersByCarrierGroupLookup(
      companyGroupId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllCompanyLookup = async () => {
    var result = await this.getClient().getAllCarrierLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  // methods for Add Contarct UI

  @action getAllLookupList = async (contractId: number, agentId: number) => {
    var result = await this.getClient().getContractById(
      contractId,
      agentId,
      this.getApiVersion()
    );
    return result;
  };
  @action getImmediateUpline = async (
    contractId: number,
    agentId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string,
    companyId: number,
    isContractStatus: boolean
  ) => {
    var result = await this.getClient().getImmediateUpline(
      contractId,
      agentId,
      this.getApiVersion(),
      companyId,
      isContractStatus,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    if (result && result.length > 0) {
      result.splice(0, 0, { agentId: -1 });
    }
    return result;
  };

  @action loadStatusReasonListByStatus = async (
    statusId: number,
    option: boolean
  ) => {
    var result = await this.getClient().getStatusReason(
      this.getApiVersion(),
      statusId,
      option
    );
    return result;
  };
  @action addContract = async (model: CreateUpdateContractModel) => {
    var result = await this.getClient().createContract(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };

  @action EditContract = async (model: CreateUpdateContractModel) => {
    var result = await this.getClient().updateContract(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };

  @action deleteContract = async (contractId: number) => {
    var result = await this.getClient().deleteContract(
      contractId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getContractLevels = async (contractId: number) => {
    var result = await this.getClient().getAllLevel(
      contractId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getContractPrincipals = async (
    contractId: number,
    index: number,
    size: number,
    column: string,
    isSortAsecending: boolean,
    searchFilter: string
  ) => {
    var result =
      await this.getProducerClient().getAllPrincipalAgentsByContractId(
        contractId,
        this.getApiVersion(),
        index,
        size,
        column,
        isSortAsecending,
        searchFilter,
        UserContext.getUserId()
      );
    return result;
  };

  @action getContractDocuments = async (
    contractId: number,
    index: number,
    size: number,
    column: string,
    isSortAsecending: boolean,
    searchFilter: string
  ) => {
    var result = await this.getDocumentClient().getContractDocuments(
      contractId,
      this.getApiVersion(),
      index,
      size,
      column,
      isSortAsecending,
      searchFilter,
      UserContext.getUserId()
    );
    return result;
  };

  @action loadContractAppointments = async (
    agentId: number,
    companyId: number | undefined,
    index: number,
    size: number,
    column: string,
    isSortAsecending: boolean,
    searchFilter: string
  ) => {
    var result = await this.getClient().getAllAppointments(
      this.getApiVersion(),
      agentId,
      companyId,
      index,
      size,
      column,
      isSortAsecending,
      searchFilter,
      UserContext.getUserId()
    );
    return result;
  };

  @action getContractCommunications = async (
    contractId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAsecending: boolean
  ) => {
    var result = await this.getClient().getAllCommunication(
      contractId,
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAsecending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getContractLocations = async (
    contractId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAsecending: boolean
  ) => {
    var result = await this.getClient().getAllLocation(
      contractId,
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAsecending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getLevelDetails = async (levelId: number) => {
    var result = await this.getClient().getLevelById(
      levelId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteLevel = async (levelId: number) => {
    var result = await this.getClient().deleteLevel(
      levelId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteLocation = async (locationId: number) => {
    var result = await this.getClient().deleteLocation(
      locationId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deletePrincipalAgent = async (principalAgentId: number) => {
    var result = await this.getProducerClient().deletePrincipleAgent(
      principalAgentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getMarketersLokup = async () => {
    var result = await this.getClient().getMarketersLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllMarketersLookup = async () => {
    var result = await this.getClient().getAllMarketers(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getLevelsLookup = async (
    companyId: number | undefined,
    payoutLevelId: number | undefined,
    productId: number | undefined,
    licenseId: number | undefined
  ) => {
    var result = await this.getClient().getPayoutLevelLookup(
      this.getApiVersion(),
      companyId,
      payoutLevelId,
      productId,
      licenseId,
      UserContext.getUserId()
    );
    return result;
  };

  @action addLevel = async (dto: CreateUpdateLevelRequest) => {
    var result = await this.getClient().createLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editLevel = async (dto: CreateUpdateLevelRequest) => {
    var result = await this.getClient().updateLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action deleteCommunication = async (commuId: number, contractId: number) => {
    var result = await this.getClient().deleteCommunication(
      this.getApiVersion(),
      contractId,
      commuId,
      false,
      UserContext.userId
    );
    return result;
  };
  @action addLocation = async (model: LocationCreateRequest) => {
    var result = await this.getClient().createLocation(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };

  @action addPrincipalAgent = async (model: PrincipalAgentRequest) => {
    var result = await this.getProducerClient().createPrincipleAgent(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      model
    );
    return result;
  };

  @action getContractDetailsReport = async (
    agentId?: number | undefined,
    companyGroupId?: number | undefined,
    companyId?: number | undefined,
    licenseStatusId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    subCategories?: number[] | undefined,
    products?: number[] | undefined,
    marketerIds?: number[] | undefined,
    licenseStates?: number[] | undefined,
    uplineId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (subCategories && subCategories.length > 0) {
      subCategories = subCategories.map((i) => Number(i));
    }
    if (products && products.length > 0) {
      products = products.map((i) => Number(i));
    }
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    var request: ContractDetailsRequest = {
      agentId: agentId,
      companyGroupId: companyGroupId,
      companyId: companyId,
      licenseStatusId: licenseStatusId,
      startDate: startDate,
      stopDate: stopDate,
      subCategories: subCategories ? subCategories : [],
      products: products ? products : [],
      marketerIds: marketerIds ? marketerIds : [],
      licenseStates: licenseStates ? licenseStates : [],
      uplineId: uplineId,
      fileType: fileType,
    };
    var result = await this.getClient().getContractDetailsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getAppointmentDetails = async (
    appointmentId: number,
    agentId: number
  ) => {
    var result = await this.getClient().getAppointmentById(
      appointmentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action deleteAppointment = async (appointmentId: number) => {
    var result = await this.getClient().deleteAppointment(
      appointmentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addAppointment = async (dto: CreateUpdateAppointmentModel) => {
    var result = await this.getClient().createAppointment(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editAppointment = async (dto: CreateUpdateAppointmentModel) => {
    var result = await this.getClient().updateAppointment(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action appointmentStates = async () => {
    var result = await this.getClient().getStates(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action appointmentCompanies = async (contractId: number) => {
    var result = await this.getClient().getCompaniesByContractId(
      contractId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action reportLicenseStatus = async () => {
    var result = await this.getClient().getReportContractStatusLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action reportAgentHomeState = async () => {
    var result = await this.getClient().getStates(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action buildAll = async (levelId: number, subCategoryId: number) => {
    var result = await this.getClient().createBuildAll(
      levelId,
      subCategoryId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action cloneProductDetails = async (
    uplicenseId: number | undefined,
    downLicenseId: number | undefined,
    payoutLevelId: number | undefined
  ) => {
    var result = await this.getClient().cloneProductDetails(
      this.getApiVersion(),
      uplicenseId,
      downLicenseId,
      payoutLevelId,
      UserContext.userId
    );
    return result;
  };
  @action getLevelNotes = async (contractId: number) => {
    var result = await this.getClient().getLevelNote(
      contractId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  // Reports
  @action contractInviteReport = async (
    agentId?: number | undefined,
    uplineAgentId?: number | undefined,
    marketerIds?: number[] | undefined,
    companyId?: number | undefined,
    startDate?: Date,
    endDate?: Date,
    fileType?: FileType2 | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: ContractInviteRequest = {
      agentId: agentId,
      uplineAgentId: uplineAgentId,
      marketerIds: marketerIds ? marketerIds : [],
      companyId: companyId,
      startDate: startDate,
      stopDate: endDate,
      fileType: fileType,
    };
    var result = await this.getClient().getContractInviteReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action contractingSentReport = async (
    companyId?: number | undefined,
    startDate?: Date,
    endDate?: Date,
    teamId?: number | undefined,
    marketerIds?: number[] | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: ContractingSentRequest = {
      companyId: companyId,
      startDate: startDate,
      stopDate: endDate,
      teamId: teamId,
      marketerIds: marketerIds ? marketerIds : [],
      fileType: fileType,
    };
    var result = await this.getClient().getContractingSentReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action contractingSentNoFUPIn30DaysReport = async (
    startDate?: Date,
    endDate?: Date,
    teamId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    var request: ContractingSentNoFUPIn30DaysRequest = {
      startDate: startDate,
      stopDate: endDate,
      teamId: teamId,
      fileType: fileType,
    };
    var result = await this.getClient().getContractingSentNoFUPIn30DaysReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action contractsByMarketerReport = async (
    companyId?: number | undefined,
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    licenseStates?: number[] | undefined,
    homeStates?: string[] | undefined,
    marketerIds?: number[] | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: ContractsByMarketerRequest = {
      companyId: companyId,
      startDate: startDate,
      stopDate: stopDate,
      licenseStates: licenseStates ? licenseStates : [],
      homeStates: homeStates ? homeStates : [],
      marketerIds: marketerIds ? marketerIds : [],
      fileType: fileType,
    };
    var result = await this.getClient().getContractsByMarketerReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action contractsWithNoBusinessReport = async (
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    companyId?: number | undefined,
    licenseStates?: number[] | undefined,
    marketerIds?: number[] | undefined,
    uplineAgentId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: ContractsWithNoBusinessRequest = {
      startDate: startDate,
      stopDate: stopDate,
      companyId: companyId,
      licenseStates: licenseStates ? licenseStates : [],
      marketerIds: marketerIds ? marketerIds : [],
      uplineId: uplineAgentId,
      fileType: fileType,
    };
    var result = await this.getClient().getContractsWithNoBusinessReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action downlineAgentsReport = async (
    uplineId?: number | undefined,
    companyId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    var request: DownlineAgentsRequest = {
      uplineId: uplineId,
      companyId: companyId,
      fileType: fileType,
    };
    var result = await this.getClient().getDownlineAgentsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action carrierUpdatesReport = async (
    companyId?: number | undefined,
    licenseStates?: number[] | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (licenseStates && licenseStates.length > 0) {
      licenseStates = licenseStates.map((i) => Number(i));
    }
    var request: CarrierUpdatesRequest = {
      companyId: companyId,
      licenseStates: licenseStates ? licenseStates : [],
      fileType: fileType,
    };
    var result = await this.getClient().getCarrierUpdatesReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action faxConfirmationReport = async (
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    companyId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    var request: FaxConfirmationRequest = {
      startDate: startDate,
      stopDate: stopDate,
      companyId: companyId,
      fileType: fileType,
    };
    var result = await this.getClient().getFaxConfirmationReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action licenseCountByCompanyReport = async (
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    marketerIds?: number[] | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (marketerIds && marketerIds.length > 0) {
      marketerIds = marketerIds.map((i) => Number(i));
    }
    var request: LicenseCountByCompanyRequest = {
      startDate: startDate,
      stopDate: stopDate,
      marketerIds: marketerIds ? marketerIds : [],
      fileType: fileType,
    };
    var result = await this.getClient().getLicenseCountByCompanyReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
  @action ordersByDivisionReport = async (
    startDate?: Date | undefined,
    stopDate?: Date | undefined,
    divisionId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    var request: OrdersByDivisionRequest = {
      startDate: startDate,
      stopDate: stopDate,
      divisionId: divisionId,
      fileType: fileType,
    };
    var result = await this.getClient().getOrdersByDivisionReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action lookupCommunication = async (contractId: number) => {
    var result = await this.getClient().getCommunicationLookup(
      contractId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action addCommunication = async (dto: CommunicationRequest) => {
    var result = await this.getClient().createCommunication(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      dto
    );
    return result;
  };
  @action replaceContract = async (
    contractId: number,
    downlineEffected: boolean
  ) => {
    var result = await this.getClient().replaceContract(
      this.getApiVersion(),
      contractId,
      downlineEffected,
      UserContext.userId
    );
    return result;
  };

  // Admin->Carrier
  @action getAllCarriers = async (
    IsHidden: boolean | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "carrierName",
    isSortAscending: boolean = true,
    searchFilter: string
  ) => {
    var result = await this.getClient().getAllCarriers(
      this.getApiVersion(),
      IsHidden,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      searchFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action
  getCarrierById = async (id: number): Promise<CarrierModel> => {
    var result = await this.getClient().getCarrierById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  updateCarrier = async (item: CreateUpdateCarrierModel) => {
    var result = await this.getClient().updateCarrier(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
    return result;
  };

  @action
  addCarrier = async (item: CreateUpdateCarrierModel) => {
    var result = await this.getClient().addCarrier(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
    return result;
  };

  @action
  deleteCarrier = async (id: number) => {
    await this.getClient().deleteCarrier(
      this.getApiVersion(),
      "" + id,
      UserContext.getUserId(),
      id
    );
  };

  @action
  getAllCarriersAsExcel = async () => {
    var result = await this.getClient().getCarriersAsExcel(
      this.getApiVersion(),
      0,
      100000,
      "CarrierName",
      true,
      undefined,
      UserContext.getUserId(),
      null
    );
    return result;
  };

  @action
  getCarrierssAsExcel = async (
    status: boolean | null,
    carrierGroupIds: any,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean = true,
    searchText: string | undefined
  ) => {
    var result = await this.getClient().getCarrierssAsExcel(
      this.getApiVersion(),
      status,
      carrierGroupIds,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      searchText,
      UserContext.getUserId(),
      null
    );
    return result;
  };

  // Admin->LicenseStatusReason
  @action getAllLicenseStatusReasons = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "StatusReason",
    isSortAscending: boolean = true
  ) => {
    var result = await this.getClient().getAllLicenseStatusReasons(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action
  getLicenseStatusReasonById = async (
    id: number
  ): Promise<LicenseStatusReasonModel> => {
    var result = await this.getClient().getLicenseStatusReasonById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  updateLicenseStatusReason = async (item: LicenseStatusReasonModel) => {
    await this.getClient().updateLicenseStatusReason(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addLicenseStatusReason = async (item: LicenseStatusReasonModel) => {
    await this.getClient().addLicenseStatusReason(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deleteLicenseStatusReason = async (id: number) => {
    await this.getClient().deleteLicenseStatusReason(
      this.getApiVersion(),
      "" + id,
      UserContext.getUserId(),
      undefined,
      id
    );
  };

  // Admin->CarrierGroup
  @action getAllCarrierGroups = async (
    isHidden: boolean | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "GroupName",
    isSortAscending: boolean = true,
    searchFilter: string
  ) => {
    var result = await this.getClient().getAllCarrierGroups(
      this.getApiVersion(),
      isHidden,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      searchFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getLicensePersistencyTypeColoringRule = (
    id: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string,
    isSortAscending: boolean
  ) => {
    var result = this.getClient().getLicensePersistencyTypeColoringRule(
      this.getApiVersion(),
      id,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action
  getCarrierGroupById = async (id: number): Promise<CarrierGroupModel> => {
    var result = await this.getClient().getCarrierGroupById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  getLicensePersistencyTypeColoringRuleById = async (
    id: number
  ): Promise<ColoringRuleModel> => {
    var result =
      await this.getClient().getLicensePersistencyTypeColoringRuleById(
        id,
        this.getApiVersion(),
        UserContext.getUserId()
      );
    return result;
  };

  @action
  updateCarrierGroup = async (item: CarrierGroupModel) => {
    await this.getClient().updateCarrierGroup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addCarrierGroup = async (item: CarrierGroupModel) => {
    await this.getClient().addCarrierGroup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deleteCarrierGroup = async (id: number) => {
    await this.getClient().deleteCarrierGroup(
      +id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getAllCarrierGroupLookup = async () => {
    var result = await this.getClient().getAllCarrierGroupLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getCarrierDivisionLookup = async () => {
    var result = await this.getClient().getCarrierDivisionLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllLicenseNumbersLookup = async () => {
    var result = await this.getClient().getAllLicenseNumbersLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  // Email and Fax Details CRUD
  @action
  getEmailAndFaxDetailsList = async (
    companyId: number,
    docTypeId: number,
    productId: number,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "Id",
    isSortAscending: boolean = true
  ) => {
    var result = null;
    // var result = await this.getClient().getAllEmailAndFaxDetails(
    //   this.getApiVersion(),
    //   companyId,
    //   docTypeId,
    //   productId,
    //   startIndex,
    //   pageSize,
    //   sortColumn,
    //   isSortAscending,
    //   null,
    //   UserContext.getUserId()
    // );
    return result;
  };

  @action
  getEmailAndFaxDetailsById = async (id: number): Promise<any> => {
    // var result = await this.getClient().getEmailAndFaxDetailsById(
    //   id,
    //   this.getApiVersion(),
    //   UserContext.getUserId()
    // );
    var result = null;
    return result;
  };

  @action
  updateEmailAndFaxDetails = async (item: any) => {
    // await this.getClient().updateEmailAndFaxDetails(
    //   this.getApiVersion(),
    //   UserContext.getUserId(),
    //   item
    // );
  };

  @action
  addEmailAndFaxDetails = async (item: any) => {};

  @action
  deleteEmailAndFaxDetails = async (id: number) => {};

  @action
  upload = async (
    productYear: number,
    importName: string,
    importId: number,
    file: any
  ) => {
    var result = await this.getClient().upload(
      this.getApiVersion(),
      UserContext.getUserId(),
      this.getHostname(),
      productYear,
      importName,
      importId,
      file
    );
    return result;
  };
  @action
  getLastBulkMailShippingNumber = async () => {
    var result = await this.getClient().getLastBulkMailShippingNumber(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action
  updateBulkMailShippingNumber = async () => {
    var result = await this.getClient().updateBulkMailShippingNumber(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action certificationsDropDownValues = async () => {
    let result = await this.getClient().certificationsDropDownValues(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllCertifications = async (
    year?: number | undefined,
    companyId?: number | undefined,
    agentId?: number | undefined,
    serachText?: string | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined
  ) => {
    let result = await this.getClient().getAllCertifications(
      this.getApiVersion(),
      year,
      companyId,
      agentId,
      serachText,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.userId
    );
    return result;
  };
  @action addCertification = async (body: CertificationModel) => {
    let result = await this.getClient().addCertification(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };

  @action updateCertification = async (body: CertificationModel) => {
    let result = await this.getClient().updateCertification(
      this.getApiVersion(),
      UserContext.userId,
      undefined,
      body
    );
    return result;
  };
  @action getCertificationById = async (id: number) => {
    let result = await this.getClient().getCertificationById(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action deleteCertification = async (id: number) => {
    let result = await this.getClient().deleteCertification(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  // Status Reason CRUD
  @action
  getLicenseStatusReasonList = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "reason",
    isSortAscending: boolean = true
  ) => {
    var result = await this.getClient().getAllLicenseStatusReasons(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  /*
      @action
      getLicenseStatusReasonById = async(id: number): Promise<LicenseStatusReasonModel> => {
        var result = await this.getClient()
                               .getLicenseStatusReasonById(
                                 id,
                                 this.getApiVersion(),
                                 UserContext.getUserId()
                              );
        return result;
      }
  
      @action
      updateLicenseStatusReason = async(item: LicenseStatusReasonModel) => {
        await this.getClient()
                  .updateLicenseStatusReason(
                    this.getApiVersion(),
                    UserContext.getUserId(),
                    item
                  );
      }
  
      @action
      addLicenseStatusReason = async(item: LicenseStatusReasonModel) => {
        await this.getClient()
                  .addLicenseStatusReason(
                    this.getApiVersion(),
                    UserContext.getUserId(),
                    item
                  );
      }
  
      @action
      deleteLicenseStatusReason = async(id: number) => {
        await this.getClient()
                  .deleteLicenseStatusReason(
                    this.getApiVersion(),
                    ''+id,
                    UserContext.getUserId(),
                    id
                  );
      }
      */

  /* */
  @action
  getCompanyLicenseNumberDefaultById = async (ID: number) => {
    return this.getClient().getCompanyLicenseNumberDefaultById(
      ID,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action
  getAllCompanyLicenseNumberDefaults = async (
    companyId?: number,
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | undefined
  ) => {
    var result = await this.getClient().getAllCompanyLicenseNumberDefaults(
      this.getApiVersion(),
      companyId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );

    return result;
  };

  @action
  getAllLicensePersistencyTypes = async (
    companyId?: number,
    startIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined,
    fullTextFilter?: string | undefined
  ) => {
    var result = await this.getClient().getAllLicensePersistencyTypes(
      this.getApiVersion(),
      companyId,
      startIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );

    return result;
  };

  @action
  addCompanyLicenseNumberDefaults = async (
    dto: CompanyLicenseNumberDefaultModel
  ) => {
    var result = this.getClient().createCompanyLicenseNumberDefault(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );

    return result;
  };

  @action
  updateCompanyLicenseNumberDefaults = async (
    dto: CompanyLicenseNumberDefaultModel
  ) => {
    var result = this.getClient().updateCompanyLicenseNumberDefault(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action
  public deleteCompanyLicenseNumberDefaults = async (id: number) => {
    var result = this.getClient().deleteCompanyLicenseNumberDefault(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  public deleteLicensePersistencyTypeColoringRule = async (id: number) => {
    var result = this.getClient().deleteLicensePersistencyTypeColoringRule(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  public isLicenseStatusReasonExists = async (
    id: number,
    reason: string,
    statusId: number
  ) => {
    var result = this.getClient().isLicenseStatusReasonExists(
      this.getApiVersion(),
      id,
      reason,
      statusId,
      UserContext.getUserId()
    );
    return result;
  };

  @action buildAllLevel = async (body: BuildAllCommandRequest) => {
    let result = this.getClient().buildAllLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

  @action moveLicense = async (request: SaveSplittLicenseRequest) => {
    var result = await this.getClient().saveSplittLicense(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  // @action moveLocation = async (
  //   request: MoveIsLocationDataRequest
  // ) => {
  // var result = await this.getClient().moveIsLocationData(
  //   this.getApiVersion(),
  //   UserContext.getUserId(),
  //   undefined,
  //   request
  // );
  // let result = null
  // return result;
  // };

  @action cloneAgent = async (id: number) => {
    // var result = await this.getClient().cloneAgent(
    //   this.getApiVersion(), id,
    //   UserContext.getUserId(),
    //   undefined
    // );
    let result = null;
    return result;
  };

  @action downloadFile = async (filename: string) => {
    let result = await this.getClient().downloadFile(
      this.getApiVersion(),
      filename,
      UserContext.getUserId()
    );
    return result;
  };

  @action createCompanyLicenseNumberDefault = (
    dto: CompanyLicenseNumberDefaultModel
  ) => {
    let result = this.getClient().createCompanyLicenseNumberDefault(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updateCompanyLicenseNumberDefault = (
    dto: CompanyLicenseNumberDefaultModel
  ) => {
    let result = this.getClient().updateCompanyLicenseNumberDefault(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action createLicensePersistencyTypeColoringRule = (
    dto: ColoringRuleModel
  ) => {
    let result = this.getClient().createLicensePersistencyTypeColoringRule(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updateLicensePersistencyTypeColoringRule = (
    dto: ColoringRuleModel
  ) => {
    let result = this.getClient().updateLicensePersistencyTypeColoringRule(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getAllImportPersistencyLookup = async () => {
    var result = await this.getClient().getAllImportPersistencyLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action getAllLicensePersistency = async (
    licenseId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    var result = await this.getClient().getAllLicensePersistency(
      licenseId,
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getLicensePersistencyById = async (Id: number) => {
    var result = await this.getClient().getLicensePersistencyById(
      Id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAllLicensePersistencyLookup = async (Id: number) => {
    var result = await this.getClient().getAllLicensePersistencyLookup(
      Id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action createLicensePersistency = async (dto: LicensePersistencyModel) => {
    var result = await this.getClient().createLicensePersistency(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updateLicensePersistency = async (dto: LicensePersistencyModel) => {
    var result = await this.getClient().updateLicensePersistency(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action deleteLicensePersistency = async (id: number) => {
    var result = await this.getClient().deleteLicensePersistency(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action importPersistencyTypes = async (
    licensePersistencyId: number,
    file: FileParameter | undefined
  ) => {
    var result = await this.getClient().importPersistencyTypes(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      licensePersistencyId,
      file
    );
    return result;
  };

  @action getLicensePersistencyHistory = async (id: number) => {
    var result = await this.getClient().getLicensePersistencyHistory(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action
  getAllCompanyLicenseNumberDefaultsLookup = async (ID: number) => {
    return this.getClient().getAllCompanyLicenseNumberDefaultsLookup(
      ID,
      this.getApiVersion(),
      UserContext.getUserId()
    );
  };

  @action getCarrierGroupByCompanyId = async (companyGroupId: number) => {
    let result = this.getClient().getCarrierGroupByCompanyId(
      companyGroupId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteLlicenseState = async (id: number) => {
    var result = await this.getClient().deleteLicenseState(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getLicenseStateById = async (id: number) => {
    let result = this.getClient().getLicenseStateById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addLicenseState = async (dto: LicenseStateModel) => {
    var result = await this.getClient().addLicenseState(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editLicenseState = async (dto: LicenseStateModel) => {
    var result = await this.getClient().updateLicenseState(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getAllLicenseStates = async (
    licenseId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    var result = await this.getClient().getAllLicenseStates(
      this.getApiVersion(),
      licenseId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAllCertificationsByAgent = async (
    year: number,
    companyId: number,
    agentId: number,
    searchText: string,
    pageIndex: number,
    PageSize: number,
    sortColumn: string,
    sortAscending: boolean
  ) => {
    let result = await this.getClient().getAllCertificationsByAgent(
      this.getApiVersion(),
      year,
      companyId,
      agentId,
      searchText,
      pageIndex,
      PageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getImportForValues = async () => {
    var result = await this.getClient().getImportForValues(
      this.getApiVersion(),
      UserContext.getUserId(),
      null
    );
    return result;
  };

  //   @action getAdvancedMonths = async () => {
  //     var result = await this.getClient().getAdvancedMonths(
  //       this.getApiVersion(),
  //       UserContext.getUserId()
  //     );
  //     return result;
  //   };

  @action downloadContractSummaryReport = async (
    id: number,

    fileType: number,

    carrierId?: number | null | undefined,

    statusId?: number | null | undefined,

    fullTextFilter?: string | null | undefined
  ) => {
    var result = await this.getClient().downloadContractSummary(
      this.getApiVersion(),
      id,
      carrierId,
      statusId,
      fileType,
      undefined,
      undefined,
      undefined,
      undefined,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllResidencyStatus = async () => {
    var result = await this.getClient().getAllResidencyStatus(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllStateLicenseStatus = async () => {
    var result = await this.getClient().getAllStateLicenseStatus(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllCarrierss = async (
    IsHidden: boolean | null,
    startIndex: number,
    carrierGroupIds: any,
    pageSize: number,
    sortColumn: string,
    isSortAscending: boolean = true,
    searchFilter: string
  ) => {
    var result = await this.getClient().getAllCarrierss(
      this.getApiVersion(),
      IsHidden,
      carrierGroupIds,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      searchFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action getAllCarriersGroups = async (
    isHidden: boolean | null | undefined,
    startIndex: number,
    pageSize: number,
    sortColumn: string = "GroupName",
    isSortAscending: boolean = true,
    SearchText: string
  ) => {
    var result = await this.getClient().getAllCarriersGroups(
      this.getApiVersion(),
      isHidden,
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      SearchText,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action
  addCarriersGroup = async (item: CarrierGroupModel) => {
    await this.getClient().addCarriersGroup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };
  @action getCarriersGroupById = async (
    id: number
  ): Promise<CarrierGroupModel> => {
    var result = await this.getClient().getCarriersGroupById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action
  updateCarriersGroup = async (item: CarrierGroupModel) => {
    await this.getClient().updateCarriersGroup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };
  @action getCarrierGroupsAsExcel = async (
    isHidden: boolean | null | undefined,
    SearchText: string
  ) => {
    var result = await this.getClient().getCarrierGroupsAsExcel(
      this.getApiVersion(),
      SearchText,
      isHidden,
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllAddresses = async (carrierId: number) => {
    var result = await this.getClient().getAllCarrierAddresses(
      carrierId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAddressTypeLookupForCarrier = async () => {
    var result = await this.getClient().getAddressTypeLookupForCarrier(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action addAddress = async (
    addressDto: CreateUpdateAddressModel,
    carrierId: string
  ) => {
    var result = await this.getClient().createCarrierAddress(
      this.getApiVersion(),
      carrierId,
      UserContext.getUserId(),
      undefined,
      addressDto
    );
    return result;
  };
  @action editAddress = async (
    addressDto: CreateUpdateAddressModel,
    carrierId: string
  ) => {
    var result = await this.getClient().updateCarrierAddress(
      this.getApiVersion(),
      carrierId,
      UserContext.getUserId(),
      undefined,
      addressDto
    );
    return result;
  };
  @action getAddressDetails = async (addressId: number, carrierId: string) => {
    var result = await this.getClient().getCarrierAddressById(
      addressId,
      this.getApiVersion(),
      carrierId,
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteAddress = async (addressId: number, carrierId: string) => {
    var result = await this.getClient().deleteCarrierAddress(
      addressId,
      this.getApiVersion(),
      carrierId,
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllContact = async (companyId: number) => {
    var result = await this.getClient().getAllCarrierContact(
      this.getApiVersion(),
      companyId,
      UserContext.userId
    );
    return result;
  };
  @action getContactLookups = async () => {
    var result = await this.getClient().getContactLookups(
      this.getApiVersion(),
      UserContext.userId,
      undefined
    );
    return result;
  };
  @action addContact = async (contactDto: any) => {
    var result = await this.getClient().createCarrierContact(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action editContact = async (contactDto: any) => {
    var result = await this.getClient().updateCarrierContact(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      contactDto
    );
    return result;
  };
  @action getContactDetails = async (contactId: number) => {
    var result = await this.getClient().getCarrierContactById(
      contactId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteContact = async (contactId: number) => {
    var result = await this.getClient().deleteCarrierContact(
      contactId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllNotes = async (
    companyId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string
  ) => {
    var result = await this.getClient().getAllCarrierNotes(
      this.getApiVersion(),
      companyId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };
  @action addNote = async (body: CreateUpdateNoteModel) => {
    var result = await this.getClient().addCarrierNote(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;
  };
  @action updateNote = async (body: CreateUpdateNoteModel) => {
    var result = await this.getClient().updateCarrierNote(
      this.getApiVersion(),
      UserContext.getUserId(),
      UserContext.getUserId(),
      body
    );
    return result;
  };
  @action deleteNote = async (id: number) => {
    var result = await this.getClient().deleteCarrierNote(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getCarriersById = async (id: number) => {
    var result = await this.getClient().getCarriersById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addCarriers = async (payLoad: CreateUpdateCarrierModel2) => {
    var result = await this.getClient().addCarriers(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      payLoad
    );
    return result;
  };
  @action updateCarriers = async (payLoad: CreateUpdateCarrierModel2) => {
    var result = await this.getClient().updateCarriers(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      payLoad
    );
    return result;
  };

  @action getAllMasterCarrierLookup = async () => {
    var result = await this.getClient().getAllMasterCarrierLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getMasterCarrierById = async (masterCarrierId: string) => {
    var result = await this.getClient().getMasterCarrierById(
      masterCarrierId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAllMasterCarrierGroupLookup = async () => {
    var result = await this.getClient().getAllMasterCarrierGroupLookup(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action deleteAllSelectedProductsByLevels = async (
    requestProductModel: DeleteRequest
  ) => {
    var result = await this.getClient().deleteAllSelectedProductsByLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      requestProductModel || undefined
    );
    return result;
  };
  @action getProductPaidCommission = async (
    marketerId?: number,
    payoutLevelId?: number,
    categoryId?: number[],
    subCategoryIds?: number[],
    licenseId?: number,
    notes?: string,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortAscending?: boolean,
    fullTextFilter?: string,
    sortOrder?: number
  ) => {
    var result = await this.getClient().getProductPaidCommission(
      this.getApiVersion(),
      marketerId,
      payoutLevelId,
      categoryId,
      subCategoryIds,
      licenseId,
      notes,
      sortOrder,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action deleteAllSelectedProductsByLevels = async (
    requestProductModel: DeleteRequest
  ) => {
    var result = await this.getClient().deleteAllSelectedProductsByLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      requestProductModel || undefined
    );
    return result;
  };

  @action getContractUplineDetail = async (
    companyId: number,
    agentId: number
  ) => {
    var result = await this.getClient().getContractUplineDetail(
      companyId,
      agentId,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action updateContractGridColumnOrder = async (
    body: ContractGridColumnRequest
  ) => {
    var result = await this.getClient().updateContractGridColumnOrder(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body || undefined
    );
    return result;
  };

  @action getContractGridColumnOrder = async () => {
    var result = await this.getClient().getContractGridColumnOrder(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action bulkUpdateContractsQueue = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string
  ) => {
    var result = await this.getClient().bulkUpdateContractsQueue(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };
  @action bulkUpdateContractsHistory = async (
    batchId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string
  ) => {
    var result = await this.getClient().bulkUpdateContractsHistory(
      this.getApiVersion(),
      batchId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };
  @action bulkUpdateContracts = async (file: any) => {
    var result = await this.getClient().bulkUpdateContracts(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      file
    );
    return result;
  };
  @action bulkUpdateContractsTemplate = async () => {
    var result = await this.getClient().bulkUpdateContractsTemplate(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

    @action getInconsistencyContracts = async (
      body: InconsistencyContractsRequest
  ) => {
      var result = await this.getClient().getInconsistencyContracts(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };

    @action getInconsistencyAgentsForAutoRTS = async (
        body: InconsistencyAgentsRequest
  ) => {
        var result = await this.getClient().getInconsistencyAgentsForAutoRTS(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
    };

    @action getLookupsForInconsistencyContract = async (
        body: LookupsForInconsistencyContractRequest
    ) => {
        var result = await this.getClient().getLookupsForInconsistencyContract(
            this.getApiVersion(),
            UserContext.getUserId(),
            undefined,
            body
        );
        return result;
    };

  @action
  updateRTSContracts = async (item: any) => {
    var result = await this.getClient().updateRTSContracts(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
    return result;
  };
}

export default new ContractsStore();
