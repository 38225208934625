export enum Settings {
  General = "General",
  Commissions = "Commissions",
  Accounting = "Accounting",
  Licensing = "Contracting",
  Sales = "Sales",
  AppSettings = "Permissions (App Settings)",
  ManageIdentity = "Manage Identity",
  Roles = "Roles",
  Teams = "Teams",
  Users = "Users",
  UserActivity = "User Activity",
  IT = "IT",
  NewBusiness = "New Business",
  Supplies = "Supplies",
  UserAuditTrail = "User Audit Report",
  ManageImport = "Imports",
  UserAuditReport = "User Audit Trail",
}

export enum SubSettings {
  Announcements = "Announcements",
  CreditCardCharge = "Credit Card Charge Requests",
  CreditCardChargeRequests = "Credit Card Charge Requests",
  CarrierBankDeposit = "Carrier Bank Deposit",
  UserActivity = "User Activity",
  RenewalImports = "Revenue Imports",
  Products = "Products",
  Carriers = "Carriers",
  Supplies = "Supplies",
  LicenseDashboard = "License Dashboard",
  ManageStatusReasons = "Manage Status Reasons",
  ManageCompanyLicense = "Manage Carrier License",
  NumberDefaults = "Number Defaults",
  WebsiteLinks = "Website Links",
  SubmissionManagement = "Submission Management",
  ProductSubCategories = "Product SubCategories",
  Certifications = "Certifications",
  LicenseSplitter = "License Splitter",
  PasswordList = "Password List",
  ManageDocuments = "Manage Documents",
  PayoutLevels = "Payout Levels",
  ManageEmailandFaxDetails = "Manage Email and Fax Details",
  ImportAgentCertificates = "Import Agent Certificates",
  ManageProductLines = "Manage Product Lines",
  ManageDocumentFileTypes = "Manage Document File Types",
  MarketerMailConfiguration = "Marketer Mail Configuration",
  PrintIMBMailShippingSlip = "Print IMB Mail Shipping Slip",
  AgentGroups = "Agent Groups",
}
export enum GeneralSettings {
  Announcements = "Announcements",
  Carriers = "Carriers",
  CarrierGroups = "Carrier Groups",
  Certifications = "Certifications",
  ImportAgentCertificates = "Import Agent RTS Information",
  ManageDocuments = "Manage Documents",
  ManageEmailandFaxDetails = "Manage Email and Fax Details",
  //ManageUsers = 'Manage Users',
  PasswordList = "Password List",
  ManageProductLines = "Manage Product Lines",
  PayoutLevels = "Payout Levels",
  PrintIMBMailShippingSlip = "Print IMB Mail Shipping Slip",
  Products = "Products",
  ProductSubCategories = "Product SubCategories",
  Supplies = "Supplies",
  WebsiteLinks = "Website Links",
  SubmissionManagement = "Submission Management",
  CreditChargeList = "Credit Card Charge Requests",
  CreditCardChargeRequests = "Credit Card Charge Requests",
  AccountingJournalReports = "Accounting Journal Reports",
  AgentGroups = "Agent Groups",
  Emails = "Emails",
  CarrierManagement = "Carrier Management",
  Notifications = "Notifications",
  ManageAttributes = "Manage Custom Attributes",
}
export enum CommisionsSettings {
  BackoutRenewalImport = 'Back out Revenue Import',
  CommissionPlans = 'Commission Plans',
  EnterCommissions = 'Enter Commissions',
  MergeStatementDivision = 'Merge Statement Division',
  PolicyDashboard = 'Policy Dashboard',
  PrintCommissions = 'Print Commissions',
  RemoveFuneralHomeStatements = 'Remove Funeral Home Statements',
  RenewalDashboard = 'Revenue Dashboard',
  RenewalBackout = 'Revenue Backout',
  RunCommissions = 'Run Commissions',
  ProcessCommissions = 'Process Commissions',
  ManageDocumentFileTypes = 'Manage Document File Types',
  EnhancedCommissions = 'Enhanced Commissions',
}
export enum AccountingSettings {
  AccountingJournalReports = "Accounting Journal Reports",
  CreditCardCharge = "Credit Card Charge Requests",
  CreditCardChargeRequests = "Credit Card Charge Requests",
  ImportAgentDebt = "Import Agent Debt",
  ManageAgentDebtImports = "Manage Agent Debt Imports",
  ManageRevenue = "Manage Revenue",
  NoCommissionEmailReports = "No Commission Email Reports",
  ReceivingPaperStatement = "Receiving Paper Statement Report",
  CarrierBankDeposits = "Carrier Bank Deposits",
  PolicyAdjustments = "Accounting Adjustments",
}
export enum ITSettings {
  UserActivity = "User Activity",
  ManageUsers = "Manage Users",
}
export enum LicensingSettings {
  LicenseDashboard = "Contract Dashboard",
  LicenseSplitter = "Contract Splitter",
  ManageCompanyLicenseNumberDefaults = "Manage Carrier License Number Defaults",
  ManageDocumentFileTypes = "Manage Document File Types",
  ManageMarketerEmailsforContractInvites = "Manage Contract Invite Emails",
  ManageStatusReasons = "Manage Status Reasons",
  ImportLicensePersistencyTypes = "Import Persistency",
  BulkUpload = "Bulk Upload ",
}
export enum SalesSettings {
  AgentLeadDisabledCarriers = "Agent Lead Disabled Carriers",
}

export enum NewBusinessSettings {
  Certifications = "Certifications",
}

export enum ManageImport {
  ManageColumnDefinitions = "Manage Column Definitions",
  ManageImports = "Manage Imports",
}

export enum Supplies {
  Supplies = "Supplies",
  PrintIMBMailShippingSlip = "Print IMB Mail Shipping Slip",
}

export enum RunCommissionsTabViewModelTypes {
  RemoveFuneralHomeStatements = "RemoveFuneralHomeStatements",
  ProcessCommissions = "ProcessCommissions",
}

export enum RunCommissionsTabs {
  RemoveFuneralHomeStatements = "Remove Funeral Home Statements",
  ProcessCommissions = "Process Commissions",
}

export enum PayoutLevel {
  Add = "Add Payout Level",
  Edit = "Edit Payout Level",
  AddProduct = "Add Product Payout",
  EditProduct = "Edit Product Payout",
}

export enum Carrier {
  AddPTColor = "Add Persistency Type Color",
  EditPTColor = "Edit Persistency Type Color",
  AddPT = "Add Persistency Type",
  EditPT = "Edit Persistency Type",
  AddWRN = "Add Writing Number Default",
  EditWRN = "Edit Writing Number Default",
}

export enum CommissionPlan {
  AddAgeRange = "Add Age Range",
  EditAgeRange = "Edit Age Range",
  AddDuration = "Add Duration And Rate",
  EditDuration = "Edit Duration And Rate",
  AddLevel = "Add Level",
  EditLevel = "Edit Level",
}

export enum FileType {
  EFT = "EFT",
}

export enum CommissionPlanHeader {
  Contracts = "Contracts",
  Policies = "Policies",
}
