/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action, toJS } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import InsuredStore from "../../store/InsuredStore";
import AddressStore from "../../store/AddressStore";
import RouteList from "../../infrastructure/RouteList";
import IMASLog from "../../infrastructure/IMASLog";
import Utils from "../../infrastructure/Utils";
import { routes } from "../../router";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import {
  CustomerDetailModel,
  CreateUpdateCustomerModel,
  CustAttrToEntityModel,
} from "../../services/InsuredService";
import PageContext from "../../infrastructure/PageContext";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import {
  CustomerTitle,
  SsnValidation,
} from "../../infrastructure/enum/Customer";
import UserContext from "../../infrastructure/UserContext";
import { CustomerDetails } from "../../infrastructure/enum/AgentDetails";
import { CustomerSummaryViewModel } from "./CustomerSummaryViewModel";
import { CustomAttributesComponentViewModel } from "../../views/customers/CustomAttributesComponentViewModel";
import CustomAttributeStore from "../../store/CustomAttributeStore";
import { Permission } from "../../infrastructure/enum/Permission";
import { googleTagManager } from "../../infrastructure/tracking";
import { Constants } from "../../infrastructure/enum/Constants";
import Collection from "../../infrastructure/CollectionHelper";

export class AddEditCustomerViewModel implements ViewModel {
  @observable title: string = "";
  @observable lastName: string = "";
  @observable firstName: string | undefined = "";
  @observable middleName: string = "";
  @observable isException: boolean = false;
  @observable suffix: string = "";
  @observable zip: string = "";
  @observable city: string = "";
  @observable state: string = "None";
  @observable gender: string = "";
  @observable dob: Date | undefined;
  @observable ssn: string = "";
  @observable validateSSN: string = "";
  @observable mbi: string = "";
  @observable hicn: string = "";
  @observable payor: string = "";
  @observable county: string = "";
  @observable medicaidnumber: string = "";
  @observable stateprogram: string = "";
  @observable subsidy: string = "";
  @observable isSuccess: boolean = false;
  @observable isLoading: boolean = true;
  @observable isEdit: boolean = false;
  @observable customerId: number = 0;
  @observable isMbiValid: boolean = false;
  @observable isCancelConfirm: boolean = false;
  @observable validMbiError: string = "";
  @observable exceptionMessage: any;
  @observable response: any = "";
  @observable address1: string = "";
  @observable address2: string = "";
  @observable ignoreNameValidation: boolean = false;
  @observable maxDate: Date = new Date();
  @observable isDOBValid: boolean = false;
  @observable validDOBError: string = "";
  @observable isfirstNameValid: boolean = false;
  @observable validfirstNameError: string = "";
  @observable selectedKey: string = CustomerDetails.Summary;
  @observable selectedTabIndex?: number = 0;
  @observable dummyState: any = "";
  @observable genderList = [
    { label: "Not Selected", value: "" },
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];

  //custom attribute states
  @observable addAttributesModal: boolean = false;
  @observable CustomAttributesComponentViewModel: CustomAttributesComponentViewModel = new CustomAttributesComponentViewModel();
  @observable isCustomAttrActive: boolean = false;
  @observable attributesScreenPath: number = 0;
  @observable customAttributeList: any = [];
  @observable isValid = true;
  @observable attributesScreenPathId: number = 0;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();

  @action setIsfirstNameValid(value: boolean) {
    this.isfirstNameValid = value;
  }

  @action setValidfirstNameError(value: string) {
    this.validfirstNameError = value;
  }

  @observable islastNameValid: boolean = false;
  @observable validlastNameError: string = "";
  @action setIslastNameValid(value: boolean) {
    this.islastNameValid = value;
  }

  @action setValidlastNameError(value: string) {
    this.validlastNameError = value;
  }

  @observable ismiddleNameValid: boolean = false;
  @observable validmiddleNameError: string = "";
  @action setIsmiddleNameValid(value: boolean) {
    this.ismiddleNameValid = value;
  }

  @action setValidmiddleNameError(value: string) {
    this.validmiddleNameError = value;
  }
  @action setIsDOBValid(value: boolean) {
    this.isDOBValid = value;
  }

  @action setValidDOBError(value: string) {
    this.validDOBError = value;
  }
  @observable isSsnValid: boolean = false;
  @observable validSsnError: string = "";
  @action setIsSsnValid(value: boolean) {
    this.isSsnValid = value;
  }

  @action setValidSsnError(value: string) {
    this.validSsnError = value;
  }
  @observable
  public ErrorModel = new ErrorModel(new AddCustomerValidator());
  @observable
  public ErrorModelCustomer = new ErrorModel(new AddCustomerValidator());
  public CustomerSummaryViewModel = new CustomerSummaryViewModel();

  @action setCancelConfirmation(value: boolean) {
    this.isCancelConfirm = value;
  }

  @action
  public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };

  @observable stateList: any;
  private loadstateList(result: CustomerDetailModel) {
    if (result.statesList) {
      this.stateList = [];
      this.stateList.push({ label: "None", value: "" });
      var sortStateList = [...result.statesList].sort(Utils.compareListItem);
      for (let item of sortStateList) {
        this.stateList.push({
          label: "" + item.stateName,
          value: "" + item.abbreviation,
          isHeader: item.isHeader,
        });
      }
      this.setState(result.state ? "" + result.state : "");
    }
  }

  @observable countyList = [{ label: "None", value: "" }];
  private loadcountyList(result: any) {
    if (result) {
      this.countyList = [];
      this.countyList.push({ label: "None", value: "" });
      var sortcountyList = [...result.countyList].sort(Utils.compareListItem);
      for (let item of sortcountyList) {
        this.countyList.push({
          label: item.countyName ? item.countyName : "",
          value: item.countyName ? item.countyName : "",
        });
      }
      this.setCounty(result.countyName ? "" + result.countyName : "");
    }
  }

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    }
    catch (e) {
      throw e;
    }

  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(screenId, this.customerId);
      return res;
    }
    catch (e) {
      throw e;
    }

  }
  @observable subsidyList = [{ label: "None", value: "0" }];
  private loadSubsidyList(result: any) {
    if (result) {
      this.subsidyList = [];
      this.subsidyList.push({ label: "None", value: "0" });
      var sortSubsidyList = [...result.subsidyLevelList].sort(
        Utils.compareListItem
      );
      for (let item of sortSubsidyList) {
        this.subsidyList.push({
          label: item.value ? item.value : "",
          value: item.id ? "" + item.id : "",
        });
      }
      this.subsidy = result.subsidyLevelId ? "" + result.subsidyLevelId : "0";
    }
  }

  @action Load = async (customerId: any) => {        
    PageContext.isCustomAttribute = false;
    this.isSuccess = false;
    this.isException = false;
    PageContext.setIsOverlay(true);
    await this.resetAll();
    await this.ResetValidate();
    await this.ResetValidateCustomer();
    if (customerId) {
      this.isEdit = true;
      this.title = CustomerTitle.Edit;
      this.customerId = +customerId;
      this.setIgnoreNameValidation(false);
    } else {
      this.isLoading = false;
      this.isEdit = false;
      this.customerId = 0;
      this.title = CustomerTitle.Add;
    }
    this.maxDate = this.DefaultDate();
    this.setIslastNameValid(false);
    this.setIsfirstNameValid(false);
    this.setIsDOBValid(false);
    this.setIsmiddleNameValid(false);
    this.setIsSsnValid(false);
    await this.loadCustomerDetails();
    PageContext.setIsOverlay(false);

    if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
      // get Custom attribute screen permission
      var res = await this.getCustomAttributePermissionByScreen();
      if (res) {
        const data = res.filter(x => x.path === Permission.AddEditCustomerScreen && x.active === true).pop();
        this.isCustomAttrActive = data && Object.keys(data).length > 0 ? true : false;
        this.attributesScreenPath = data && data.id || 0;

        var attributes = await this.getCustomAttributesByScreen(data && data.id || 3);
        if (attributes) {
          this.customAttributeList = attributes;
        }

        this.attributesScreenPathId = data && data.id || 3;

      }
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };

  @action DefaultDate = () => {
    var d: Date = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year, month, day - 1);
  };
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action setGender(value: string) {
    this.gender = value;
  }
  @action setFirstName(value: string) {
    this.firstName = value.replace(/^(.)|\s(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  }
  @action setLastName(value: string) {
    this.lastName = value.replace(/^(.)|\s(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  }
  @action setMiddleName(value: string) {
    this.middleName = value.replace(/^(.)|\s(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  }
  @action setSuffix(value: string) {
    this.suffix = value.replace(/^(.)|\s(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  }
  @action setZip(value: string) {
    this.zip = value;
  }
  @action setCity(value: string) {
    this.city = value;
  }
  @action setState(value: string) {
    this.state = value;
  }
  @action setDob(value: Date | undefined) {
    this.dob = value;
  }
  @action setCounty(value: string) {
    this.county = value;
  }
  @action setMedicaidnumber(value: string) {
    this.medicaidnumber = value.replace(/[^a-zA-Z0-9 ]/g, "");
  }
  @action setStateprogram(value: string) {
    this.stateprogram = value.replace(/[^a-zA-Z0-9 ]/g, "");
  }
  @action setSubsidy(value: string) {
    this.subsidy = value;
  }
  @action setAddress1(value: string) {
    this.address1 = value;
  }
  @action setAddress2(value: string) {
    this.address2 = value;
  }
  @action async setSsn(value: string) {
    this.ssn = value;
    this.validateSSN = value.replaceAll("-", "").replaceAll("_", "");
    await this.Validate();
    if (value === "" || this.validateSSN.length == 9) {
      this.validateCustomerDetails("SSN", this.ssn);
    }
  }
  @action setMbi(value: string) {
    if (value && value.length > 0) {
      this.mbi = value.slice(0, 1) === "0" ? value.substring(1) : value;
      if (
        value.length > 1 &&
        (value.includes("S") ||
          value.includes("L") ||
          value.includes("O") ||
          value.includes("I") ||
          value.includes("B") ||
          value.includes("Z"))
      ) {
        this.mbi = value
          .replace("S", "")
          .replace("O", "")
          .replace("Z", "")
          .replace("I", "")
          .replace("B", "")
          .replace("L", "");
      }
    } else {
      this.mbi = value;
    }
  }
  @action setHicn(value: string) {
    this.hicn = value.toUpperCase();
  }

  @action setIsMbiValid(value: boolean) {
    this.isMbiValid = value;
  }

  @action setValidMbiError(value: string) {
    this.validMbiError = value;
  }

  @action setPayor(value: string) {
    this.payor = value;
  }
  @action setIsSuccess(value: boolean) {
    this.isSuccess = value;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action
  public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action resetAll = async () => {
    this.isCancelConfirm = false;
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.suffix = "";
    this.zip = "";
    this.city = "";
    this.state = "";
    this.dob = undefined;
    this.mbi = "";
    this.county = "";
    this.hicn = "";
    this.payor = "";
    this.ssn = "";
    this.validateSSN = "";
    this.address1 = "";
    this.address2 = "";
    this.stateList = [];
    this.isMbiValid = false;
    this.gender = "";
    this.selectedKey = CustomerDetails.Summary;
    this.selectedTabIndex = 0;
    this.CustomerSummaryViewModel.addContactDto = [];
    this.CustomerSummaryViewModel.ContactList.values = [];
    this.medicaidnumber = "";
    this.stateprogram = "";
    this.subsidy = "";
    PageContext.isCustomAttribute = false;
    this.response = "";
  };

  @action gotoDetails(isUpdated: boolean) {
    RouteParamDetails.isUpdated = isUpdated;
    routes.customerDetails.replace({ customerId: "" + this.customerId });

  }

  @action ResetValidateCustomer = async () => {
    return await this.ErrorModelCustomer.ResetValidation(this);
  };

  @action searchByZip = async () => {
    try {
      this.isLoading = true;
      this.isException = false;
      if (this.zip && this.zip.length >= 5) {
        var result = await AddressStore.getAddressByZipCode(this.zip);
        if (result) {
          this.state = result.stateAbbrivation ? result.stateAbbrivation : "";
          this.city = result.city ? result.city : "";
          if (result.countyList) {
            this.loadcountyList(result);
          }
          this.isLoading = false;
        }
      }
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception.." + error);
    }
  };

  @action loadCustomerDetails = async () => {
    try {
      this.isException = false;
      // api to fet customer details using customer id
      var result = await InsuredStore.getCustomerDetails(
        this.customerId ? this.customerId : 0
      );
      if (result) {
        if (result.statesList) {
          this.loadstateList(result);
        }
        if (result.countyList) {
          this.loadcountyList(result);
        }
        this.medicaidnumber = result.medicaidNumber
          ? result.medicaidNumber
          : "";
        if (result.subsidyLevelList) {
          this.loadSubsidyList(result);
        }

        this.firstName = result.fName ? result.fName : "";
        this.lastName = result.lName ? result.lName : "";
        this.middleName = result.mi ? result.mi : "";
        this.dob =
          result.bDay && this.isEdit ? new Date(result.bDay) : undefined;
        this.zip = result.zip ? result.zip : "";
        this.city = result.city ? result.city : "";
        this.mbi = result.medicareBeneficiaryIdentificationNumber
          ? result.medicareBeneficiaryIdentificationNumber
          : "";
        this.ssn = result.ssn ? result.ssn : "";
        this.validateSSN = result.ssn ? result.ssn : "";
        this.hicn = result.hicn ? result.hicn : "";
        this.county = result.countyName ? result.countyName : "";
        this.payor = result.payorName ? result.payorName : "";
        this.suffix = result.suffix ? result.suffix : "";
        this.address1 = result.street ? result.street : "";
        this.address2 = result.street2 ? result.street2 : "";
        this.state = result.state ? result.state : "";
        this.gender = result.gender ? result.gender.trim() : "";
        this.stateprogram = result.statePharmaceuticalProgramMemberNumber || "";
      }
      if (this.isEdit) {
        this.CustomerSummaryViewModel.loadContacts();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      this.ResetValidate();
      IMASLog.log("exception.." + error);
    }
  };

  @action setIgnoreNameValidation(value: boolean) {
    this.ignoreNameValidation = value;
  }

  @action addEditCustomer = async () => {
    await this.ResetValidate();
    PageContext.isMessageVisible = false;
    this.isMbiValid = false;
    this.validMbiError = "";
    var mbiValue = this.mbi.replace(/_/g, "").replace(/-/g, "").trim();
    if (
      (mbiValue && mbiValue.length > 0 && mbiValue.length < 11) ||
      (mbiValue &&
        mbiValue.length > 0 &&
        (mbiValue[0] === "0" ||
          mbiValue.includes("S") ||
          mbiValue.includes("L") ||
          mbiValue.includes("O") ||
          mbiValue.includes("I") ||
          mbiValue.includes("B") ||
          mbiValue.includes("Z")))
    ) {
      this.setIsMbiValid(true);
      this.setValidMbiError("MBI is Invalid");
    }

    //validation for firstName
    var firstNameValue = "";
    var FName: any[] = [];
    if (this.firstName) {
      firstNameValue = this.firstName.toLowerCase().trim();
      FName = firstNameValue.split("");

      if (
        firstNameValue &&
        FName &&
        (firstNameValue.length < 2 ||
          ["'", "-"].includes(FName[0]) ||
          ["'", "-"].includes(FName[FName.length - 1]) ||
          firstNameValue.split(" ").length - 1 > 1)
      ) {
        this.setIsfirstNameValid(true);
        this.setValidfirstNameError("First Name is Invalid");
        return 0;
      } else {
        this.setIsfirstNameValid(false);
        this.setValidfirstNameError("");
      }
    } else {
      this.setIsfirstNameValid(false);
      this.setValidfirstNameError("");
    }

    //validation for lastName
    var lastNameValue = "";
    var LName: any[] = [];
    if (this.lastName) {
      lastNameValue = this.lastName.trim();
      LName = lastNameValue.split("");

      if (
        lastNameValue &&
        LName &&
        (lastNameValue.length < 1 ||
          ["'", "-"].includes(LName[0]) ||
          ["'", "-"].includes(LName[LName.length - 1]))
      ) {
        this.setIslastNameValid(true);
        this.setValidlastNameError("Last Name is Invalid");
        return 0;
      } else {
        this.setIslastNameValid(false);
        this.setValidlastNameError("");
      }
    } else {
      this.setIslastNameValid(false);
      this.setValidlastNameError("");
    }

    //validation for middleName
    var middleNameValue = "";
    var MName: any[] = [];
    if (this.middleName) {
      middleNameValue = this.middleName.toLowerCase().trim();
      MName = middleNameValue.split("");

      if (
        middleNameValue &&
        MName &&
        (middleNameValue.length < 1 ||
          ["'", "-"].includes(MName[0]) ||
          ["'", "-"].includes(MName[MName.length - 1]) ||
          middleNameValue.split(" ").length - 1 > 1)
      ) {
        this.setIsmiddleNameValid(true);
        this.setValidmiddleNameError("Middle Name is Invalid");
      } else {
        this.setIsmiddleNameValid(false);
        this.setValidmiddleNameError("");
      }
    } else {
      this.setIsmiddleNameValid(false);
      this.setValidmiddleNameError("");
    }

    //validation for SSN
    var SSNValue = "";
    var SSNName: any[] = [];
    if (this.ssn) {
      SSNValue = this.ssn.includes("-")
        ? this.ssn
        : this.ssn.substring(0, 3) +
        "-" +
        this.ssn.substring(3, 5) +
        "-" +
        this.ssn.substring(5, 9);
      SSNName = SSNValue.split("-");
      if (
        SSNValue &&
        SSNName &&
        (SSNName[0] === SsnValidation.first ||
          SSNName[1] === SsnValidation.second ||
          SSNName[2] === SsnValidation.thrid)
      ) {
        this.setIsSsnValid(true);
        this.setValidSsnError("SSN is Invalid");
      } else {
        this.setIsSsnValid(false);
        this.setValidSsnError("");
      }
    } else {
      this.setIsSsnValid(false);
      this.setValidSsnError("");
    }

    //validation for calender date for age 18
    //    if (this.dob) {
    //     if(this.dob && Utils.validateDOB(this.dob)){
    //         this.setIsDOBValid(true);
    //         this.setValidDOBError(Constants.dobValidation);
    //     }
    //     else{
    //         this.setIsDOBValid(false);
    //         this.setValidDOBError('');
    //     }

    // } else {
    //     this.setIsDOBValid(false);
    //     this.setValidDOBError('');
    // }

    if (
      (await this.Validate()) ||
      this.isMbiValid ||
      this.isfirstNameValid ||
      this.islastNameValid ||
      this.ismiddleNameValid ||
      this.isSsnValid ||
      this.isDOBValid
    )
      return 0;
    try {
      this.isLoading = true;
      this.isException = false;
      this.setIsSuccess(false);
      this.setIgnoreNameValidation(
        RouteParamDetails.customerIgnoreNameValidation
      );
      if (
        !(await this.Validate()) ||
        RouteParamDetails.customerIgnoreNameValidation === true
      ) {
        try {
          var ssnValue = "";
          if (this.ssn) {
            ssnValue = this.ssn.replace(/-/g, "").trim();
          }
          this.CustomerSummaryViewModel.addContactDto.forEach((item: any) => {
            item["id"] = 0;
          });

          this.validateCustomAttributes()

          if (this.isValid) {
            var updateDto: CreateUpdateCustomerModel = {
              fName: this.firstName
                ? this.firstName.charAt(0).toUpperCase() +
                this.firstName.slice(1).toLowerCase()
                : "",
              lName: this.lastName
                ? Utils.capitalize(this.lastName)
                : "",
              mi: this.middleName
                ? this.middleName.charAt(0).toUpperCase() +
                this.middleName.slice(1).toLowerCase()
                : "",
              suffix: this.suffix,
              city: this.city
                ? this.city.charAt(0).toUpperCase() +
                this.city.slice(1).toLowerCase()
                : "",
              state: this.state ? this.state : "",
              countyName: this.county,
              bDay: this.dob,
              zip: this.zip,
              ssn: ssnValue,
              hicn: this.hicn,
              payorName: this.payor,
              medicareBeneficiaryIdentificationNumber: mbiValue,
              address1: this.address1,
              address2: this.address2,
              ignoreNameValidation: this.ignoreNameValidation,
              gender: this.gender,
              contactList: this.CustomerSummaryViewModel.addContactDto,
              medicaidNumber: this.medicaidnumber,
              subsidyLevelId: this.subsidy ? parseInt(this.subsidy) : 0,
              statePharmaceuticalProgramMemberNumber: this.stateprogram,
            };

            if (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
                Utils.hasUserPermission(Permission.PublicCustomAttribute)) {
                updateDto.customAttributes = await this.createUpdateAttributes();
            }

            if (this.isEdit) {
              try {
                updateDto.id = this.customerId;
                await InsuredStore.editCustomer(updateDto);
                await this.ResetValidate();
                RouteParamDetails.customerSummaryCall = true;
                this.gotoDetails(true);
                this.isLoading = false;
              } catch (error) {
                this.isLoading = false;
                this.isException = true;
                return 0;
              }
            }
            else {

              try {
                this.customerId = await InsuredStore.addCustomer(updateDto);
                await this.ResetValidate();
                RouteParamDetails.customerSummaryCall = true;
                this.gotoDetails(false);
                this.isLoading = false;
              } catch (error) {
                this.exceptionMessage = error.response;
                IMASLog.log("exception: " + error);
                this.isLoading = false;
                this.isException = true;
                return 0;
              }
            }

            await this.ResetValidate();
            this.setIsSuccess(true);
            this.gotoDetails(false);
            this.isLoading = false;
            RouteParamDetails.customerIgnoreNameValidation = false;

          }

        } catch (error) {
          IMASLog.log("exception: " + error);
          this.isLoading = false;
          this.isException = true;
          RouteParamDetails.customerIgnoreNameValidation = false;
          return 0;
        }
      }
      this.isLoading = false;
    } catch (error) {
      IMASLog.log("exception: " + error);
      this.exceptionMessage = error.response;
      this.isLoading = false;
      this.isException = true;
      return 0;
    }
  };

  @action setSuccessResponse() {
    window.scrollTo(0, 0);
  }

  @action
  public Cancel = async () => {
    localStorage.removeItem("CustomAttribute");
    if (this.isEdit) {
      routes.customerDetails.replace({ customerId: "" + this.customerId });
    } else {
      routes.customerSearch.replace();
    }
  };

  @action validateCustomerDetails = async (key: string, value: string) => {
    if (value) {
      let result = await InsuredStore.validateCustomerDetails(
        key,
        value.replace(/-/g, ""),
        this.customerId,
        this.customerId === 0
      );
      if (result) {
        if (!result.valid && result.value) {
          let message = { status: 400, title: result.value };
          PageContext.setIsMessageVisible(true);
          PageContext.setResponseMessage(JSON.stringify(message));
        }
      }
    }
  };

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    //this.setIsEditAccess(false);
    this.updateSelectedKey(key);
  };
  @action updateSelectedKey = (value: string) => {
    this.selectedKey = value;
    switch (value) {
      case CustomerDetails.Summary:
        this.setIsException(false);
        break;
      case CustomerDetails.ContactOptions:
        this.CustomerSummaryViewModel.customerId = this.customerId;
        if (this.customerId !== 0) {
          this.CustomerSummaryViewModel.loadContacts();
        }
        break;
    }
  };


  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value;
    setTimeout(async () => {
      value && this.CustomAttributesComponentViewModel.loadExistingAttributes(this.attributesScreenPathId);
      !value && this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  }

  @action AttributeSave = (type: number) => {
    let data = this.CustomAttributesComponentViewModel.saveAttribute(type);
    this.addAttributesModal = false;

    setTimeout(async () => {
      var attributes = await this.getCustomAttributesByScreen(this.attributesScreenPath || 3);
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  }

  @action public validateCustomAttributes = async () => {
    PageContext.isCustomAttribute = false;
    this.response = "";
    var attrData = localStorage.getItem("CustomAttribute");
    var result = [];
    this.isValid = true;
    if (attrData) {
      result = JSON.parse(attrData);
      var mandatoryCustomAttributeData = result.filter((x: any) => x.isMandatory == true && (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined));
      if (mandatoryCustomAttributeData != null && mandatoryCustomAttributeData.length > 0 && (Utils.hasUserPermission(Permission.PrivateCustomAttribute) || Utils.hasUserPermission(Permission.PublicCustomAttribute))) {
        PageContext.isCustomAttribute = true;
        this.isValid = false;
        window.scrollTo(0, 0);
        var responseModel = {
          status: 400,
          title: Constants.CustomAttributeValidationMessage,
          errors: { "": [] },
        };
        this.response = JSON.stringify(responseModel);
      }
    }
  }

  @action createUpdateAttributes = async () => {
    let data: any = [];
    (this.customAttributeList || []).map((item: any) => {
      item.attrValue = item.dataTypeId == 6 ? (item.attrValue ? Utils.getDateInFormat(new Date(item.attrValue)) : "") : item.attrValue;
      data.push({
        customAttributeScreenId: item.scrnCustAttrId,
        entityId: this.customerId,
        value: item.attrValue + "",
      });
    });

    return data;
  }


}

class AddCustomerValidator extends AbstractValidator<AddEditCustomerViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.firstName)
      .isNotEmpty()
      .withFailureMessage("First Name is required");
    this.validateIfString((input) => input.lastName)
      .isNotEmpty()
      .withFailureMessage("Last Name is required");
    this.validateIfString((input) => input.suffix)
      .hasMaxLength(5)
      .whenNotEmpty()
      .withFailureMessage("Suffix is Invalid");
    this.validateIfDate((input) => input.dob)
      .isNotEmpty()
      .withFailureMessage("DOB is required");

    this.validateIfString((input) => input.validateSSN)
      .hasMinLength(9)
      .whenNotEmpty()
      .withFailureMessage("Please enter 9 numerals for SSN");
  }
}
