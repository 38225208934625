export enum Permission {
    AddAgent = 'AddAgent',
    ViewAgent = 'ViewAgent',
    EditAgent = 'EditAgent',
    DeleteAgent = 'DeleteAgent',
    MergeAgent = 'MergeAgent',
    AddInsured = 'AddInsured',
    EditInsured = 'EditInsured',
    ViewInsured = 'ViewInsured',
    DeleteInsured = 'DeleteInsured',
    MergeInsured = 'MergeInsured',
    EditAgentDebt = 'EditAgentDebt',
    AddAgentContract = 'AddAgentContract',
    EditAgentContract = 'EditAgentContract',
    ViewAgentContract = 'ViewAgentContract',
    DeleteAgentContract = 'DeleteAgentContract',
    ViewContractAgentGroup = 'ViewContractAgentGroup',
    DisplayAgentContractProductDetailsSpecialNotes = 'DisplayAgentContractProductDetailsSpecialNotes',
    EditAgentContractProductDetails = 'EditAgentContractProductDetails',
    ViewAgentContractProductDetails = 'ViewAgentContractProductDetails',
    DeleteAgentContractProductDetails = 'DeleteAgentContractProductDetails',
    AddAgentContractProductDetails = 'AddAgentContractProductDetails',
    ToggleAgentPortalLogin = 'ToggleAgentPortalLogin',
    EditDoNotContact = 'EditDoNotContact',
    EditDoNotEmail = 'EditDoNotEmail',
    EditDoNotCall = 'EditDoNotCall',
    SendAgentResubscribeEmail = 'SendAgentResubscribeEmail',
    EditAgentSSN = 'EditAgentSSN',
    EditAgentDBA = 'EditAgentDBA',
    EditAgentTIN = 'EditAgentTIN',
    EditAgentNPN = 'EditAgentNPN',
    ViewAgentDBA = 'ViewAgentDBA',
    ViewAgentTIN = 'ViewAgentTIN',
    ViewAgentSSN = 'ViewAgentSSN',
    EditAgentSendNewsletter = 'EditAgentSendNewsletter',
    AddPublicAgentNote = 'AddPublicAgentNote',
    AddPrivateAgentNote = 'AddPrivateAgentNote',
    EditAnyAgentNote = 'EditAnyAgentNote',
    EditMyAgentNote = 'EditMyAgentNote',
    DeleteAnyAgentNote = 'DeleteAnyAgentNote',
    DeleteMyAgentNote = 'DeleteMyAgentNote',
    ViewPublicAgentNote = 'ViewPublicAgentNote',
    ViewPrivateAgentNote = 'ViewPrivateAgentNote',
    ReplaceAgentContract = 'ReplaceAgentContract',
    CreateLeadViaAgentProfileScreen = 'CreateLeadViaAgentProfileScreen',
    ViewAutoDialer = 'ViewDialer',
    // CallProspectiveAgents = "CallProspectiveAgents",
    // CallActiveAgents = "CallActiveAgents",
    // CallCalllist = "CallCalllist",
    CreateCalllist = 'CreateCalllist',
    GenerateIContactList = 'GenerateIContactList',
    ViewPreNeedAgents = 'ViewPreNeedAgents',
    ViewAdminFunctions = 'ViewAdminFunctions',
    ViewAccountTabOnAgentScreen = 'ViewAccountTabOnAgentScreen',
    ViewEFTDocs = 'ViewEFTDocs',
    AddFinancialNote = 'AddFinancialNote',
    StealAnyWorkflowApps = 'StealAnyWorkflowApps',
    StealAnyWorkflowContracts = 'StealAnyWorkflowContracts',
    EditTerminatedAgentRevenueOption = 'EditTerminatedAgentRevenueOption',
    RunCallSummaryReport = 'RunCallSummaryReport',
    CreateContactologyList = 'CreateContactologyList',
    ViewPoliciesTabOnAgentScreen = 'ViewPoliciesTabOnAgentScreen',
    ViewNotesTabOnAgentScreen = 'ViewNotesTabOnAgentScreen',
    ViewContractTabOnAgentScreen = 'ViewContractTabOnAgentScreen',
    ViewAgentNotes = 'ViewAgentNotes',
    ViewOrdersTabOnAgentScreen = 'ViewOrdersTabOnAgentScreen',
    ViewDocsTabOnAgentScreen = 'ViewDocsTabOnAgentScreen',
    ViewTripPointsTabOnAgentScreen = 'ViewPointsTabOnAgentScreen',
    ViewStandardReports = 'ViewStandardReports',
    ViewDocuments = 'ViewDocuments',
    ViewLeads = 'ViewLeads',
    EmailLeadNote = 'EmailLeadNote',
    DeletePolicy = 'DeletePolicy',
    ViewWorkflowTabOnAgentScreen = 'ViewWorkflowTabOnAgentScreen',
    AddWorkflowNoteFromAgentScreen = 'AddWorkflowNoteFromAgentScreen',
    ReferAnAgent = 'ReferAnAgent',
    ViewPasswordList = 'ViewPasswordList',
    ViewHiddenAgentProfile = 'ViewHiddenAgentProfile',
    RequestRemoteSupport = 'RequestRemoteSupport',
    NotifyMeWhenReferredAgentIsCalled = 'NotifyMeWhenReferredAgentIsCalled',
    InsertContractDirectlyIntoWorkflow = 'InsertContractDirectlyIntoWorkflow',
    EditPolicy = 'EditPolicy',
    EditAgentAgencies = 'EditAgentAgencies',
    AddPolicy = 'AddPolicy',
    CreateRevenueEntry = 'CreateRevenueEntry',
    RefaxApp = 'RefaxApp',
    ReFTPApp = 'ReFTPApp',
    ChangeMarketerParameter = 'ChangeMarketerParameter',
    ChangeMarketerParameters = 'ChangeMarketerParameters',
    ReviveDeletedDoc = 'ReviveDeletedDoc',
    MarkContractsAsReceivedinWorkflow = 'MarkContractsAsReceivedinWorkflow',
    SendLicenseKitFromDocumentSystem = 'SendLicenseKitFromDocumentSystem',
    ViewInternalDocs = 'ViewInternalDocs',
    CreateCustomCallList = 'CreateCustomCallList',
    TransmitFileViaEmail = 'TransmitFileViaEmail',
    // ViewMyCallsPage = "ViewMyCallsPage",
    ChangeAgency = 'ChangeAgency',
    AddLeadSource = 'AddLeadSource',
    EditLeadSource = 'EditLeadSource',
    DeleteLeadSource = 'DeleteLeadSource',
    AddCarrier = 'AddCarrier',
    EditCarrier = 'EditCarrier',
    DeleteCarrier = 'DeleteCarrier',
    AddAgentStatus = 'AddAgentStatus',
    EditAgentStatus = 'EditAgentStatus',
    DeleteAgentStatus = 'DeleteAgentStatus',
    AddContractType = 'AddContractType',
    EditContractType = 'EditContractType',
    DeleteContractType = 'DeleteContractType',
    AddMarketingEvent = 'AddMarketingEvent',
    EditMarketingEvent = 'EditMarketingEvent',
    DeleteMarketingEvent = 'DeleteMarketingEvent',
    AddAgency = 'AddAgency',
    EditAgency = 'EditAgency',
    DeleteAgency = 'DeleteAgency',
    WorkflowNotesFactorIntoDocumentAgeCalculation = 'WorkflowNotesFactorIntoDocumentAgeCalculation',
    OnlyViewDownlineAgents = 'OnlyViewDownlineAgents',
    EditDoNotMarket = 'EditDoNotMarket',
    ViewManagedEmployeesLeads = 'ViewManagedEmployeesLeads',
    AddAccountTransaction = 'AddAccountTransaction',
    EditAccountTransaction = 'EditAccountTransaction',
    DeleteAccountTransaction = 'DeleteAccountTransaction',
    ViewMainNavigationBar = 'ViewMainNavigationBar',
    ViewWorkflowAppCount = 'ViewWorkflowAppCount',
    ViewWorkflowContractCount = 'ViewWorkflowContractCount',
    OnlyViewAutoDialer = 'OnlyViewAutoDialer',
    DeleteCallList = 'DeleteCallList',
    ViewOptionsListPanel = 'ViewOptionsListPanel',
    OnlyViewLockedDownAutoDialer = 'OnlyViewLockedDownDialer',
    StealOnlyTeamWorkflowApps = 'StealOnlyTeamWorkflowApps',
    StealOnlyTeamWorkflowContracts = 'StealOnlyTeamWorkflowContracts',
    ImpersonateAllActiveUsers = 'ImpersonateAllActiveUsers',
    EditMarketingPreferences = 'EditMarketingPreferences',
    AddAddress = 'AddAddress',
    EditAddress = 'EditAddress',
    DeleteAddress = 'DeleteAddress',
    AddContactOption = 'AddContactOption',
    EditContactOption = 'EditContactOption',
    DeleteContactOption = 'DeleteContactOption',
    SetFutureFaxDate = 'SetFutureFaxDate',
    AddPaymentPlan = 'AddPaymentPlan',
    EditPaymentPlan = 'EditPaymentPlan',
    ViewPaymentPlan = 'ViewPaymentPlan',
    DeletePaymentPlan = 'DeletePaymentPlan',
    ModifyExcludeFromTrip = 'ModifyExcludeFromPoints',
    ViewEmployeePolicies = 'ViewEmployeePolicies',
    PullMarketingEventList = 'PullMarketingEventList',
    AddMarketingEventEmailRules = 'AddMarketingEventEmailRules',
    EditMarketingEventEmailRules = 'EditMarketingEventEmailRules',
    DeleteMarketingEventEmailRules = 'DeleteMarketingEventEmailRules',
    ViewMarketingEventEmailRules = 'ViewMarketingEventEmailRules',
    ViewTerminatedRevenueOption = 'ViewTerminatedRevenueOption',
    ViewOnlyPoBox = 'ViewOnlyPoBox',
    EditDNx = 'EditDNx',
    AddCommunication = 'AddCommunication',
    DeleteCommunication = 'DeleteCommunication',
    ViewCommunication = 'ViewCommunication',
    CanEditPaymentSource = 'CanEditPaymentSource',
    EditAppSettings = 'EditAppSettings',
    ViewPendingAppEntry = 'ViewPendingAppEntry',
    ViewAllOrdersByTeam = 'ViewAllOrdersByTeam',
    EditLocation = 'EditLocation',
    ContractStatusSavedInCookie = 'ContractStatusSavedInCookie',
    ModifyContractStatusFilter = 'ModifyContractStatusFilter',
    ViewAllArcLeadsNoOrder = 'ViewAllArcLeadsNoOrder',
    ViewCommissions = 'ViewCommissions',
    ViewCarrierUpdates = 'ViewCarrierUpdates',
    EditEAndOPariticipant = 'EditEAndOParticipant',
    AddLevelToContractingSentNotes = 'AddLevelToContractingSentNotes',
    CreatePrivateNotes = 'CreatePrivateNotes',
    ReceiveWorkflowEmail = 'ReceiveWorkflowEmail',
    ReceiveWorkflowTasks = 'ReceiveWorkflowTasks',
    AddDocumentsViaAgentProfile = 'AddDocumentsViaAgentProfile',
    AddTaskViaAgentProfile = 'AddTaskViaAgentProfile',
    AddOrderViaAgentProfile = 'AddOrderViaAgentProfile',
    SetDefaultContactTypeViaAgentProfile = 'SetDefaultContactTypeViaAgentProfile',
    UpdateQuickNotes = 'UpdateQuickNotes',
    EditSupplyOrder = 'EditSupplyOrder',
    DeleteSupplyOrder = 'DeleteSupplyOrder',
    ReOrderSupplyOrder = 'ReOrderSupplyOrder',
    AddInsuredNote = 'AddInsuredNote',
    AttachDocument = 'AttachDocument',
    CreateTask = 'CreateTask',
    ManageTeamTasks = 'ManageTeamTasks',
    ViewImdirectProduction = 'ViewImdirectProduction',
    ViewBankAccount = 'ViewBankAccount',
    ViewUnpaidCommissions = 'ViewUnpaidCommissions',
    ViewSupplyOrders = 'ViewSupplyOrders',
    NotesPrivateByDefault = 'NotesPrivateByDefault',
    ViewAnyStatement = 'ViewAnyStatement',
    ViewAgentStatementsThatAreNotEmployees = 'ViewAgentStatementsThatAreNotEmployees',
    ViewMarketerStatements = 'ViewMarketerStatements',
    ViewAgentStatementWhomUserIsMarketer = 'ViewAgentStatementWhomUserIsMarketer',
    // ViewArcLeadTransfer = "ViewArcLeadTransfer",
    Email = 'Email',
    Messaging = 'Messaging',
    // EditNickname = "EditNickname",
    DeletePhoneNumber = 'DeletePhoneNumber',
    // UpdateAgentStatus = "UpdateAgentStatus",
    ProcessOrders = 'ProcessOrders',
    SetPaymentType = 'SetPaymentType',
    SubmitViaFTP = 'SubmitViaFTP',
    SubmitViaFax = 'SubmitViaFax',
    ViewUserActivity = 'ViewUserActivity',
    ResetTAPI = 'ResetTAPI',
    ManageRevenue = 'ManageRevenue',
    AssignMarketingEventToCallList = 'AssignMarketingEventToCallList',
    ViewMarketerAgentProfile = 'ViewMarketerAgentProfile',
    ApproveAStatement = 'ApproveAStatement',
    MarkAStatementAsPaid = 'MarkAStatementAsPaid',
    ClickHyperlinkInWorkflowDataEntry = 'ClickHyperlinkInWorkflowDataEntry',
    ViewAndManagePublicAnnouncements = 'View /ManagePublicAnnouncements',
    ViewAndManagePrivateAnnouncements = 'View /ManagePrivateAnnouncements',
    ViewContractDashboard = 'ViewContractDashboard',
    EnterWebsiteLinks = 'EnterWebsiteLinks',
    CreateLead = 'CreateLead',
    EnterCommissions = 'EnterCommissions',
    RunCommissions = 'ViewProcessCommissions',
    PrintCommissions = 'PrintCommissions',
    CreditCardChargeRequests = 'CreditCardChargeRequests',
    CompleteWorkflowDocumentsInPending = 'CompleteWorkflowDocumentsInPending',
    ManageUser = 'ManageUser',
    EnterSubCategory = 'EnterSubCategory',
    DeleteSubCategory = 'DeleteSubCategory',
    EnterProduct = 'EnterProduct',
    DeleteProduct = 'DeleteProduct',
    EnterApptCert = 'EnterApptCert',
    ViewProduct = 'ViewProduct',
    UpdateProduct = 'UpdateProduct',
    ManageCert = 'ManageCert',
    DeleteOverrideCerts = 'DeleteOverrideCerts',
    ManageAccoutingJournal = 'ManageAccountingJournals',
    ViewContractSplitter = 'ViewContractSplitter',
    EditMarketerClassification = 'EditMarketerClassification',
    DownloadMarketingEventStatisticsReport = 'DownloadMarketingEventStatisticsReport',
    DownloadMarketingEventResendableEmailList = 'DownloadMarketingEventResendableEmailList',
    ManageCarrier = 'ManageCarrier',
    SendDocumentFromPendingSubmissionToMissingInfo = 'SendDocumentFromPendingSubmissionToMissingInfo',
    ViewAimcAgentDemographics = 'ViewAimcAgentDemographics',
    NotSaveNonCertified = 'NotSaveNonCertified',
    NotSaveNonAppointed = 'NotSaveNonAppointed',
    ViewMarketingEventReport = 'ViewMarketingEventReport',
    EditBankAccount = 'EditBankAccount',
    ViewCommissionReport = 'ViewCommissionReport',
    ChangePaymentPlanStatusDropdownOnFindAnAgent = 'ChangePaymentPlanStatusDropdownOnFindAnAgent',
    EditSupplyComment = 'EditSupplyComment',
    EditSupplyItemComment = 'EditSupplyItemComment',
    ViewPolicyRevenueReport = 'ViewPolicyRevenueReport',
    ChangePayeeWithoutPaymentPlan = 'ChangePayeeWithoutPaymentPlan',
    ChangePayeeRegardlessOfExistingPaymentPlan = 'ChangePayeeRegardlessOfExistingPaymentPlan',
    AddPayee = 'AddPayee',
    DeletePayee = 'DeletePayee',
    ViewPayeeColumn = 'ViewPayeeColumn',
    EditRevenueEntry = 'EditRevenueEntry',
    DeleteRevenueEntry = 'DeleteRevenueEntry',
    ViewRevenues = 'ViewRevenues',
    ManageDocuments = 'ManageDocuments',
    AddAppointment = 'AddAppointment',
    EditAppointment = 'EditAppointment',
    DeleteAppointment = 'DeleteAppointment',
    ViewAppointment = 'ViewAppointment',
    AddSupply = 'AddSupply',
    EditSupply = 'EditSupply',
    DeleteSupply = 'DeleteSupply',
    ViewSupply = 'ViewSupply',
    ManageCommissionPlans = 'ManageCommissionPlans',
    ViewRevenueDashboard = 'ViewRevenueDashboard',
    ViewRevenueBackout = 'ViewRevenueBackout',
    ViewBackOutRevenueImport = 'ViewBackOutRevenueImport',
    ManagePayoutLevels = 'ManagePayoutLevels',
    ModifyRevenueReminderNote = 'ModifyRevenueReminderNote',
    MarkContractsAsPendingCertificationsinWorkflow = 'MarkContractsAsPendingCertificationsinWorkflow',
    EditPolicyNumberIsValid = 'EditPolicyNumberIsValid',
    ManageEmailAndFaxDetails = 'ManageEmailAndFaxDetails',
    ImportAgentCertificates = 'ImportAgentCertificates',
    AccessReceivedQueue = 'AccessReceivedQueue',
    AccessScrubQueue = 'AccessScrubQueue',
    AccessMissingInfoQueue = 'AccessMissingInfoQueue',
    AccessDataEntryQueue = 'AccessDataEntryQueue',
    AccessPendingQueue = 'AccessPendingQueue',
    AccessPendingSubmissionQueue = 'AccessPendingSubmissionQueue',
    AccessAssignmentQueue = 'AccessAssignmentQueue',
    DeleteDocument = 'DeleteDocument',
    MoveDocumentToDataEntry = 'MoveDocumentToDataEntry',
    EditDocumentMetadata = 'EditDocumentMetadata',
    ShowOnUserDropdowns = 'ShowOnUserDropdowns',
    ModifyOriginalEffectiveDate = 'ModifyOriginalEffectiveDate',
    ManageContractStatusReasons = 'ManageContractStatusReasons',
    EditHiddenAgentProfile = 'EditHiddenAgentProfile',
    ReceiveBrokenOrderAddressNotification = 'ReceiveBrokenOrderAddressNotification',
    ViewProductLineMatrix = 'ViewProductLineMatrix',
    ViewPolicyDashboard = 'ViewPolicyDashboard',
    ManageProductLines = 'ManageProductLines',
    ManageDocumentFileTypes = 'ManageDocumentFileTypes',
    ViewContractSummary = 'ViewContractSummary',
    DeleteAgentDebt = 'DeleteAgentDebt',
    ViewAgentDebt = 'ViewAgentDebt',
    ViewAgentDebtReport = 'ViewAgentDebtReport',
    ImportAgentDebt = 'ImportAgentDebt',
    EditContractAdvanceOption = 'EditContractAdvanceOption',
    EditMaxAdvanceAmount = 'EditMaxAdvanceAmount',
    ViewContractAdvanceOption = 'ViewContractAdvanceOption',
    ViewMaxAdvanceAmount = 'ViewMaxAdvanceAmount',
    ViewCarrierContractNumberDefaults = 'ViewCarrierContractNumberDefaults',
    DeleteCarrierContractNumberDefaults = 'DeleteCarrierContractNumberDefaults',
    EditCarrierContractNumberDefaults = 'EditCarrierContractNumberDefaults',
    ViewContractPersistency = 'ViewContractPersistency',
    EditContractPersistency = 'EditContractPersistency',
    ImportContractPersistency = 'ImportContractPersistency',
    ViewSalesOpsAgentLeads = 'ViewSalesOpsAgentLeads',
    ViewFlaggedDocument = 'ViewFlaggedDocument',
    LookupAgentByLastName = 'LookupAgentByLastName',
    LookupAgentByWritingNumber = 'LookupAgentByWritingNumber',
    ViewCarrier = 'ViewCarrier',
    ViewContractPersistencyTypes = 'ViewContractPersistencyTypes',
    EditContractPersistencyTypes = 'EditContractPersistencyTypes',
    DeleteContractPersistencyTypes = 'DeleteContractPersistencyTypes',
    ViewContractPersistencyTypeColoringRules = 'ViewContractPersistencyTypeColoringRules',
    EditContractPersistencyTypeColoringRules = 'EditContractPersistencyTypeColoringRules',
    DeleteContractPersistencyTypeColoringRules = 'DeleteContractPersistencyTypeColoringRules',
    DeleteContractPersistency = 'DeleteContractPersistency',
    DeleteAgentDebtImports = 'DeleteAgentDebtImports',
    ViewAgentDebtImports = 'ViewAgentDebtImports',
    EditAgentDebtImports = 'EditAgentDebtImports',
    EditAgentProfilePicture = 'EditAgentProfilePicture',
    ViewAgentProfilePicture = 'ViewAgentProfilePicture',
    EditFederallyFacilitatedMarketplaceUserId = 'EditFederallyFacilitatedMarketplaceUserId',
    StealAnyWorkflowContractInvite = 'StealAnyWorkflowContractInvite',
    StealAnyWorkflowMarketerChange = 'StealAnyWorkflowMarketerChange',
    ManageMarketingMailConfiguration = 'ManageMarketingMailConfiguration',
    ViewAgentEmail = 'ViewAgentEmail',
    DeleteCreditCheck = 'DeleteCreditCheck',
    EditMyTeamsAgentNote = 'EditMyTeamsAgentNote',
    DeleteMyTeamsAgentNote = 'DeleteMyTeamsAgentNote',
    MergeStatementDivision = 'MergeStatementDivision',
    EditProspectiveAgentMarketer = 'EditProspectiveAgentMarketer',
    ViewProspectiveAgentMarketer = 'ViewProspectiveAgentMarketer',
    RemoveFuneralHomeCommissions = 'RemoveFuneralHomeCommissions',
    PrintIMBMailShippingSlip = 'PrintIMBMailShippingSlip',
    EditReceivePaperStatement = 'EditReceivePaperStatement',
    ViewReceivePaperStatement = 'ViewReceivePaperStatement',
    AddAddressLine = 'AddAddressLine',
    AddPhoneNumber = 'AddPhoneNumber',
    EditAddressLine = 'EditAddressLine',
    EditPhoneNumber = 'EditPhoneNumber',
    ViewAddressLine = 'ViewAddressLine',
    ViewPhoneNumber = 'ViewPhoneNumber',
    HasWorkFlowNotes = 'HasWorkFlowNotes',
    ViewStateLicense = 'ViewStateLicense',
    ViewCarrierGroup = 'ViewCarrierGroup',
    ViewRulesetDashboard = 'ViewRulesetDashboard',
    DisableLeadCarrier = 'DisableLeadCarrier',
    ManageCarrierGroup = 'ManageCarrierGroup',
    EditIsIntegrityPartner = 'EditIsIntegrityPartner',
    ImportContracts = 'ImportContracts',
    EditSalesType = 'EditSalesType',
    ViewEditSalesTypeInContractingTypeNotes = 'ViewEditSalesTypeInContractingTypeNotes',
    ViewSalesType = 'ViewSalesType',
    EditShowOnPortal = 'EditShowOnPortal',
    ViewAgentGroup = 'ViewAgentGroup',
    AddAgentGroup = 'AddAgentGroup',
    EditAgentGroup = 'EditAgentGroup',
    DeleteAgentGroup = 'DeleteAgentGroup',
    ViewAgentGroupOnAgentEdit = 'ViewAgentGroupOnAgentEdit',
    ViewSubmissionSenderTypeDashboard = 'ViewSubmissionSenderTypeDashboard',
    AddSubmissionSenderType = 'AddSubmissionSenderType',
    EditSubmissionSenderType = 'EditSubmissionSenderType',
    DeleteSubmissionSenderType = 'DeleteSubmissionSenderType',
    AddSubmissionType = 'AddSubmissionType',
    EditSubmissionType = 'EditSubmissionType',
    ExportSubmissionTypeReport = 'ExportSubmissionTypeReport',
    ViewSubmissionTypeDashboard = 'ViewSubmissionTypeDashboard',
    DeleteSubmissionType = 'DeleteSubmissionType',
    AddRequirementRuleset = 'AddRequirementRuleset',
    EditRequirementRuleset = 'EditRequirementRuleset',
    DeleteRequirementRuleset = 'DeleteRequirementRuleset',
    ViewRequirementRulesetDashboard = 'ViewRequirementRulesetDashboard',
    TestRequirementRuleset = 'TestRequirementRuleset',
    ExportRequirementRulesetReport = 'ExportRequirementRulesetReport',
    AddAutoUncheckRuleset = 'AddAutoUncheckRuleset',
    EditAutoUncheckRuleset = 'EditAutoUncheckRuleset',
    DeleteAutoUncheckRuleset = 'DeleteAutoUncheckRuleset',
    ViewAutoUncheckRulesetDashboard = 'ViewAutoUncheckRulesetDashboard',
    AddRuleset = 'AddRuleset',
    TestRuleset = 'TestRuleset',
    ExportRulesetReport = 'ExportRulesetReport',
    EditRuleset = 'EditRuleset',
    DeleteRuleset = 'DeleteRuleset',
    ViewContractsInWorkflow = 'ViewContractsInWorkflow',
    ViewAppsInWorkflow = 'ViewAppsInWorkflow',
    ViewLivesImpacted = 'ViewLivesImpacted',
    ViewContractedAgents = 'ViewContractedAgents',
    ViewAppCountsByMonthYearOverYear = 'ViewAppCountsByMonthYearOverYear',
    ViewMyTasks = 'ViewMyTasks',
    ViewMarketingEvents = 'ViewMarketingEvents',
    ViewAnnouncements = 'ViewAnnouncements',
    ViewTopFiveCarriers = 'ViewTopFiveCarriers',
    ViewTopFiveAgents = 'ViewTopFiveAgents',
    ViewTopFiveProductTypes = 'ViewTopFiveProductTypes',
    DeleteAgentDocumentsForOthers = 'DeleteAgentDocumentsForOthers',
    ViewAskIntegrity = 'ViewAskIntegrity',
    ViewAuditTrail = 'ViewAuditTrail',
    EditStates = 'EditStates',
    DeleteStates = 'DeleteStates',
    //Added
    ViewProductionViaReconciledRevenue = 'ViewProductionViaReconciledRevenue',
    ViewW9 = 'ViewW-9',
    EditProcessedBy = 'EditProcessedBy',
    ViewMarketer = 'ViewMarketer',
    ViewManageImports = 'ViewManageImports',
    ViewManageColumnDefinitions = 'ViewManageColumnDefinitions',
    ViewSmsMessages = 'ViewSmsMessages',
    SendSmsMessages = 'SendSmsMessages',
    ViewContractedAgentList = 'ViewContractedAgentList',
    ViewAgentMessaging = 'ViewAgentMessaging',
    ViewTaskDescription = 'ViewTaskDescription',
    ViewTaskCreatedBy = 'ViewTaskCreatedBy',
    ViewTaskModifiedDate = 'ViewTaskModifiedDate',
    ViewTaskModifiedBy = 'ViewTaskModifiedBy',
    ViewNewRevenueFieldsInDashboard = 'ViewNewRevenueFieldsInDashboard',
    ViewNewRevenueFields = 'ViewNewRevenueFields',
    OverrideAddressValidation = 'OverrideAddressValidation',
    ViewSeniorMarketRating = 'ViewSeniorMarketRating (SMR )',
    ViewLifeAndHealthRating = 'ViewLifeAndHealthRating (LHR )',
    ViewTasksTabOnAgentScreen = 'ViewTasksTabOnAgentScreen',
    ViewSearchByMap = 'ViewSearchByMap',
    ViewTasks = 'ViewTasks',
    ViewWorkflow = 'ViewWorkflow',
    AddState = 'AddState',
    AddLocation = 'AddLocation',
    CreateDownlineAgencyUsers = 'CreateDownlineAgencyUsers',
    EditDownlineAgencyUsers = 'EditDownlineAgencyUsers',
    AddEditTeams = 'Add /EditTeams',
    EditAgentDocument = 'EditAgentDocument',
    ViewCarrierManagement = 'ViewCarrierManagement',
    EditCarrierNotes = 'EditCarrierNotes',
    EditCarrierWebsites = 'EditCarrierWebsites',
    ViewCustomReports = 'ViewCustomReports',
    EditSupplyOrderDate = 'EditSupplyOrderDate',
    EditReportingHierarchy = 'EditReportingHierarchy',
    UnsubscribePhone = 'UnsubscribePhone',
    UnsubscribeEmail = 'UnsubscribeEmail',
    ManageAlerts = 'ManageAlerts',
    ViewAlerts = 'ViewAlerts',
    DefaultSaveToNotes = 'DefaultSaveToNotes',
    BypassAppointmentCheck = 'BypassAppointmentCheck',
    BypassCertificationCheck = 'BypassCertificationCheck',
    ViewOnHoldAgentCommission = 'ViewOnHoldAgentCommission',
    ViewCommissionBreakDown = 'ViewCommissionBreak -Down',
    ViewContractingSentReport = 'ViewContractingSentReport',
    DeleteTask = 'DeleteTask',
    AddEditRevenueNote = 'AddEditRevenueNote',
    ViewTemplates = 'ViewTemplates',
    ActivateEmailTemplate = 'ActivateEmailTemplate',
    EditEmailTemplate = 'EditEmailTemplate',
    DownloadWorkflowRecords = 'DownloadWorkflowRecords',
    ViewReceivingPaperStatementReports = 'ViewReceivingPaperStatementReports',
    ViewNoCommissionEmailsReports = 'ViewNoCommissionEmailsReports',
    CreateNewCustomReports = 'CreateNewCustomReports',
    ManageAgentAgencyReportCustom = 'ManageAgent /AgencyReport (Custom )',
    ManageInsuredReportsCustom = 'ManageInsuredReports (Custom )',
    ManageNotesReportCustom = 'ManageNotesReport (Custom )',
    ManageTasksReportCustom = 'ManageTasksReport (Custom )',
    ManageStateLicensesReportCustom = 'ManageStateLicensesReport (Custom )',
    ManagePolicyReportCustom = 'ManagePolicyReport (Custom )',
    ManageAppointmentsAndCertificationsReportCustom = 'ManageAppointmentsAndCertificationsReport (Custom )',
    ManagePointsReportCustom = 'ManagePointsReport (Custom )',
    ViewW9Information = 'ViewW9Information',
    EditW9Information = 'EditW9Information',
    ManageWorkflowReportCustom = 'ManageWorkflowReport (Custom )',
    ViewContractNotes = 'ViewContractNotes',
    ViewCommissionScheduleDocumentType = 'ViewCommissionScheduleDocumentType',
    ViewInsuredNotes = 'ViewInsuredNotes',
    ViewInsuredPrivateNotes = 'ViewInsuredPrivateNotes',
    DeleteInsuredNotes = 'DeleteInsuredNotes',
    ViewInsuredPublicNotes = 'ViewInsuredPublicNotes',
    CanAddEditInsuredPublicNotes = 'Add /EditInsuredPublicNotes',
    AddEditInsuredPrivateNotes = 'Add /EditInsuredPrivateNotes',
    NavigateToAgentDashboardFromIntegrityCONNECT = 'NavigateToAgentDashboard',
    ViewReportingHierarchy = 'ViewReportingHierarchy',
    AddEditPolicyScreen = 'policies',
    AddEditStateLicensesScreen = 'statelicenses',
    AddEditCustomerScreen = 'customers',
    PublicCustomAttribute = 'ViewPublicCustomAttribute',
    PrivateCustomAttribute = 'ViewPrivateCustomAttribute',
    CreateOrEditCustomAttribute = 'CreateOrEditCustomAttribute',
    ManageCustomAttributeAccess = 'ManageCustomAttributeAccess',
    DeleteCustomAttributes = 'DeleteCustomAttributes',
    ManagePrivateAgentDocuments = 'ManagePrivateAgentDocuments',
    ViewPaidPercentage = 'ViewPaidPercentage',
    OverrideEmailValidation = 'OverrideEmailValidation',
    ViewEnhancedCommissions = 'ViewEnhancedCommissions',
    ViewMessagingTab = 'ViewMessagingTab',
    ViewRecentNotes = 'ViewRecentNotes',
    AddAgentCustomAttribute = 'agents/add',
    EditAgentCustomAttribute = 'agents/edit',
    AddEditTaskCustomAttribute = 'tasks',
    ViewBulkUpdates = 'ViewBulkUpdates',
    ManageContractBulkUpload = 'ManageContractBulkUpload',
    ViewReportQueue = 'ViewReportQueue',
    ViewEmailLogs = 'ViewEmailLogs',
    ViewTeams = 'ViewTeams',
    ViewRoles = 'ViewRoles',
    ViewRTSReview= 'ViewRTSReview',
    ViewBookOfBusiness = 'ViewBookOfBusiness',
    ImportBookOfBusiness = 'ImportBookOfBusiness',
    ExportBookOfBusiness = 'ExportBookOfBusiness',
    DeleteBookOfBusiness = 'DeleteBookOfBusiness'
}
