/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import DocumentStore from "../../store/DocumentStore";
import Utils from "../../infrastructure/Utils";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import InsuredStore from "../../store/InsuredStore";
import { CreateUpdateNoteModel } from "../../services/InsuredService";
import { Permission } from "../../infrastructure/enum/Permission";
import { routes } from "../../router";

export class CustomerTabNotesViewModel implements ViewModel {
  @action Load = async () => {
    // this.isLoading = false;
    // this.customerName = RouteParamDetails.customerName;
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @observable insuredId: number = 0;
  @observable customerName: string = "";
  @observable cmSearchInputValue: string = "";
  @observable isRefreshEnableCarrier: boolean = false;
  @observable isPinNote: boolean = false;
  @observable isPinNotes: boolean = false;

  @observable itemList = new Collection<any>();
  @observable searchInputValue: string = "";
  @observable selectedStatus: string | null = "";
  @observable selectedCarrierGroup: any;
  @observable isCarrierToast: boolean = false;
  @observable isNotesPreview: boolean = true;
  @observable isNotesPreviewTemp: boolean = true;
  @observable isNotesAdd: boolean = false;
  @observable toasterIcon: string | null = "";
  @observable toasterMessage: string | null = "";
  @observable message: string = "";
  @observable noteTitle: string = "";
  @observable notesSearch: string = "";
  @observable notesSaveBtnTxt: string =
    "This button is disabled because a title for your note must be entered.";
  @observable CarrierID: number = 0;
  @observable rowId: number = 0;
  @observable isEditNoteId: number = 0;
  @observable timer: any;
  @observable isInvalidMessage: boolean = false;
  @observable isNoteSave: boolean = false;
  @observable showToolTip: boolean = false;
  @observable isMaxLenghtExceed: boolean = false;
  @observable notesCount: number = 0;

  @observable
  public ErrorModel = new ErrorModel(new AddNoteValidator());
  @observable selectedRowData: any;
  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 50;
  @observable pageIndex: number = 0;

  @observable sortColumn: string = "createdDate";
  @observable isSortAscending: boolean = false;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable isPagination: string = "";
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 50;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;

  // @observable isShowCreateToastNotes: boolean = false;
  // @observable isShowDeleteToastNotes: boolean = false;
  // @observable isShowUpdatedToastNotes: boolean = false;
  // @observable isShowDiscardedToastNotes: boolean = false;

  @observable isShowEditToastNotes: string = "";
  @observable NotesTitleToaster: string = "";

  @observable isNotesDeleteDialogVisible: boolean = false;
  @observable isNotesDiscardDialogVisible: boolean = false;
  @observable sort: boolean = false;
  @observable selectedPrivacy: string = "1";
  @observable privacyList = [
    { label: "Public", value: "1" },
    { label: "Private", value: "2" },
  ];
  @observable FilteredDocumentList = new Collection<any>();
  @observable documentList = new Collection<any>();
  @observable selectedDocument: string = "";
  @observable selectedDocumentId: number = 0;

  @action load = async (id: number, name: string | undefined) => {
    if (
      !Utils.hasUserPermission(Permission.ViewInsuredPrivateNotes) &&
      !Utils.hasUserPermission(Permission.ViewInsuredPublicNotes)
    ) {
      this.isNotesPreview = false;
      this.isNotesAdd = false;
      this.isNoteSave = false;
      this.noteTitle = "";
      this.message = "";
    }
    if (!Utils.hasUserPermission(Permission.ViewInsuredNotes)) {
      routes.customerSummary.push({ customerId: "" + id });
    } else {
      this.insuredId = id;
      this.customerName = "" + name;
      if (this.insuredId > 0) {
        this.isNotesAdd = false;
        this.isNoteSave = false;
        this.noteTitle = "";
        this.message = "";

        this.resetToasters();
        (Utils.hasUserPermission(Permission.ViewInsuredPrivateNotes) ||
          Utils.hasUserPermission(Permission.ViewInsuredPublicNotes)) &&
          this.loadAllNotes(false);
      }
    }
  };

  @action searchIntervals = (text: string) => {
    this.cmSearchInputValue = "";
    this.cmSearchInputValue = text;
    this.isHideRefreshButton();
  };

  @action refresh = () => {
    this.cmSearchInputValue = "";
    this.isRefreshEnableCarrier = false;
    this.loadAllNotes(false);
  };

  @action isHideRefreshButton() {
    if (this.cmSearchInputValue != "") {
      this.isRefreshEnableCarrier = true;
    } else {
      this.isRefreshEnableCarrier = false;
    }
  }

  @action resetFilters = async () => {
    this.cmSearchInputValue = "";
  };

  @action resetToasters = () => {
    // this.isShowCreateToastNotes = false;
    // this.isShowUpdatedToastNotes = false;
    // this.isShowDiscardedToastNotes = false;
    // this.isShowDeleteToastNotes = false;
  };

  @action loadAllNotes = async (isSearchCase: boolean) => {
    try {
      this.isLoading = true;
      this.itemList.values = [];
      let privacyStatus;
      if (
        Utils.hasUserPermission(Permission.ViewInsuredPublicNotes) &&
        Utils.hasUserPermission(Permission.ViewInsuredPrivateNotes)
      ) {
        privacyStatus = null;
      } else if (
        Utils.hasUserPermission(Permission.ViewInsuredPublicNotes) &&
        !Utils.hasUserPermission(Permission.ViewInsuredPrivateNotes)
      ) {
        privacyStatus = false;
      } else if (
        !Utils.hasUserPermission(Permission.ViewInsuredPublicNotes) &&
        Utils.hasUserPermission(Permission.ViewInsuredPrivateNotes)
      ) {
        privacyStatus = true;
      }
      const response = await InsuredStore.getAllInsuredNotes(
        this.insuredId,
        privacyStatus,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.isSortAscending,
        this.cmSearchInputValue
      );
      if (response && response.data && response.data.length > 0) {
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.isPagination =
          this.totalRecords > 50 ? "" : "disable-cm-pagination";
        this.itemList.values = response.data;
        this.selectedRowData = this.itemList.values[0];
        if (isSearchCase && response.recordCount > 0) {
          this.isNotesPreview = this.isNotesPreviewTemp;
        }
        // this.resetToasters();
      } else {
        // this.resetToasters();
        this.isNotesPreview = false;
        this.isNotesAdd = false;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isNotesPreview = false;
      this.isPagination = "disable-cm-pagination";
      this.isLoading = false;
      this.isNotesAdd = false;
    }
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "action-report-menu-list-wrap"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "actions-ellipsis-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
          ? "actions-ellipsis-menu-active"
          : "actions-ellipsis-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "action-report-menu-list-wrap"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
            ? "actions-ellipsis-menuspan"
            : "actions-ellipsis-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".actions-ellipsis-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action onFieldChange = (value: any) => {
    // e.persist();
    this.cmSearchInputValue = value;
    if (
      this.cmSearchInputValue.length >= 3 ||
      this.cmSearchInputValue.length === 0
    ) {
      this.isHideRefreshButton();
      this.loadAllNotes(true);
    }
  };

  @action setMessage(value: string) {
    if (value && value.length > 0) {
      this.notesCount = value.replace(/<[^>]+>/g, "").length;
      this.isMaxLenghtExceed = this.notesCount > 5000;
      this.message = value;
    } else {
      this.notesSaveBtnTxt =
        "This button is disabled because a title for your note must be entered.";
      this.isMaxLenghtExceed = false;
        this.notesCount = 0;
        this.message = "";
    }
  }
  @action setNoteTitle(value: string) {
    if (value.length == 1 && value == " ") {
      this.showToolTip = true;
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        this.showToolTip = true;
        return false;
      } else {
        this.showToolTip = false;
        this.noteTitle = value;
        if (
          this.noteTitle &&
          this.noteTitle.length > 0 &&
          this.noteTitle.trim().split(/ +/).join(" ").length
        ) {
          this.isNoteSave = true;
        } else {
          this.isNoteSave = false;
        }
        return true;
      }
    }
  }
  @action handleDiscardNote = (value: boolean) => {
    this.isNotesDiscardDialogVisible = value;
  };
  @action handleAddNotes = () => {
    this.privacyList = [];
    if (
      Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      !Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [{ label: "Private", value: "2" }];
      this.selectedPrivacy = "2";
    } else if (
      !Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [{ label: "Public", value: "1" }];
      this.selectedPrivacy = "1";
    } else if (
      Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [
        { label: "Public", value: "1" },
        { label: "Private", value: "2" },
      ];
      this.selectedPrivacy = "1";
    }
    this.noteTitle = "";
    this.message = "";
    this.isNoteSave = false;
    this.isNotesPreview = true;
    this.isNotesPreviewTemp = true;
    this.isNotesAdd = !this.isNotesAdd;
    this.documentList.values = [];
  };

  @action onSort = (sortColumn: any) => {
    this.sortColumn = sortColumn.sortField;
    if (sortColumn.sortField === this.sortColumn)
      this.isSortAscending = !this.isSortAscending;
    else this.isSortAscending = true;
    this.setSortOrder();
    this.loadAllNotes(true);
    //this.sortItems(sortColumn.sortField, sortOrder);
  };
  @action handleSaveNotes = async () => {
    this.resetToasters();
    this.isNotesAdd = !this.isNotesAdd;
    if (this.isEditNoteId != 0) {
      this.updateNote(this.isEditNoteId);
    } else {
      localStorage.setItem("CMNotesEdit", "edit");
      this.resetToasters();
      let reqData: CreateUpdateNoteModel = {
        id: 0,
        insuredId: this.insuredId,
        title: this.noteTitle,
        note: this.message,
        private: this.selectedPrivacy == "1" ? false : true,
        documentAttachmentList: this.documentList.values || [],
      };
      this.isLoading = true;
      const response = await InsuredStore.addInsuredNote(reqData);
      Utils.showSuccessToaster("Note created.", 5000, 500);
      if (response && response) {
        this.loadAllNotes(false);
        this.isNotesAdd = false;
        this.isNoteSave = false;
        this.noteTitle = "";
        this.message = "";
        this.selectedRowData = undefined;

        // clearTimeout(this.timer);
        // this.timer = setTimeout(async () => {
        //   this.isShowCreateToastNotes = true;
        //   localStorage.removeItem("CMNotesEdit");
        // }, 300);
      }
      this.isLoading = false;
    }
  };

  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [50, 100, 150, 200] : [];
  }

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadAllNotes(false);
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action updateNote = async (id: number) => {
    this.isNotesAdd = !this.isNotesAdd;
    localStorage.setItem("CMNotesEdit", "edit");
    this.resetToasters();

    let reqData: CreateUpdateNoteModel = {
      id: this.isEditNoteId,
      insuredId: this.insuredId,
      title: this.noteTitle,
      note: this.message,
      private: this.selectedPrivacy == "1" ? false : true,
      documentAttachmentList: this.documentList.values || [],
    };
    try {
      this.isLoading = true;
      const response = await InsuredStore.updateInsuredNote(reqData);

      Utils.showSuccessToaster("Note updated.", 5000, 500);
      this.loadAllNotes(false);
      this.isNotesAdd = false;
      this.isNoteSave = false;
      this.noteTitle = "";
      this.message = "";
      this.selectedRowData = undefined;
      // clearTimeout(this.timer);
      // this.timer = setTimeout(async () => {
      //   this.isShowUpdatedToastNotes = true;
      //   localStorage.removeItem("CMNotesEdit");
      // }, 300);

      this.isLoading = false;
      this.isEditNoteId = 0;
      this.selectedRowData = this.itemList.values[0];
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action handleToggle = (e: any) => {
    e.persist();
    this.isNotesPreview = e.target.checked;
    this.isNotesPreviewTemp = e.target.checked;
    this.isNotesAdd = false;
  };

  @action DiscardNote = (value: boolean) => {
    localStorage.setItem("CMNotesDiscard", "discard");
    this.isNotesDiscardDialogVisible = value;
    this.isNotesAdd = false;
    this.isEditNoteId = 0;
    Utils.showSuccessToaster("Note discarded.", 5000, 500);
    this.loadAllNotes(false);
  };
  @action cancelNotesDelete = async (value: boolean) => {
    this.isNotesDeleteDialogVisible = value;
  };
  @action editNote = async (data: any) => {
    this.privacyList = [];
    if (
      Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      !Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [{ label: "Private", value: "2" }];
    } else if (
      !Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [{ label: "Public", value: "1" }];
    } else if (
      Utils.hasUserPermission(Permission.AddEditInsuredPrivateNotes) &&
      Utils.hasUserPermission(Permission.CanAddEditInsuredPublicNotes)
    ) {
      this.privacyList = [
        { label: "Public", value: "1" },
        { label: "Private", value: "2" },
      ];
    }
    this.isNoteSave = true;
    this.isEditNoteId = data.id;
    this.selectedRowData = undefined;
    this.isNotesAdd = true;
    this.isNotesPreview = true;
    this.isNotesPreviewTemp = true;
    this.noteTitle = data.title;
    this.message = data.note;
    this.documentList.values = data.documentAttachments;
    this.selectedPrivacy = data.private ? "2" : "1";
  };

  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @action downloadDocumentLink = async (docId: number, fileName: string) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : fileName,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e: any) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };

  @action privacyChange = (value: string) => {
    this.selectedPrivacy = value;
  };

  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getCustomerDocumentsOnSearch(
      this.insuredId || 0,
      this.selectedDocument || "",
      undefined,
      undefined,
      undefined,
      undefined
    );
    var noAgent: any = { id: 0 };
    if (result && result.recordCount && result.data) {
      let self = this;
      result.data =
        self.documentList.values &&
        result.data.filter((i) => {
          return (
            self.documentList.values.find(
              (j) =>
                j.documentName == i.fileName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }

    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, { id: -1 });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              //element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action onPinnedNoteClick = async () => {
    this.isPinNote = true;
  };
  @action checkIsPinned = () => {
    // if (
    //   !this.isPinNotes &&
    //   this.isPinNote &&
    //   sessionStorage.getItem("isPinned") &&
    //   sessionStorage.getItem("isPinned") == "pin"
    // )
    // this.isPingNoteConfirm = true;
  };

  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }
  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;
    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter((item) => {
        return item.rowId != rowId;
      });
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
  }

  @action setNotesDeleteDialogVisible = async (value: boolean) => {
    this.resetToasters();
    this.isNotesDeleteDialogVisible = value;
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action deleteNote = async () => {
    this.isNotesAdd = false;
    localStorage.setItem("CMNotesDelete", "delete");
    this.resetToasters();
    try {
      this.isLoading = true;
      const response = await InsuredStore.deleteInsuredNote(
        this.selectedRowData.id
      );
      Utils.showSuccessToaster("Note deleted.", 5000, 500);
      this.isNotesAdd = false;
      this.isNoteSave = false;
      this.isNotesDeleteDialogVisible = false;
      this.rowId = 0;
      this.noteTitle = "";
      this.message = "";
      this.selectedRowData = undefined;
      this.loadAllNotes(false);
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };
}
class AddNoteValidator extends AbstractValidator<any> {
  public constructor() {
    super();

    this.validateIfString((input) => input.message)
      .isNotEmpty()
      .withFailureMessage("Message is required.");
  }
}
