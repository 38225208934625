import { getToken } from "../infrastructure/Auth0Authenticator";
import { action } from "mobx";
import UserContext from "../infrastructure/UserContext";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import ServiceClient from "../infrastructure/client/ServiceClient";
import {
  Client as NotificationClient,
  NotificationTemplateModel,
} from "../services/NotificationService";
class NotificationStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): NotificationClient => {
    return ServiceClient.getClient(ServiceType.Notification);
  };

  @action getEmailTemplates = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string,
    statusId: number
  ) => {
    const result = await this.getClient().getAllNotification(
      this.getApiVersion(),
      statusId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getEmailDetailsById = async (emailId: number) => {
    var result = await this.getClient().getNotificationById(
      emailId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action updateNotification =async(body:any) =>{
    var result = await this.getClient().updateNotification(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  }
  
  @action updateNotificationStatus =async(body:any) =>{
    var result = await this.getClient().updateNotificationStatus(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  }


  @action addEmail = async (
    agentId: number,
    createUpdateTaskModel: NotificationTemplateModel
  ) => {
    var result = await this.getClient().addNotification(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      createUpdateTaskModel
    );
    return result;
  };
  @action editEmail = async (
    createUpdateTaskModel: NotificationTemplateModel
  ) => {
    var result = await this.getClient().updateNotification(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      createUpdateTaskModel
    );
    return result;
  };

  @action deleteEmail = async (emailId: string) => {
    var result = await this.getClient().deleteNotification(
      this.getApiVersion(),
      emailId,
      UserContext.getUserId()
    );
  return result;
  };

    @action sendTestEmail = async (toAddress: string, id: number) => {
        var result = await this.getClient().sendTestEmail(
            this.getApiVersion(),
            toAddress,
            id,
            UserContext.getUserId()
        );
        return result;
    };    
}
export default new NotificationStore();
