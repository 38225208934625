import { observable, action } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../infrastructure/Utils";
import ContractsStore from "../../store/ContractsStore";
import Collection from "../../infrastructure/CollectionHelper";
import AccountStore from "../../store/AccountingStore";
import { AgentAccountViewModel } from "./AgentAccountViewModel";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { Permission } from '../../infrastructure/enum/Permission';

export class AddAgentDebtViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  public ErrorModel = new ErrorModel(new AddDebtValidator());
  @action Load = async (id: number, debtId: number | undefined) => {
    this.agentId = id;

    this.resetAddFields();
    await this.ResetValidate();
    await this.loadCompanies();

    if (debtId && Utils.hasUserPermission(Permission.EditAgentDebt)) {
      this.title = "Edit Agent Debt";
      this.debtId = debtId;
      this.loadDebtValues();
    } else {
      this.title = "Add Agent Debt";
      this.debtId = 0;
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable agentId: number = 0;
  @observable writingNumber: string = "";
  @observable unSecured: string = "0";
  @observable reserve: string = "0";
  @observable sideDebt: string = "0";
  @observable rollUp: string = "0";
  @observable producerWeek: string = "0";
  @observable uplineWeek: string = "0";
  @observable advanced: string = "0";
  @observable debtId: number = 0;
  @observable total: number = 0;
  @observable title: string = "Add Agent Debt";
  
  @observable isZeroedOut: boolean = false;
  @observable advance: string = "0";

  @observable companies = new Collection<{
    label: string;
    value: string;
  }>();
  @observable
  AgentAccountViewModel: AgentAccountViewModel = new AgentAccountViewModel();
  @action writingNumberChange = (value: string) => {
    this.writingNumber = value;
  };
  @action setUpline = (value: string) => {
    this.uplineWeek = value;
  };
  @action setProducer = (value: string) => {
    this.producerWeek = value;
  };
  @action setUnsecured = (value: string) => {
    this.unSecured = value;
  };
  @action setReserve = (value: string) => {
    this.reserve = value;
  };
  @action setSideDebt = (value: string) => {
    this.sideDebt = value;
  };
  @action setRollup = (value: string) => {
    this.rollUp = value;
  };
  @action setAdvanced = (value: string) => {
    this.advanced = value;
  };

  @action setAdvance = (value: string) => {
    this.advance = value;
  };

  @observable selectedCompany: string = "";


  @action resetAddFields() {
    this.unSecured = "0";
    this.uplineWeek = "0";
    this.writingNumber = "";
    this.sideDebt = "0";
    this.rollUp = "0";
    this.reserve = "0";
    this.producerWeek = "0";
    this.advanced = "0";   
    this.endDate = new Date();
    this.selectedCompany = "";
    this.debtId = 0;
    this.isZeroedOut = false;
  }

  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };

  @observable endDate: Date = new Date();
  @action setSelectDate(value: Date) {
    this.endDate = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }

  @action loadDebtValues = async () => {
    
    try {
      let result = await AccountStore.getDebtById(this.debtId);
      if (result) {
        this.fillValues(result);
      }      
    } catch (e) {      
    }
  };

  @action fillValues(response: any) {
    this.advanced = response.advanced;
    this.advance = response.advanced;
    let temp = response.carrierId.toString();
    this.selectedCompany = temp;
    this.debtId = response.id;
    this.producerWeek = response.producer;
    this.endDate = new Date(response.reportDate);
    this.reserve = response.reserve;
    this.rollUp = response.rollUp;
    this.sideDebt = response.side;
    this.total = response.total;
    this.unSecured = response.unSecured;
    this.uplineWeek = response.upline;
    this.writingNumber = response.writingNumber;
  }
  @action
  loadCompanies = async () => {
   
    var result = await ContractsStore.getAllCompanyLookup();
    if (result !== null) {
      this.companies.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };

          this.companies.values.push(data);
        });
      this.selectedCompany = this.companies.values[0].value.toString();
    } else {
      this.companies.values = [];
      this.selectedCompany = "";
    }
  };

  @action formatNum = (val: string): number => {
    let num;
    if (val) {
      val = val.toString().replace("$", "").split(",").join("");
      num = !isNaN(Number(val)) ? Number(val.split(",").join("")) : 0;
    } else num = 0;

    return num;
  };

  @action onSubmit = async () => {
   
    await this.ResetValidate();
    if (!(await this.Validate())) {
      let body = {
        id: this.debtId !== 0 ? this.debtId : 0,
        agentId: this.agentId,
        carrierId: Number(this.selectedCompany),
        writingNumber: this.writingNumber,
        reportDate: Utils.getDateinyymmdd(this.endDate),
        advanced: this.formatNum(this.advanced),
        unSecured: this.formatNum(this.unSecured),
        reserve: this.formatNum(this.reserve),
        side: this.formatNum(this.sideDebt),
        total: this.total,
        rollUp: this.formatNum(this.rollUp),
        producer: this.formatNum(this.producerWeek),
        upline: this.formatNum(this.uplineWeek),
      };
      try {
        if (this.debtId && this.debtId !== 0) {
          await AccountStore.editAgentDebt(body);
        } else {
          await AccountStore.addAgentDebt(body);
        }

             
       
          routes.agentAccount.replace({
            agentId: "" + RouteParamDetails.id           
          });
      
      } catch (e) {
        
      }
    } 
  };
  @action goBack = () => {
    routes.agentAccount.replace({
      agentId: "" + RouteParamDetails.id      
    });
  };
}

class AddDebtValidator extends AbstractValidator<AddAgentDebtViewModel> {
  public constructor() {
    super();

    this.validateIfString((input) => input.selectedCompany)
      .isNotEmpty()
      .withFailureMessage("Carrier is required");

    this.validateIfString((input) => input.writingNumber)
      .isNotEmpty()
      .withFailureMessage("Writing number is required");

    this.validateIfString((input) => input.advanced)
      .isNotEmpty()
      .withFailureMessage("Advanced is required");

    this.validateIfString((input) => input.unSecured)
      .isNotEmpty()
      .withFailureMessage("Unsecured is required");

    this.validateIfString((input) => input.reserve)
      .isNotEmpty()
      .withFailureMessage("Reserve is required");

    this.validateIfString((input) => input.sideDebt)
      .isNotEmpty()
      .withFailureMessage("Side Debt is required");

    this.validateIfString((input) => input.rollUp)
      .isNotEmpty()
      .withFailureMessage("Rollup is required");

    this.validateIfString((input) => input.producerWeek)
      .isNotEmpty()
      .withFailureMessage("Producer weeks is required");

    this.validateIfString((input) => input.uplineWeek)
      .isNotEmpty()
      .withFailureMessage("Upline weeks is required");
  }
}
