import { observable, action } from "mobx";
import ContractsStore from "../../store/ContractsStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { UpdateAgentListener } from "./AgentSummaryViewModel";
import {
  ContractGridColumnRequest,
  ContractListModel,
  ContractRequestModel,
  CreateUpdateContractModel,
} from "../../services/ContractService";
import PageContext from "../../infrastructure/PageContext";
import { AgentContractDetails } from "../../infrastructure/enum/AgentDetails";
import { Permission } from "../../infrastructure/enum/Permission";
import AccountStore from "../../store/AccountingStore";
import {
  PaymentPlanListModel,
  PaymentPlanUsageListModel,
} from "../../services/AccountingService";
import TaskStore from "../../store/TaskStore";
import UserStore from "../../store/IdentityStore";
import { CreateUpdateTaskModel } from "../../services/TaskService";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import InsuredStore from "../../store/InsuredStore";
import ProducerStore from "../../store/ProducerStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import DocumentStore from "../../store/DocumentStore";
import { googleTagManager } from "../../infrastructure/tracking";
import { Constants } from "../../infrastructure/enum/Constants";
import { ContractAddEditPaymentPlanViewModel } from "./contracts/ContractAddEditPaymentPlanViewModel";

export class AgentLicensesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable gridApi: any;
  @observable gridApiLoadingRows: string = "";
  @observable gridApiNoRows: string = "<span></span>";
  @observable gridApiData: any;
  @observable gridContainerClass: any = "";
  @observable isCreateTaskSaveDisabled: boolean = false;
  @observable agentsSearchList: number = 0;
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable selectedDocument: string = "";
  @observable FilteredDocumentList = new Collection<any>();
  @observable createTaskModalVisibility: boolean = false;
  @observable emptyTaskTitle: boolean = false;
  @observable taskNoteTitle: string = "";
  @observable selectedUserNoteTask: string | undefined = "0";
  @observable selectedAgentId: number | undefined = 0;
  @observable selectedNoteTaskPriority: string = "";
  @observable taskRichtextValue: any = "";
  @observable characterCount: number = 0;
  @observable taskNoteCarrierID: any = null;
  @observable noteTaskDate: Date = new Date();
  @observable isSaveEnable: boolean = true;
  @observable taskWatchersTooltip: string =
    "Multiple email addresses should be separated using a semi-colon.";
  @observable documentTooltip: string = "Select an agent to attach a document";
  @observable selectedCompany: string = "";
  @observable documentList = new Collection<any>();
  @observable selectedAgent: string = "";
  @observable agentIdCheck: number = 0;
  @observable selectedCustomer: string = "";
  @observable selectedCustomerID: string = "0";
  @observable customersSearchList: number = 0;
  @observable FilteredCustomersList =
    new Collection<CustomerLookupListModelTask>();
  @observable isMaxLenghtExceed: boolean = false;
  @observable notesCount: number = 0;
  @observable replaceConfirm: string = "1";
  @observable replaceDownline: boolean = true;
  @observable replaceModalVisibility: boolean = false;
  @observable message: string = "";
  @observable setColumnDefsCustom: any;
  @observable setColumnDefsColumns: any;
  @observable isResetColumnsEnable: boolean = false;
  @observable replaceList = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];
  @action setMessage = (value: string) => {
    if (value && value.length > 0) {
      this.characterCount = value.replace(/<[^>]+>/g, "").length;
      this.taskRichtextValue = value;
      this.isMaxLenghtExceed = this.characterCount > 500;
    } else {
      this.isMaxLenghtExceed = false;
      this.characterCount = 0;
      this.taskRichtextValue = "";
    }
    this.checkCreateTaskSaveEnabled();
  };

  @observable assignToNoteTask = new Collection<{
    label: string;
    value: string;
    isHeader?: boolean | undefined;
  }>();

  @observable noteTaskPriority = new Collection<{
    label: string;
    value: string;
  }>();

  @observable jsonTest: any;
  @observable isScrollVisible: boolean = false;

  @observable selectedCarriers: any = [];
  @observable carriersList = new Collection<{
    label: string;
    value: string;
  }>();    
  @observable activeCarriers = new Collection<{
    label: string;
    value: string;
  }>();

  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
  };

  @action setSelectedAgentName = async () => {
    try {
      this.isLoading = true;
      if (this.agentId != null && this.agentId != undefined) {
        var agentInfo = await ProducerStore.getAgentProfileInfoById(
          this.agentId
        );
        if (agentInfo && agentInfo.name) {
          let nameArray = agentInfo.name.split(" ");
          if (nameArray.length > 1) {
            this.selectedAgent = nameArray[1] + ", " + nameArray[0];
            this.selectedAgentId = agentInfo.id;
          } else {
            this.selectedAgent = nameArray[0];
            this.selectedAgentId = 0;
          }
        }
      }
      this.isLoading = false;
    } catch (e) { }
  };
  @action setSelectedPanel = async (value: string) => {
    console.log(value);
    this.selectedPanel = value;
    if (value === AgentContractDetails.Contracts) {
      this.ContractList.values = [];
      this.gridApiData = [];
      Utils.setContractSubTab(0);
      this.getDatasource();
    } else {
      Utils.setContractSubTab(1);
      await this.loadCompany();
      this.loadPaymentPlans();
    }
  };
  @action searchAgents = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        this.selectedAgent = "";
        this.selectedAgentId = 0;
      }
    } else {
      this.agentsSearchList = 0;
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
      this.selectedAgentId = 0;
    }
    this.isLoading = false;
    setTimeout(() => { }, 500);
  };
  @action setSelectedCusomer(value: string) {
    if (typeof value == "string") {
      this.selectedCustomer = value;
    }
  }
  @action setSelectedCusomerID(value: string) {
    this.selectedCustomerID = value;
  }
  @action searchCustomers = async (value: string) => {
    IMASLog.log("search customers ..");
    var noCustomer: CustomerLookupListModelTask = { id: 0 };
    var searchTypeVal = 1;
    let isnum = /^\d+$/.test(value);
    if (isnum) {
      searchTypeVal = 6;
    }

    let result = await InsuredStore.getCustomerList(
      searchTypeVal,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        this.customersSearchList = result.recordCount;
        if (result.data) {
          result.data.splice(0, 0, {
            id: -1,
          });
          this.FilteredCustomersList.values = result.data;
        }
      } else {
        this.customersSearchList = 0;
        this.FilteredCustomersList.values = [noCustomer];
        this.selectedCustomer = "";
      }
    } else {
      this.customersSearchList = 0;
      this.FilteredCustomersList.values = [noCustomer];
      this.selectedCustomer = "";
    }
    this.isLoading = false;
    setTimeout(() => { }, 500);
  };
  @action setSelectedAgent(value: string) {
    if (typeof value == "string") {
      this.selectedAgent = value;
    }
  }
  @action settaskTitle = (value: any) => {
    if (value.length == 1 && value == " ") {
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        return false;
      } else {
        this.taskNoteTitle = value;
        return true;
      }
    }
  };
  @action setReplaceModalVisibility = (value: boolean) => {
    this.replaceModalVisibility = value;
    this.isDownlineReplace = value;
  };

  @action setCreateTaskModalVisibility = (value: boolean) => {
    this.createTaskModalVisibility = value;
    this.getImpersonatingUserId();
    this.noteTaskPriority.values = [];
    this.noteTaskPriority.values.push(
      { value: "1", label: "Low" },
      { value: "2", label: "Medium" },
      { value: "3", label: "High" }
    );
    this.selectedNoteTaskPriority = "1";
    this.emptyTaskTitle = false;
    this.noteTaskDate = new Date();
  };
  @action validateField = () => {
    this.emptyTaskTitle = this.taskNoteTitle.length < 1;
  };
  @action userNoteTaskChange = async (value: string) => {
    this.selectedUserNoteTask = value;
  };
  @observable companyList = [{ label: "Select", value: "0" }];

  @action resetTaskFields = async () => {
    this.taskNoteTitle = "";
    this.selectedNoteTaskPriority = "1";
    this.noteTaskDate = new Date();
    this.selectedCustomer = "";
    this.taskRichtextValue = "";
    this.characterCount = 0;
    this.isMaxLenghtExceed = false;
    this.documentList.values = [];
  };
  @action loadCompanies = async (carrier: any) => {
    try {
      this.isLoading = true;
      var carriers = await ContractsStore.getAllCompanyLookup();
      if (carriers) {
        var defaultItem = { label: "Select", value: "0" };
        this.companyList = this.mapListItemAndSort(carriers);
        this.companyList.splice(0, 0, defaultItem);

        var tempVar = this.companyList.filter((item: any) => {
          return item.text == carrier;
        });
        this.selectedCompany = tempVar[0].value;
      }
      this.isLoading = false;
    } catch (e) { }
  };

  @action loadTitileOnCreateTask = async (rowData: any) => {
    if (rowData != null) {
      this.taskNoteTitle = rowData.carrier + ", " + rowData.statusReason;
    }
    setTimeout(async () => {
      this.checkCreateTaskSaveEnabled();
    }, 3000);
  };
  @action checkCreateTaskSaveEnabled = () => {
    this.taskNoteTitle != "" &&
      this.selectedCompany != "" &&
      this.selectedCompany != "0" &&
      this.selectedAgentId != 0 &&
      !this.isMaxLenghtExceed
      ? (this.isCreateTaskSaveDisabled = false)
      : (this.isCreateTaskSaveDisabled = true);
  };

  @action getImpersonatingUserId = async () => {
    try {
      var result = await UserStore.getImpersonateUserList();
      if (result) {
        Utils.mapListItemsToDropdown(result, this.assignToNoteTask, "");
        var selectedUser = result.filter((item: any) => {
          return item.selected === true;
        });
        if (selectedUser && selectedUser.length > 0) {
          this.selectedUserNoteTask = selectedUser[0].value;
        }
      }
    } catch (e) { }
  };
  @action saveTask = async () => {
    this.isLoading = true;
    try {
      var taskdto: CreateUpdateTaskModel = {};
      taskdto.id = 0;
      taskdto.userId = Number(this.selectedUserNoteTask);
      taskdto.agentId = this.agentId ? this.agentId : 0;
      taskdto.companyId =
        this.selectedCompany == null ? undefined : Number(this.selectedCompany);
      taskdto.orderId = 0;
      taskdto.task = this.taskNoteTitle;
      taskdto.description = this.taskRichtextValue;
      taskdto.complete = false;
      taskdto.taskDate = this.noteTaskDate;
      taskdto.priority = parseInt(this.selectedNoteTaskPriority);
      taskdto.customerId =
        this.selectedCustomer == null
          ? undefined
          : Number(this.selectedCustomerID);
      taskdto.documentAttachmentList = this.documentList.values || null;
      await TaskStore.addTask(taskdto.agentId, taskdto);
      this.isLoading = false;
      this.createTaskModalVisibility = false;
      Utils.showSuccessToaster(
        "'" + this.taskNoteTitle + "' task created.",
        3000
      );
    } catch (error) {
      this.isLoading = false;
    }
  };

  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }

  @action resetColumns = async () => {
    this.setColumnDefsCustom = "";
    this.setColumnDefsColumns = "";
    this.isResetColumnsEnable = false;
    this.isRefreshButtonClick = true;
    this.chacheRows = 10;
    this.rows = 10;
    this.startIndex =0;
    await this.updateColumnOrder();
    await this.loadContracts();
    Utils.showSuccessToaster("Columns have been reset successfully.", 3000);
   
  };

  @observable ContractDetailsTabItems: TabItems[] = [];

  @action getContractDetailsTabItems = () => {
    this.ContractDetailsTabItems = [];
    let a = { label: AgentContractDetails.Contracts, value: 0 };
    let b = { label: AgentContractDetails.PaymentPlans, value: 1 };
    Utils.hasUserPermission(Permission.ViewContractTabOnAgentScreen) &&
      this.ContractDetailsTabItems.push(a);
    Utils.hasUserPermission(Permission.ViewContractTabOnAgentScreen) &&
      this.ContractDetailsTabItems.push(b);
  };
  @action Load = async (
    id: number,
    marketerId: number | undefined,
    divisonId: number | undefined
  ) => {
    this.startIndex = 0;
    this.rows = 10;
    localStorage.setItem("editMainScreenPaymentPlan", "");

    this.showInactiveCarriers = false;
    Utils.setContractSubTab(0);
    this.agentId = id;
    this.getContractDetailsTabItems();
    let openTab = Utils.getContractSubTab();
    this.ContractList.values = [];
    this.gridApiData = [];
    if (openTab === AgentContractDetails.Contracts || openTab === "") {
      this.selectedTabIndex = 0;
      this.selectedPanel = AgentContractDetails.Contracts;
      this.marketerId = marketerId;
      this.divisonId = divisonId;
      this.sortColumnContract = "carrier"; //as per AMS-2436
      this.sortContract = true;
      this.isMessgeVisible = false;
      this.rows = this.chacheRows || 10;

      this.loadAllLookupList(0, this.agentId);
      if (this.dnxListener) {
        this.dnxListener.updateDnxOptions();
      }
    } else {
      this.selectedTabIndex = 1;
      this.selectedPanel = AgentContractDetails.PaymentPlans;
      // bind for payment plan
      await this.loadPaymentPlans();
    }

    if (
      this.searchedAgentId &&
      localStorage.getItem(Constants.currentAgentId) &&
      localStorage.getItem(Constants.currentAgentId) !== this.searchedAgentId
    ) {
      this.searchedAgentId = this.agentId + "";
      this.resetFilters();
    } else {
      this.searchedAgentId = this.agentId + "";
      await this.loadCarriers();
      this.getDatasource();
    }

    window.addEventListener("scroll", this.listenScrollEvent);
    this.setColumnDefsCustom =
      await ContractsStore.getContractGridColumnOrder();
    if (this.setColumnDefsCustom) {
      this.isResetColumnsEnable = true;
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };
  @observable rowStyle: string = "";
  @observable selectedTabIndex?: number = 0;
  @observable selectedPanel: string = AgentContractDetails.Contracts;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  @observable rows: number = 10;
  @observable chacheRows: number = 10;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable searchedAgentId: string = "";
  @observable marketerId: number | undefined;
  @observable divisonId: number | undefined;
  @observable ContractList = new Collection<ContractListModel>();
  @observable name: string = "";
  @observable isLoading: boolean = true;
  @observable id: string = "";
  @observable startIndex: number = 0;
  @observable startIndexPrevious: boolean = true;
  @observable isDeleteConfirm: boolean = false;
  @observable isReplaceContract: boolean = false;
  @observable isDownlineReplace: boolean = false;
  @observable serachInputValue: string = "";
  @observable downlineReplace: string = "";
  @observable invalidReplaceInput: boolean = false;
  @observable serachLicenseNumber: string = "";
  @observable searchParam: string | undefined = "";
  @observable policyMenu = [
    { label: "Edit", command: (event: any) => { } },
    { label: "Delete", command: (event: any) => { } },
    { label: "Replace", command: (event: any) => { } },
  ];
  @observable contractId: number = 0;
  @observable deleteContractContext: any =
    "Are you sure you want to delete this contract?";
  @observable showContextualMenu: boolean = false;
  @observable defaultIsSortAscending: boolean = true;
  @observable sortContract: boolean = true;
  @observable sortColumnContract: string = "carrier"; //as per AMS-2436
  @observable isAgentDebt: boolean = false;
  @observable isAgentOwesLevel: boolean = false;
  @observable agentDebtLevel: string = "";
  @observable agentDebtDescription: string = "";
  @observable isRefreshButtonClick: boolean = false;
  @observable isRefreshEnable: boolean = false;

  @observable PaymentPlansList = new Collection<PaymentPlanListModel>();
  @observable paymentPlanTitle: string = "Edit Payment plan";
  @observable isPaymentPlanVisible: boolean = false;
  @observable ContractAddEditPaymentPlanViewModel =
    new ContractAddEditPaymentPlanViewModel();
  @action setPaymentPlan = (value: boolean) => {
    this.isPaymentPlanVisible = value;
  };

  listener: UpdateAgentListener | undefined;
  setListener(listener: UpdateAgentListener) {
    this.listener = listener;
  }
  @action setSortOrder() {
    this.sortContract = !this.sortContract;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumnContract = column;
  }

  @action
  setDownlineReplace(value: string) {
    this.downlineReplace = value;
  }
  @action
  setShowContextualMenu(visible: boolean) {
    this.showContextualMenu = visible;
  }
  @action setSelectedPolicy = (value: number) => {
    this.contractId = value;
  };
  @observable selectedLicense: ContractListModel | null = null;
  @observable
  status = new Collection<{
    key: number;
    label: string;
    value: string;
  }>();

  @observable selectedStatus: string = "0";
  @observable selectedStatusTemp: string = "1";
  @observable agGridRowData: any;
  @action updateColumnOrder = async () => {
    var columnOrders: ContractGridColumnRequest = {
      gridColumnOrder: JSON.stringify(this.setColumnDefsColumns),
    };
    await ContractsStore.updateContractGridColumnOrder(columnOrders);
  };
  @action loadAllLookupList = async (contractId: number, agentId: number) => {
    var data = [
      { text: "Active & Pending", value: "1" },
      { text: "Pre-Contracting", value: "4" },
      { text: "Terminated", value: "2" },
    ];
    this.loadstatusList(data);
  };
  @action loadstatusList = async (data?: any[]) => {
    this.status.values = [];
    if (data != null) {
      let key = 1;
      data.forEach((obj) => {
        var dataO = {
          key: key,
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value : "",
        };
        key++;
        this.status.values.push(dataO);
      });
    }
    this.status.values.splice(0, 0, { key: 0, label: "All", value: "0" });
    // this.selectedStatus = "1";
    this.selectedStatus = this.selectedStatusTemp;
  };
  @action loadContracts = async () => {
    try {
      //this.isLoading = true;
      if (this.searchParam) {
        if (isNaN(Date.parse(this.searchParam))) {
          this.searchParam = this.searchParam.replace("/", "$");
        }
      }

      var model: ContractRequestModel = {
        agentId: this.agentId,
        policyStatusId: +this.selectedStatus,
        searchParameter: "" + this.searchParam,
        marketerId: this.marketerId,
        divisionId: this.divisonId,
        carrierIds: this.selectedCarriers,
        pageIndex: this.startIndex,
        pageSize: this.rows,
        sortColumn: this.sortColumnContract,
        sortAscending: this.sortContract,
      };

      var agentContracts = await ContractsStore.getContracts(model);
      this.isAgentDebt = false;
      this.isAgentOwesLevel = false;

      if (agentContracts) {
        let debtId = agentContracts.agentDebtId;
        if (debtId) {
          this.isAgentDebt = debtId > 1 ? true : false;
        }
        let debtLevelDetails = agentContracts.agentDebtLevelDetail;
        if (debtLevelDetails && debtLevelDetails.id) {
          this.isAgentOwesLevel = debtLevelDetails.id > 1 ? true : false;
          this.agentDebtLevel =
            "Agent Owes: " +
            (debtLevelDetails.debtLevelName
              ? debtLevelDetails.debtLevelName
              : "");
          this.agentDebtDescription = debtLevelDetails.description
            ? debtLevelDetails.description
            : "";
          if (this.isAgentOwesLevel && this.listener) {
            this.listener.updateAgentDebtInfo(
              this.isAgentOwesLevel,
              this.agentDebtLevel,
              this.agentDebtDescription
            );
          }
        }
      }

      if (agentContracts.data) {
        this.totalRecords = agentContracts.recordCount
          ? agentContracts.recordCount
          : 0;
        this.ContractList.values = [];
        this.ContractList.values = agentContracts.data;
        this.gridContainerClass = "";
        this.mapRowData(this.ContractList.values);
        setTimeout(() => {
          let node =
            this.gridApi &&
            this.gridApi.getDisplayedRowAtIndex(this.rowIndexToRetainHighlight);
          node && node.setSelected(true);
          this.gridContainerClass = "ag-grid-container";
          this.update();
        }, 200);
      } else {
        this.ContractList.values = [];
        this.gridApiData = [];
      }
      //this.isLoading = false;
    } catch (e: any) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };

  @action mapRowData(rowData: any) {
    this.gridApiData = [];
    this.gridApiData = rowData.map((object: any) => {
      return {
        id: object.id,
        carrier: object.carrier,
        status: object.status || " - ",
        statusReason: object.statusReason || " - ",
        uplines:
          object.uplines && object.uplines.length > 0 ? object.uplines : "",
        toh: object.toh || " - ",
        startDate: object.startDate
          ? Utils.getDateInFormat(new Date(object.startDate))
          : " - ",
        endDate: object.endDate
          ? Utils.getDateInFormat(new Date(object.endDate))
          : " - ",
        submittedDate: object.submittedDate
          ? Utils.getDateInFormat(new Date(object.submittedDate))
          : " - ",
        licenseNumber:
          object.licenseNumber && object.licenseNumber.length > 0
            ? object.licenseNumber
            : "",
        marketerList:
          object.marketerList && object.marketerList.length > 0
            ? object.marketerList
            : "",
        productCategories:
          object.productCategories && object.productCategories.length > 0
            ? object.productCategories
            : "",
        levels: object.levels && object.levels.length > 0 ? object.levels : "",
        processedBy: object.processedBy || " - ",
        salesTypeName:
          object.salesTypeName && object.salesTypeName.length > 0
            ? object.salesTypeName
            : " - ",
        payee: object.payee || "This Agent/ Agency",
        agentGroup: object.agentGroup || " - ",
        doNotCall: object.doNotCall,
        doNotEmail: object.doNotEmail,
        doNotMarket: object.doNotMarket,
        salesTypeId: object.salesTypeId,
        statusId: object.statusId,
        doNotContact: object.doNotContact,
        displayEndDate: object.displayEndDate,
        underConstruction: object.underConstruction,
        isTeleSale: object.isTeleSale,
        replacedFromLicenseId: object.replacedFromLicenseId,
        replacedByLicenseId: object.replacedByLicenseId,
        advanceMonths: object.advanceMonths || " - ",
        receiptDate: object.receiptDate
          ? Utils.getDateInFormat(new Date(object.receiptDate))
          : " - ",
        approvalDate: object.approvalDate
          ? Utils.getDateInFormat(new Date(object.approvalDate))
          : " - ",
        firstApplicationDate: object.firstApplicationDate
          ? Utils.getDateInFormat(new Date(object.firstApplicationDate))
          : " - ",
      };
    });
  }

  @action downloadContracts = async () => {
    try {
      this.isLoading = true;
      if (this.searchParam) {
        if (isNaN(Date.parse(this.searchParam))) {
          this.searchParam = this.searchParam.replace("/", "$");
        }
      }
      var agentContracts = await ContractsStore.downloadContracts(
        this.agentId,
        +this.selectedStatus,
        "" + this.searchParam,
        this.marketerId,
        this.divisonId,
        this.startIndex,
        this.rows,
        this.sortColumnContract,
        this.sortContract
      );
      if (agentContracts != null) {
        if (agentContracts.data) {
          Utils.downloadDocument(
            agentContracts.data,
            agentContracts.fileName !== null &&
              agentContracts.fileName !== undefined
              ? agentContracts.fileName
              : "Contract_Summary.xlsx",
            agentContracts.fileName !== null &&
              agentContracts.fileName !== undefined
              ? agentContracts.fileName
              : "Contract_Summary.xlsx"
          );
        }
      }
    } catch (e: any) {
      IMASLog.log("exception: " + e.message);
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };

  @action showDownlineReplaceConfirm() {
    this.setReplaceConfirmation(false);
    this.setInvalidReplaceInput(false);
    this.setDownlineReplace("");
    this.setDownlineReplaceConfirmation(true);
  }

  @action replaceContract = async () => {
    let input = this.downlineReplace.toLowerCase().trim();
    this.replaceModalVisibility = false;
    this.setInvalidReplaceInput(false);
    this.setDownlineReplaceConfirmation(false);
    await this.replace(this.replaceDownline);
    Utils.showSuccessToaster("Contract Replaced.", 3000);
    this.getDatasource();
  };

  @observable isShown: boolean = false;
  @observable listViewActions: boolean = false;
  @action handleClick = (id: string) => {
    let showState = this.isShown;
    const menulistDiv = document.getElementById(id);
    // code to hide all menu lists
    const menuListAllDivs = Array.from(
      document.getElementsByClassName(
        "custom-report-menu-list"
      ) as HTMLCollectionOf<HTMLElement>
    );
    if (menulistDiv !== null && menuListAllDivs !== null) {
      for (let i = 0; i < menuListAllDivs.length; i++) {
        if (menuListAllDivs[i] !== null && menuListAllDivs[i].id !== id) {
          menuListAllDivs[i].style.display = "none";
        }
      }

      // code to open menu list
      menulistDiv.style.display =
        menulistDiv.style.display === "block" ? "none" : "block";

      // code to remove backgroud of svg
      for (let i = 0; i < menuListAllDivs.length; i++) {
        const svgSpan = document.getElementById(
          "custom-temp-" + menuListAllDivs[i].id
        ) as HTMLElement;
        if (svgSpan !== null && menuListAllDivs[i].id !== id) {
          svgSpan.className = "custom-report-menuspan";
        }
      }
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      if (svgDiv !== null) {
        svgDiv.className =
          menulistDiv.style.display === "block"
            ? "custom-report-menu-active"
            : "custom-report-menuspan";
        menulistDiv.style.display === "block"
          ? (this.listViewActions = true)
          : (this.listViewActions = false);
      }
    }

    window.onclick = function (event) {
      const menuListAllDivs = Array.from(
        document.getElementsByClassName(
          "custom-report-menu-list"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const svgDiv = document.getElementById(
        "custom-temp-" + id
      ) as HTMLElement;
      const menulistDiv = document.getElementById(id);
      if (showState && menulistDiv) {
        for (let i = 0; i < menuListAllDivs.length; i++) {
          menuListAllDivs[i].style.display = "none";
          menulistDiv.style.display =
            menulistDiv.style.display === "block" ? "none" : "none";
          svgDiv.className =
            menulistDiv.style.display === "block"
              ? "custom-report-menuspan"
              : "custom-report-menuspan";
          showState = false;
        }

        const activeClassName = document.querySelector(
          ".custom-report-menu-active"
        );
      } else {
        showState = true;
      }
    };
    this.isShown = showState;
  };

  @action replace = async (downlineEffected: boolean) => {
    try {
      let res = await ContractsStore.replaceContract(
        this.contractId,
        downlineEffected
      );
      if (res !== null && res !== undefined) {
        this.sortColumnContract = "carrier";
        this.sortContract = true;
        this.getDatasource();
      }
    } catch (e) { }
  };
  @action addContract = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      var result = await ContractsStore.getAllLookupList(
        this.contractId,
        this.agentId
      );
      if (result != null) {
        var dto: CreateUpdateContractModel = {
          id: 0,
          agentId: this.agentId,
          companyId: result.companyId,
          statusId: result.statusId,
          statusReasonId: result.statusReasonId,
          apptDate: result.startDate,
          termDate: undefined,
          payeeId: result.payeeId,
          licenseAdvanceOptionId: result.licenseAdvanceOptionId,
          advancedMonths: result.advancedMonths,
          maxAdvanceAmount: result.maxAdvanceAmount,
          agencyIsPayee: result.agnecyIsPayee,
          notes: result.notes,
          reportsToLicenseId: result.reportsToId,
          writingNumbers: result.writingNumbers,
          leadId: 0,
          doNotCall: result.doNotCall,
          doNotEmail: result.doNotEmail,
          doNotMarket: result.doNotMarket,
          doNotCallDownlineDefault: result.doNotCallDownlineDefault,
          doNotEmailDownlineDefault: result.doNotEmailDownlineDefault,
          doNotMarketDownlineDefault: result.doNotMarketDownlineDefault,
        };
        await ContractsStore.addContract(dto);
        this.isLoading = false;
      }
    } catch (e: any) {
      this.isLoading = false;
      this.response = e.response;
      this.isMessgeVisible = true;
    }
  };

  @action onDeleteClick = async () => {
    this.isLoading = true;
    try {
      var result = await ContractsStore.deleteContract(this.contractId);
      if (googleTagManager) {
        googleTagManager.trackAction("Delete", {
          feature: "Agent",
          contractId: this.contractId,
        });
      }
      this.setDeleteConfirmation(false);
      this.isLoading = false;
      this.isMessgeVisible = true;
      if (result && result.message) {
        this.rowIndexToRetainHighlight = -1;
        var successResponse = {
          status: 400,
          title: result.message + "",
          errors: { "": [] },
        };

        setTimeout(async () => {
          this.isMessgeVisible = false;
          this.getDatasource();
          this.isLoading = false;
          this.response = JSON.stringify(successResponse);
          if (!result.isDeleted) {
            PageContext.setResponseMessage(this.response);
            PageContext.setIsMessageVisible(true);
          }
        }, Utils.timeDelay_Delete());
      }
      Utils.showSuccessToaster("Contract Deleted.", 3000);
    } catch (e: any) {
      IMASLog.log(
        "exception ..deleting agent contract with contract id : " +
        this.contractId
      );
      this.isMessgeVisible = true;
      this.response = e.response;
      this.setDeleteConfirmation(false);
      this.isLoading = false;
    }
  };

  @action setDeleteConfirmation(value: boolean) {
    this.isDeleteConfirm = value;
  }
  @action setReplaceConfirmation(value: boolean) {
    this.isReplaceContract = value;
  }
  @action setDownlineReplaceConfirmation(value: boolean) {
    this.isDownlineReplace = value;
  }
  @action setInvalidReplaceInput(value: boolean) {
    this.invalidReplaceInput = value;
  }

  @action onEditContractClick = (contractId: number) => {
    localStorage.setItem("isEditContract", "true");
    routes.editContract.push({
      agentId: "" + RouteParamDetails.id,
      contractId: contractId,
    });
  };

  @action onReplaceContractClick = (contractId: number) => {
    this.setReplaceConfirmation(true);
  };

  @action setContractId = (value: number) => {
    this.contractId = value;
  };

  @action getDateInFormat = (
    contractData: ContractListModel,
    column: string
  ) => {
    switch (column) {
      case "StartDate":
        return Utils.getDateInFormat(contractData.startDate);
      case "EndDate":
        if (
          contractData.endDate !== undefined &&
          contractData.endDate !== null &&
          contractData.endDate.toString() === "0001-01-01T00:00:00"
        ) {
          return "";
        } else {
          return Utils.getDateInFormat(contractData.endDate);
        }

      case "SubmittedDate":
        if (
          contractData.submittedDate !== undefined &&
          contractData.submittedDate !== null &&
          contractData.submittedDate.toString() === "0001-01-01T00:00:00"
        ) {
          return "";
        } else {
          return Utils.getDateInFormat(contractData.submittedDate);
        }

      default:
        return "";
    }
  };

  @action isHideRefreshButton() {
    if (this.serachInputValue != "") {
      this.isRefreshEnable = true;
    } else {
      this.isRefreshEnable = false;
    }
  }
  @action searchIntervals = async (text: string) => {
    this.serachInputValue = text;
    if (text) {
      this.searchParam = text;
      if (this.searchParam.length >= 3 || this.searchParam.length === 0) {
        this.isHideRefreshButton();
        this.startIndex = 0;
        this.getDatasource();
      } else {
        this.selectedStatus && this.selectedStatus == "1"
          ? (this.isRefreshEnable = false)
          : (this.isRefreshEnable = true);
      }
    } else {
      this.searchParam = "";
      this.startIndex = 0;
      this.first = 0;
      this.getDatasource();
    }
  };

  @action setGlobalSerachValue = (text: string) => {
    this.serachInputValue = text;
  };
  @action searchNotesDesc = async (value: string) => {
    this.getDatasource();
  };

  @action setSelectedLincense = (value: any) => {
    this.selectedLicense = value;
  };

  @action goSearch = async () => {
    this.startIndex = 0;
    this.first = 0;
    this.getDatasource();
  };
  @action resetFilters = async () => {
    this.searchParam = "";
    this.serachInputValue = "";
    this.selectedStatus = "1";
    this.startIndex = 0;
    this.first = 0;
    this.sortColumnContract = "carrier"; //as per AMS-2436
    this.sortContract = true;
    this.marketerId = undefined;
    this.divisonId = undefined;
    this.isRefreshEnable = false;
    this.selectedCarriers = [];
    this.getDatasource();
  };
  @action loadUserers() { }
  @action onVirtual(index: number) { }
  @action onPage = async (firstIndex: number, rows: number) => {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.getDatasource();
  };
  dnxListener: AgentSummaryListener | undefined;
  setDnxListener(listener: AgentSummaryListener) {
    this.dnxListener = listener;
  }

  @action getDatasource = () => {
    this.loadContracts();
  };

  // Payment plan logic
  @observable paymentstatus: string = "A";
  @observable paymentCarrier: string = "0";
  @observable sortColumnPaymentPlan: string = "Id";
  @observable sortOrderPaymentPlan: boolean = false;
  @observable PaymentPlansList = new Collection<PaymentPlanListModel>();
  @observable selectedPaymentPlanId: number = 0;
  @observable selectedPaymentPlan: PaymentPlanListModel = {};
  @observable paymentTotalRecords: number = 0;
  @observable paymentRows = 10;
  @observable paymentFirst: number = 0;
  @observable pageEventPrevention: boolean = true;
  @observable paymentStartIndex: number = 0;
  @observable isDeletePaymentPlan: boolean = false;
  @observable isPlanUsageDialogVisible: boolean = false;
  @observable PaymentPlanUsageList =
    new Collection<PaymentPlanUsageListModel>();

  @observable PaymentPlanUsageRows = 10;
  @observable sortColumnPaymentPlanUsage: string = "agentName";
  @observable sortOrderPaymentPlanUsage: boolean = false;

  @observable paymentCompanyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statusList = [
    { label: "All", value: "All" },
    { label: "Active", value: "A" },
    { label: "History", value: "H" },
  ];
  @action onPagePaymentPlans(firstIndex: number, rows: number) {
    this.paymentRows = rows;
    this.paymentFirst = firstIndex;
    this.paymentStartIndex = firstIndex / this.paymentRows;
    this.loadPaymentPlans();
  }

  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
  @action setPaymentPlanStatus(value: string) {
    this.paymentstatus = value;
  }
  @action setPaymentPlanCarrier(value: string) {
    this.paymentCarrier = value;
  }

  @action loadCompany = async () => {
    try {
      this.isLoading = true;
      this.paymentCompanyList.values = [];
      var result = await AccountStore.getPaymentPlanDetail(0);
      if (result) {
        Utils.mapListItemsToDropdown(
          result.companyList || [],
          this.paymentCompanyList,
          "All"
        );
        this.paymentCarrier = "0";
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }
  @action loadPaymentPlans = async () => {
    this.isMessgeVisible = false;
    try {
      if (this.agentId) {
        this.isLoading = true;
        var result = await AccountStore.getPaymentPlans(
          this.agentId,
          this.paymentstatus,
          +this.paymentCarrier,
          0,
          this.paymentStartIndex,
          this.paymentRows,
          this.sortColumnPaymentPlan,
          this.sortOrderPaymentPlan
        );
        if (result != null) {
          if (result.data) {
            this.PaymentPlansList.values = [];
            this.paymentTotalRecords = result.recordCount
              ? result.recordCount
              : 0;
            this.PaymentPlansList.values = result.data;
          } else {
            this.noPaymentPlansFound();
          }
        }
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      IMASLog.log("exception from store: " + e.value);
      this.noPaymentPlansFound();
    }
    this.isLoading = false;
  };
  @action loadPaymentPlanUsageByPlanId = async () => {
    this.loadPaymentPlanUsage();
  };

  @action loadPaymentPlanUsage = async () => {
    this.isMessgeVisible = false;
    this.PaymentPlanUsageList.values = [];
    try {
      if (this.selectedPaymentPlanId) {
        this.isLoading = true;
        var result = await AccountStore.getPaymentPlanUsgage(
          this.selectedPaymentPlanId,
          0,
          this.PaymentPlanUsageRows,
          this.sortColumnPaymentPlanUsage,
          this.sortOrderPaymentPlanUsage
        );
        if (result != null) {
          if (result.data) {
            this.PaymentPlanUsageList.values = [];
            this.PaymentPlanUsageList.values = result.data;
          } else {
            this.PaymentPlanUsageList.values = [];
          }
          this.setPlanUsageDialogDialogVisibility(true);
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      IMASLog.log("exception from store: " + e.value);
      this.noPaymentPlansFound();
    }
    this.isLoading = false;
  };
  @action resetPaymentPlanFilters() {
    this.paymentstatus = "A";
    this.paymentCarrier = "0";
    this.loadPaymentPlans();
  }
  @action setSelectedPaymentPlanId(id: number) {
    this.selectedPaymentPlanId = id;
  }

  @action handlePaymentTabClick = async (contractId: number | undefined) => {
    routes.contractDetails.replace({
      agentId: "" + this.agentId,
      contractId: contractId ? contractId : 0,
    });
  };

  @action editPaymentPlanButtonClick = async (
    contractId: number | undefined,
    paymentPlanId: number | undefined
  ) => {
    localStorage.setItem("editMainScreenPaymentPlan", "true");
    routes.editPaymentPlan.replace({
      agentId: "" + RouteParamDetails.id,
      contractId: contractId != undefined ? contractId : 0,
      paymentPlanId: paymentPlanId != undefined ? paymentPlanId : 0,
    });
  };

  @action setSelectedPaymentPlan(obj: any) {
    this.selectedPaymentPlan = obj;
  }
  @action setPaymentSortOrder() {
    this.sortOrderPaymentPlan = !this.sortOrderPaymentPlan;
  }
  @action setPaymentSortColumn(column: string) {
    this.sortColumnPaymentPlan = column;
  }

  @action setPaymentUsageSortOrder() {
    this.sortOrderPaymentPlanUsage = !this.sortOrderPaymentPlanUsage;
  }
  @action setPaymentUsageSortColumn(column: string) {
    this.sortColumnPaymentPlanUsage = column;
  }
  @action noPaymentPlansFound() {
    this.paymentTotalRecords = 0;
    this.PaymentPlansList.values = [];
  }
  @action downloadPaymentPlan = async (id: number | undefined) => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      if (id) {
        var result = await AccountStore.downloadPaymentPlan(id);
        await this.downloadReport(result, "paymentplan");
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  @action downloadReport = async (result: any, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = "paymentplan";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
  @action setDeletPaymentPlanConfirm(value: boolean) {
    this.isDeletePaymentPlan = value;
  }
  @action deletePaymentPlan = async () => {
    try {
      this.isMessgeVisible = false;
      this.isLoading = true;
      var result = await AccountStore.deletePaymentPlan(
        this.selectedPaymentPlanId
      );
      this.startIndex = 0;
      this.setDeletPaymentPlanConfirm(false);
      this.isLoading = false;
      
      // if (result && result.message) {
      //   var responseModel = {
      //     status: 400,
      //     title: result.message,
      //     errors: { "": [] },
      //   };
        // this.response = JSON.stringify(responseModel);
        // PageContext.setResponseMessage(this.response);
        // this.isMessgeVisible = true;
        if (result.isDeleted) {
          Utils.showSuccessToaster("Payment Plan deleted.", 4000);
          setTimeout(async () => {
            await this.loadPaymentPlans();
            this.isLoading = false;
            window.scrollTo(0, 0);
          }, Utils.timeDelay_Success());
        } else {
          // PageContext.setIsMessageVisible(true);
          window.scrollTo(0, 0);
          Utils.showWarningToaster(result.message || "", 4000);
        }
      }
     catch (error:any) {
      this.setDeletPaymentPlanConfirm(false);
      error &&
        error.text().then((_responseText: any) => {
          let isStringResponse = typeof _responseText === "string";
          const obj = isStringResponse
            ? JSON.parse(_responseText)
            : _responseText;
          Utils.showWarningToaster(obj.title, 4000);
        });
    }
  };
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };

  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getAgentDocuments(
      this.agentId
        ? this.agentId
        : parseInt("" + localStorage.getItem("currentAgentId")) || 0,
      0,
      0,
      this.selectedDocument,
      0,
      100,
      "id",
      false,
      Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)
        ? null
        : false
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result && result.recordCount && result.data) {
      let self = this;
      result.data =
        self.documentList.values &&
        result.data.filter((i) => {
          return (
            self.documentList.values.find(
              (j) =>
                j.documentName == i.documentName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, {
            id: -1,
          });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action downloadDocumentLink = async (docId: number, filename: string) => {
    this.isLoading = true;
    // this.isException = false;
    // this.exceptionMessage = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : filename,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e: any) {
      // this.isException = true;
      // this.exceptionMessage = e.response;
    }
    this.isLoading = false;
  };

  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;
    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter(
        (item: any) => {
          return item.rowId != rowId;
        }
      );
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item: any) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
  }

  @action getContractAssocaitionsById = async (contractId: number) => {
    let res = await ContractsStore.getContractAssocaitionsById(contractId);
    this.deleteContractContext =
      res.message || "Are you sure you want to delete this contract?";
    this.setDeleteConfirmation(true);
  };

  @action setPlanUsageDialogDialogVisibility = async (value: boolean) => {
    this.isPlanUsageDialogVisible = value;
  };

  @action listenScrollEvent = () => {
    this.attachEvent(document.getElementById("root"), "scroll", this.update());
    this.attachEvent(window, "resize", this.update());
    this.update();
  };

  @action visibleY = (el: any) => {
    if (el) {
      var rect = (el && el.getBoundingClientRect()) || null,
        top = rect.top,
        height = rect.height,
        el = el.parentNode;
      return top <= document.documentElement.clientHeight;
    } else {
      return false;
    }
  };
  @action attachEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callbackFunction);
    }
  };

  @action update = () => {
    this.isScrollVisible = this.visibleY(
      document.getElementById("pleaseignoreanddonotdeleteit")
    );
  };

  @observable rowIndexToRetainHighlight: number = -1;
  @action holdVisitedRowId = (e: any) => {
    this.rowIndexToRetainHighlight = e.rowIndex > -1 ? e.rowIndex : -1;
  };

  @action carriersChange = (value: string) => {
    this.selectedCarriers = value;
    this.loadContracts();
    this.isRefreshEnable = true;
  };

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.carriersList.values = []; 
    this.showInactiveCarriers ? (this.carriersList.values = this.carriersInitial.values) :(
    this.carriersList.values = Utils.carriersFilter(this.carriersInitial.values),
    this.activeCarriers.values = this.carriersList.values); 
    if(!this.showInactiveCarriers){ 
      if(this.selectedCarriers.length == this.carriersInitial.values.length){
        let tmpArray:any = (this.activeCarriers.values ).map((itm:  any ) => itm.value) ||[];
        this.selectedCarriers = [];  
        this.carriersChange(tmpArray);
      }
    }; 
  };
  @action loadCarriers = async () => {
    try {
      var carriers = await ContractsStore.GetActiveInactiveAllCarrierLookup();
      if (carriers) {
        Utils.mapListItemsToDropdown(
          carriers ? carriers : [],
          this.carriersList,
          "",
          ""
        );
        this.carriersInitial.values = this.carriersList.values;
        this.carriersList.values = Utils.carriersFilter(this.carriersInitial.values);
      }
    } catch (e) { }
  };
}

export interface AgentSummaryListener {
  updateDnxOptions(): void;
  updateSummary(): void;
}
export interface TabItems {
  label: AgentContractDetails;
  value: number;
}

export interface CustomerLookupListModelTask {
  id?: number;
  firstName?: string | undefined;
  lastName?: string | undefined;
  dob?: Date | undefined;
  city?: string | undefined;
  state?: string | undefined;
}
