import { observable, action } from "mobx";
import loggedInUser from "../infrastructure/Auth0Authenticator";
import UserStore from "../store/IdentityStore";
import Utils from "../infrastructure/Utils";
import * as cacheHelper from "../infrastructure/CacheHelper";
import UserContext from "./../infrastructure/UserContext";
import classNames from "classnames";
import { routes } from "../router";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import { MessageType } from "../infrastructure/enum/Common";
import { ProgressInfo } from "../infrastructure/ProgressInfoModel";
import WorkflowStore from "../store/WorkflowStore";
import IdentityStore from "../store/IdentityStore";
import { UserConnection } from "../services/MessagingService";
import ProducerStore from "../store/ProducerStore";

export interface PushNotification {
    user: string;
    message: string;
    Id: number;
}
export interface SMSPushNotification {
    id: string;
    message: string;
    agentId: string;
    user: string;
    contactId: string;
    messageRead: boolean;
  }

export class AppInlineProfileViewModel {
  @observable isVisible: boolean = false;
  @observable isNotificationCollapsed: boolean = true;
  @observable notificatioClassName: string = "";
  @observable showContextualMenu: boolean = false;
  @observable DisplayName: string = loggedInUser.displayName;
  @observable DisplayNameMobile: string = loggedInUser.initialsName;
  @observable userMenus: any;
  @observable Menus: any;
  @observable impersonatingUsers: any;
  @observable topbarColor: any = "#33478a";
  @observable imagePath: string = "assets/layout/images/";
  @observable logo: string = "assets/layout/images/IntegrityConnect.svg";
  @observable mobilelogo: string = "assets/layout/images/IntegrityConnect.svg";
  @observable environment: string = "Dev";
  @observable routefn: any;
  @observable routeUrl: any;
  @observable selectedImpersonatingUserId: number = 0;
  @observable assignedToUserDocumentCount: number = 0;
  @observable hasImpersonatingPermission: boolean = false;
  @observable assignedToUserTeamDocumentCount: number = 0;

  @observable workflowAppsDocumentCount: number = 0;
  @observable workflowContractsDocumentCount: number = 0;
  @observable notifications: PushNotification[] = [];
  @observable progressInfo: ProgressInfo = {
    Total: 0,
    Progress: 0,
    Message: "",
    MessageType: MessageType.ProgressBar,
    ToaterInfo: [],
  };
  @observable smsNotifications: any[] = [];
  listener: ReloadHomeListener | undefined;

  smsListner: AgentSMSListener | undefined;
  setSMSListener(listener: AgentSMSListener) {
    this.smsListner = listener;
  }
  setListener(listener: ReloadHomeListener) {
    this.listener = listener;
  }

  menuListener: ReloadMenusListener | undefined;
  setMenuListener(listener: ReloadMenusListener) {
    this.menuListener = listener;
  }

  @action load = async (menus: any) => {
    try {
      this.notificatioClassName = "notif-dropdown-content";
      this.setTheme();
      if (this.menuListener) {
        this.menuListener.reloadMenus();
      }
      await this.receiveNotifications();
      await this.getDocumentCount();
    } catch (e) {}
    this.Menus = menus;
    this.userMenus = menus;
  };

  @action setMenusBasedOnPermission = (menus: any) => {
    this.Menus = [];
    this.userMenus = [];
    this.Menus = menus;
    this.userMenus = menus;
  };

  @action callImpersonating = async () => {
    var result = await UserStore.getImpersonateUserList();
    if (result) {
      this.impersonatingUsers = this.mapListItemAndSort(result);
      var selectedUser =
        this.impersonatingUsers &&
        this.impersonatingUsers.length > 0 &&
        this.impersonatingUsers.filter((item: any) => {
          return item.selected === true;
        });
      console.log("selected user", selectedUser);
      if (selectedUser && selectedUser.length > 0) {
        this.selectedImpersonatingUserId = selectedUser[0].value;
        await this.setImpersonatingFirstTime(selectedUser[0].value);
      }
    }
  };

  @action setImpersonatingUserPermission = async () => {
    this.hasImpersonatingPermission =
      Utils.getLoginUserImpersonatingPermission();
  };

  @action notificationListener() {
    this.showNotifications();
    document.removeEventListener("click", this.notificationListener);
  }

  @action setTheme() {
    this.logo = this.imagePath + Utils.getLogo();
  }
  @action setImpersonatingFirstTime = async (value: string) => {
    await this.getUserPermission(value);
    await this.setImpersonatingUserPermission();
    if (
      localStorage.getItem("noCacheload") &&
      localStorage.getItem("noCacheload") === "true"
    ) {
      localStorage.removeItem("noCacheload");
    } else {
      cacheHelper.initialize();
    }
  };
  @action setImpersonatingUser = async (value: string) => {
    await this.getUserPermission(value);
    if (this.menuListener) {
      this.menuListener.reloadMenus();
    }
    if (window.location.pathname === "/") {
      if (this.listener) {
        this.listener.reloadHome();
      }
    } else {
      routes.home.replace();
    }
  };
  @action getUserPermission = async (value: string) => {
    this.selectedImpersonatingUserId = parseInt(value);
    await UserStore.getUserPermissions(
      this.selectedImpersonatingUserId.toString()
    ).then((data: any) => {
      if (data) {
          var name = "";
        if (data.displayName) {
          name = data.displayName.split(" ");
          localStorage.setItem(
            "displayUserName",
              name && name.length > 0 ? data.displayName.substring(data.displayName.indexOf(' ') + 1) : ""
          );
        }
        UserContext.userId = this.selectedImpersonatingUserId;
        UserContext.permissions = data.permissions;
        UserContext.roles = data.roles;
        UserContext.agencyId = data.agencyId;
        UserContext.teamId = data.teamId;
        UserContext.marketerId = data.marketerId;
        UserContext.isDownlineAgency = data.isDownlineAgency;
      }
    });
  };
  @action validateMenu() {
    return this.userMenus;
  }

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action
  toggleVisibility = () => {
    this.isVisible = !this.isVisible;
  };

  @action
  setShowContextualMenu(visible: boolean) {
    this.showContextualMenu = visible;
  }
  @action getUserId() {
    return this.selectedImpersonatingUserId;
  }

  @observable smsNotificationTemp: SMSPushNotification[] = [];
  @action receiveNotifications = async () => {
    try {
      this.notifications = [];
      let pushNotification: PushNotification[] = [];
      const apiBaseUrl = Utils.getServiceUrlByEnvironment();
      const userId = loggedInUser.adUserId;
      const hubConnection = new HubConnectionBuilder()
        .withUrl(apiBaseUrl + "/notify?userId=" + userId)
        .configureLogging(LogLevel.Trace)
        .build();

      console.log(hubConnection);

      hubConnection
        .start()
        .then(() => {
          console.log("Connection started!");
        })
        .then(() =>
          hubConnection.invoke("GetConnectionId").then((data) => {
            console.log("connectionId: " + data);
            this.createUserConnection(userId, data);
          })
        )
        .catch((err) => {
          console.log("Error while establishing connection ");
          console.log(err);
        });
              
      hubConnection.on("sendToUser", (progressInfo) => { 
        if (progressInfo.messageType === MessageType.Notification) {
          pushNotification.push({
            user: progressInfo.adUserId,
            message: progressInfo.message,
            Id: 0,
          });
          this.notifications = pushNotification;
        } else if (progressInfo.messageType === MessageType.SMS) {
          let smsDup: any[] = [];
          smsDup = this.smsNotifications
            ? this.smsNotifications.filter(
                (item: any) => item.id === progressInfo.messageId
              )
            : [];
          if (smsDup && smsDup.length === 0) {
            this.smsNotificationTemp.push({
              id: progressInfo.messageId,
              message: progressInfo.message,
              agentId: progressInfo.id,
              user: progressInfo.adUserId,
              contactId: "" + progressInfo.contactId,
              messageRead: false,
            });
            this.smsNotifications = this.smsNotificationTemp;
          }
        } else {
          // Progress Bar/ Toaster
          let response: any = {
            Total: progressInfo.total,
            Progress: progressInfo.progress,
            Message: progressInfo.message,
            MessageType: progressInfo.messageType,
          };
          if (progressInfo.message.search("Broken Order") !== -1) {
            response.ToaterInfo = JSON.parse(
              localStorage.getItem("broker-notification") || "[]"
            );
          }

          if (progressInfo.messageType === MessageType.Toaster) {
            let toatinfo = response.ToaterInfo.filter((item: any) => {
              return item.Message === progressInfo.message;
            });
            if (toatinfo && toatinfo.length === 0) {
              response.ToaterInfo.push({
                Id: new Date().getTime(),
                Message: progressInfo.message,
              });
            }
          }
          this.progressInfo = response;
          if (progressInfo.message.search("Broken Order") !== -1) {
            localStorage.setItem(
              "broker-notification",
              JSON.stringify(response.ToaterInfo)
            );
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  @action createUserConnection = async (
    userId: string,
    connectionId: string
  ) => {
    try {
      var dto: UserConnection = {
        connectionId: connectionId,
        adUserId: userId,
      };
      await IdentityStore.createUserConnectionPushNotification(dto);
    } catch (e) {
      console.log(e);
    }
  };
  @action getDocumentCount = async () => {
    try {
      let result = await WorkflowStore.documentCount();
      if (result && result.data) {
        this.assignedToUserDocumentCount = result.data
          .assignedToUserDocumentCount
          ? result.data.assignedToUserDocumentCount
          : 0;
        this.assignedToUserTeamDocumentCount = result.data
          .assignedToUserTeamDocumentCount
          ? result.data.assignedToUserTeamDocumentCount
          : 0;
      }
    } catch (e) {
      console.log(e);
    }
  };

  @action closeNotificationBaseOnIndex = (index: number) => {
    this.notificatioClassName = classNames("notif-dropdown-content notif-show");
    let newList = this.notifications;
    if (index > -1) {
      newList.splice(index, 1);
    }
    this.notifications = newList;
  };

  @action closeSMSNotification = async (smsId: string) => {
    try {
      await ProducerStore.setIsMessageRead(+smsId);
      let newList = this.smsNotifications.filter(
        (item: any) => item.id !== smsId
      );
      this.smsNotifications = [];
      this.smsNotificationTemp = [];
      this.smsNotifications = newList;
      this.smsNotificationTemp = newList;
    } catch (e) {
      console.log(e);
    }
  };

  @action showNotifications = async () => {
    this.isNotificationCollapsed = !this.isNotificationCollapsed;
    this.notificatioClassName = this.isNotificationCollapsed
      ? classNames("notif-dropdown-content")
      : classNames("notif-dropdown-content notif-show");
    if (!this.isNotificationCollapsed) {
      document.addEventListener("click", this.showNotifications);
    } else {
      document.removeEventListener("click", this.showNotifications);
    }
  };
}

export interface ReloadHomeListener {
    reloadHome(): void;
}

export interface ReloadMenusListener {
    reloadMenus(): void
}

export interface AgentSMSListener {
    updateSmsHistorySearch(): void;
}
