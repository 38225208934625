export enum AppInsightsKey {
    Dev = "4bbbb060-26c1-4863-b5b5-90fe86ce4c80",
    Qa = "dfd20124-e76d-4589-96e2-c9cfe6502252",
    Uat = "7299157e-c9c0-4d67-bea1-f071cfb5089d",
    Stage = "e6d74e8c-1910-4922-a7e8-7cc5bb49241e",
    Demo = "35c0cfae-a0e1-4836-b38a-aeba756a82c4",
    Prod = "4ba79b63-0b12-42a7-bebb-835e3739256f",
    Feature = "4bbbb060-26c1-4863-b5b5-90fe86ce4c80"
}
export enum AppHostKey {
    Dev = "dev",
    Qa = "qa",
    Uat = "uat",
    Stage = "stage",
    Demo = "demo",
    Prod = "prod",
    Feature = "feature",
    Feature2 ="IC00002",
    Feature6 ="IC00006",
    Feature14 ="IC00014",
}
export enum ServiceType {
    Accounting,
    Address,
    AutoDialer,
    BusinessUnitConfig,
    Commission,
    Contract,
    Document,
    Identity,
    Insured,
    Lead ,
    Messaging,
    ProducerSearch,
    Producer,
    Product,
    SupplyOrder,
    Task,
    Workflow,
    Production,
    Report,
    AccountingAdjustment,
    InviteService,
    Notification,
    CustomAttribute
}