/* eslint-disable @typescript-eslint/no-use-before-define */
import { observable, action } from "mobx";

import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";

import RouteList from "../../infrastructure/RouteList";
import utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";

import { CreditCardChargeListViewModel } from "../administration/accounting/creditCardCharge/CreditCardChargeListViewModel";
import { CreditCardChargeEditViewModel } from "../administration/accounting/creditCardCharge/CreditCardChargeEditViewModel";
import ContractsStore from "../../store/ContractsStore";
import AccountStore from "../../store/AccountingStore";

import { Permission } from '../../infrastructure/enum/Permission';
import { AccountDetails, AccountSubTabs } from "../../infrastructure/enum/AgentDetails";
import { routes } from "../../router";

export class AgentAccountViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  @action Load = async (id: number, name: string | undefined) => {
    this.agentId = id;
    this.agentName = name;
    this.isException = false;
    this.isMessageVisible = false;
    this.sortDebt = false;
    this.sortColumnDebt = "id";
    this.isAddCreditCardReq = false;
    this.getAccountDetailsTabItems();
      this.resetPaging();
      utils.setAccountSubTab(0);
    let openTab = utils.getAccountSubTab();
    if (
      utils.hasUserPermission(Permission.CreditCardChargeRequests) &&
      (openTab === AccountSubTabs.credit || openTab === "")
    ) {
      this.selectedTabIndex = 0;
      this.selectedPanel = AccountDetails.CreditCardChargeReq;
      this.CreditCardListViewModel.Load(undefined, undefined, this.agentId);
    } else {
      this.selectedTabIndex = 1;
      this.selectedPanel = AccountDetails.DebtHistory;
      await this.loadCompanies();
      this.loadDebtList();
    }


  };
  @action resetPaging = () => {
    this.startIndex = 0;
    this.first = 0;
    this.rows = 10;
  };
  @action resetSort() {
    this.sortDebt = false;
    this.sortColumnDebt = "id";
  }
  @action setSelectedPanel = async (value: string) => {
    console.log(value);
    this.selectedPanel = value;
    if (value === AccountDetails.DebtHistory) {
      utils.setAccountSubTab(1);
      await this.loadCompanies();
      this.rows = 10;
      this.loadDebtList();
    } else {
      utils.setAccountSubTab(0);
      this.CreditCardListViewModel.Load(undefined, undefined, this.agentId);
    }
  }
  @action formatName(name: string): string {
    return name ? name.split(" ").reverse().join(`, `) : "";
  }
  @action generatePaymentUrl = () => {
    let url = `https://gateway.helcim.com/hosted/?merchantId=4501452221&token=b32b231h10794id69c&allowZeroAmount=1&customerId=${this.agentId
      }&billingName=${this.formatName(
        this.agentName ? this.agentName : ""
      )}#Policies`;
    window.open(url, "_new");
  };
  Route = async (currentRoute: RouteList): Promise<void> => { };
  @observable selectedCompany: string = "0";
  @observable rows: number = 10;
  @observable totalRecords: number = 100;
  @observable totalRecordsForDebtList: number = 0;
  @observable first: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable startIndex: number = 0;
  @observable debtId: number = 0;
  @observable selectedPanel: string = AccountDetails.CreditCardChargeReq;
  @observable selectedTabIndex?: number = 0;
  // @observable companies = [{ label: "All", value: "0" }];
  @observable agentName: string | undefined = "";
  @observable isAddCreditCardReq: boolean = false;
  @observable isDeleteDebtConfirm: boolean = false;
  @observable isEditDebtAccess: boolean = false;
  @observable isException: boolean = false;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 10;
  @observable sortColumnDebt: string = "id";
  @observable sortDebt: boolean = false;
  @observable isMessageVisible: boolean = false;
  @observable response: any;
  @observable
  CreditCardListViewModel: CreditCardChargeListViewModel = new CreditCardChargeListViewModel();
  @observable
  CreditCardChargeEditViewModel: CreditCardChargeEditViewModel = new CreditCardChargeEditViewModel();
  @observable creditCardChargeReqList = new Collection<{
    status: string;
    requestor: string;
    requested: string;
    amount: number;
    description: string;
  }>();
  // @observable CreditCardChargeListModel: CreditCardChargeData | null = null;
  @observable companies = new Collection<{
    label: string;
    value: string;
  }>();

  @observable agentDebtList = new Collection<{
    id?: number;
    carrier?: string;
    carrierId?: number;
    awn?: string;
    reportDate?: Date;
    displayReportDate?: Date;
    advanced?: number;
    unSecured?: number;
    reserve?: number;
    side?: number;
    total?: number;
    rollUp?: number;
    producer?: number;
    upline?: number;
    isZeroedOut?: boolean;
  }>();

  @action setSortOrder() {
    this.sortDebt = !this.sortDebt;
  }
  @action setSortColumn(column: string) {
    this.sortColumnDebt = column;
  }
  @action noRecordsFound() {
    this.totalRecordsForDebtList = 0;
    this.agentDebtList.values = [];
    }
    @action onEditDebt(debtId: any) {
        routes.editAgentDebt.push({
            agentId: "" + this.agentId,
            debtId: debtId || 0,
        });
    }
  @action onDeleteDebtClick = async () => {
    try {
      await AccountStore.deleteAgentDebt(this.debtId);
      setTimeout(async () => {
        this.setDeleteDebtConfirmation(false);
        await this.loadDebtList();
      }, utils.timeDelay_Delete());

    } catch (error) {
      IMASLog.log("exception ..deleting debt");
    }
  };
  @action setDeleteDebtConfirmation(value: boolean) {
    this.isDeleteDebtConfirm = value;
  }

  @action setIsEditDebtAccess(value: boolean) {
    this.isEditDebtAccess = value;
  }

  @action setSelectedDebt = (debtId: number) => {
    this.debtId = debtId;
  };

  @action toggleCreditCardReq = (bool: boolean) => {
    this.isAddCreditCardReq = !this.isAddCreditCardReq;
    if (!this.isAddCreditCardReq && bool) {
      this.CreditCardListViewModel.Load(
        undefined,
        undefined,
        this.agentId,
        true
      );
      return;
    }
    if (!this.isAddCreditCardReq)
      this.CreditCardListViewModel.loadList();
    else {
      this.CreditCardChargeEditViewModel.Load(undefined);
    }
  };
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
  };
  @action loadDebtList = async () => {
    this.isLoading = true;
    this.isMessageVisible = false;
    this.response = "";
    try {
      var agentDebtList = await AccountStore.debtListBasedOnAgent(
        this.agentId,
        this.selectedCompany !== "0" ? Number(this.selectedCompany) : undefined,
        this.startIndex,
        this.rows,
        this.sortColumnDebt,
        this.sortDebt
      );
      if (agentDebtList) {
        if (agentDebtList.recordCount) {
          this.totalRecordsForDebtList = agentDebtList.recordCount;
          if (agentDebtList.data)
            this.agentDebtList.values = agentDebtList.data;
        } else {
          this.noRecordsFound();
        }
      } else {
        this.noRecordsFound();
      }
    } catch (e) {
      this.response = e.response;
      this.isMessageVisible = true;
      this.isLoading = false;
    }

    this.isLoading = false;
  };
  @action getFormatValue = (rowData: any, header: string) => {
    let rowObj = utils.getObject(rowData);
    switch (header) {
      case "Advanced":
        return utils.getCurrencyFormat(rowObj.advanced);
      case "Unsecured":
        return utils.getCurrencyFormat(rowObj.unSecured);
      case "Reserve":
        return utils.getCurrencyFormat(rowObj.reserve);
      case "Side":
        return utils.getCurrencyFormat(rowObj.side);
      case "Total":
        return utils.getCurrencyFormat(rowObj.total);
      case "Rollup":
        return utils.getCurrencyFormat(rowObj.rollUp);

      default:
        return "";
    }
  };
  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    this.startIndex = firstIndex / this.rows;
    this.loadDebtList();
  }
  @action loadCompanies = async () => {
    this.isLoading = true;
    var defaultItem = { label: "All", value: "0" };
    var result = await ContractsStore.getAllCompanyLookup();

    if (result !== null) {
      this.companies.values = [];

      result &&
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value.toString() : "",
          };
          this.companies.values.push(data);
        });
      this.companies.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";
    } else {
      this.companies.values = [];
      this.companies.values.splice(0, 0, defaultItem);
      this.selectedCompany = "0";
    }

    this.isLoading = false;
  };
  @observable AccountDetailsTabItems: TabItems[] = [];

  @action getAccountDetailsTabItems = () => {
    this.AccountDetailsTabItems = [];
    let a = { label: AccountDetails.CreditCardChargeReq, value: 0 };
    let b = { label: AccountDetails.DebtHistory, value: 1 };
    utils.hasUserPermission(Permission.CreditCardChargeRequests) &&
      this.AccountDetailsTabItems.push(a);
    utils.hasUserPermission(Permission.ViewAgentDebt) &&
      this.AccountDetailsTabItems.push(b);
  };
}



export interface TabItems {
  label: AccountDetails;
  value: number;
}
