import React from "react";
import { observer } from "mobx-react";
import { AddEditMarketingEventViewModel } from "./../../viewModels/home/AddEditMarketingEventViewModel";
import { CustomDropdown } from "../components/controls/IMASDropdown";
import { CustomInputTextArea } from "../components/controls/IMASInputTextArea";
import { CustomInputText } from "../components/controls/IMASInputText";
import { IMASCancelSaveView } from "../components/controls/IMASCancelSaveView";
import { CustomInputDate } from "../components/controls/CustomInputDate";
import { IMASInputNum } from "../components/controls/IMASInputNum";
import { Checkbox } from "primereact/checkbox";
import { routes } from "../../router";
import { DataTable } from "primereact/datatable";
import { Constants } from "../../infrastructure/enum/Constants";
import IMASLog from "../../infrastructure/IMASLog";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { Dialog } from "primereact/dialog";
import { AddEditMarketingEventListView } from "./AddEditMarketingEventListView";
import { AddEmailsView } from "./AddEmailsView";
import { IMASConfirmationAlert } from "../components/controls/IMASConformationAlert";
import { MarketingEventListDisplayView } from "../../services/ProducerService";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";

export const AddEditMarketingEventView: React.FC<{
  vm: AddEditMarketingEventViewModel;
}> = observer((props) => {
  const changeMrktgEventGroup = (value: string) => {
    props.vm.setMrktgEventGroup(value);
  };
  const changeType = (value: string) => {
    props.vm.setType(value);
  };
  const changeFormat = (value: string) => {
    props.vm.setFormat(value);
  };
  const changeAddTitle = (value: string) => {
    props.vm.setAddTitle(value);
  };

  const changeDescription = (value: string) => {
    props.vm.setDescription(value);
  };

  const changeOwner = (value: string) => {
    props.vm.setOwner(value);
  };

  const changeRequestor = (value: string) => {
    props.vm.setRequestor(value);
  };

  const changeCompany = (value: string) => {
    props.vm.setCompany(value);
  };

  const changeProductLine = (value: string) => {
    props.vm.setProductLine(value);
  };
  const changeStartTime = (date: Date | undefined) => {
    if (date instanceof Date) props.vm.setStartTime(date);
    setTimeout(() => {
      var enddateId = document.getElementById("endTime")
      if (enddateId) {
        var navigator = enddateId.querySelector(".p-datepicker-prev.p-link") as HTMLElement;
        if (navigator) {
          navigator.click()
        }
      }
    }, 200);
  };
  const changeEndTime = (date: Date | undefined) => {
    if (date instanceof Date) props.vm.setEndTime(date);
  };
  const changeUrl = (value: string) => {
    props.vm.setUrl(value);
  };
  const changeAudience = (value: string) => {
    props.vm.setAudience(value);
  };
  const changeAudienceCount = (value: string) => {
    props.vm.setAudienceCount(value);
  };
  const changePurchasedLead = (e: any) => {
    props.vm.setPurchasedLead(e.checked);
  };

  const onPageHandle = (e: { first: number; rows: number }) => {
    props.vm.onPage(e.first, e.rows);
  };
  const onSort = async (column: any) => {
    IMASLog.log("sort function " + JSON.stringify(column));
    var obj = JSON.parse(JSON.stringify(column));
    props.vm.setSortOrder();
    props.vm.setSortColumn(obj.sortField);
    await props.vm.getEventList();
  };

  const sentTemplate = (rowData: MarketingEventListDisplayView) => {
    return (
      <>
        <span>{rowData.sent ? "true" : "false"}</span>
      </>
    );
  };
  const startDateTemplate = (rowData: MarketingEventListDisplayView) => {
    let date = Utils.getDateTimeInFormat(rowData.displayStartDate);
    return (
      <>
        <span>{date}</span>
      </>
    );
  };
  const endDateTemplate = (rowData: MarketingEventListDisplayView) => {
    let date = Utils.getDateTimeInFormat(rowData.displayEndDate);
    return (
      <>
        <span>{date}</span>
      </>
    );
  };
  const actionTemplate = (rowData: MarketingEventListDisplayView) => {
    const userActionItems: MenuItem[] = [
      {
        label: "Add Emails",
        icon: "fa fa-envelope",
        command: () => {
          setTimeout(() => {
            props.vm.setEmailListVisibility(true);
          }, 300);
        },
      },
      {
        label: "Remove All Emails",
        icon: "fa fa-close",
        command: () => {
          setTimeout(() => {
            props.vm.setRemoveAllEmailsConfirmation(true);
          }, 300);
        },
      },
      {
        label: "Delete",
        icon: "fa fa-trash",
        command: () => {
          setTimeout(() => {
            props.vm.setDeleteEventListConfirmation(true);
          }, 300);
        },
      },
    ];
    return (
      <>
        <SplitButton
          label={"Edit"}
          icon="fa fa-edit"
          className="secondary-button"
          appendTo={document.body}
          model={userActionItems}
          style={{ width: "auto" }}
          onClick={() =>
            props.vm.setAddEditMarketingEventListVisibility(
              true,
              true,
              rowData.marketingEventListId
            )
          }
        ></SplitButton>
      </>
    );
  };

  const onSelectionChange = (value: any, e: any) => {
    var obj = JSON.parse(JSON.stringify(value));
    props.vm.setSelectedEventListId(obj.marketingEventListId);
  };

  const warning = () => {
    let response = {
      status: 400,
      title: Constants.genericView,
      errors: { "": [] },
    };
    return JSON.stringify(response);
  };

  return (
    <>
      {/* <Card> */}
      {(!props.vm.isEdit &&
        Utils.hasUserPermission(Permission.AddMarketingEvent)) ||
        (props.vm.isEdit &&
          Utils.hasUserPermission(Permission.EditMarketingEvent)) ? (
          <>
            <div className="p-grid">
              <div className="p-col-12 p-md-6 p-lg-6">
                <h2 style={{ marginBottom: "10px" }}>{props.vm.title}</h2>
              </div>
              {props.vm.isEdit &&
                Utils.hasUserPermission(Permission.DeleteMarketingEvent) && (
                  <div className="p-col-12 p-md-6 p-lg-6 text-right">
                    <Button
                      className="secondary-button"
                      label="Delete"
                      icon="fa fa-trash"
                      onClick={() => props.vm.setDeleteEventConfirmation(true)}
                    />
                  </div>
                )}
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Long Running Marketing Event Group"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="lrmegDropdown"
                    onBlur={() => { }}
                    value={props.vm.mrktgEventGroup}
                    options={props.vm.MrktgEventGroupList.values}
                    onChange={changeMrktgEventGroup}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputText
                    errors={props.vm.ErrorModel.getError("addTitle")}
                    id={"title"}
                    isrequired={true}
                    maxLength={150}
                    label={"Title"}
                    value={props.vm.addTitle}
                    placeHolder=""
                    onBlur={() => { }}
                    onChange={changeAddTitle}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputTextArea
                    id="mrktgEventDescription"
                    errors={undefined}
                    label="Description"
                    rows={1}
                    isrequired={false}
                    maxLength={250}
                    placeHolder=""
                    value={props.vm.description}
                    onChange={changeDescription}
                  />
                  {/* {props.vm.isInvalidMessage && (
                    <div className="invalid-feedback">Message is required and should be less than 5000 characters</div>
                  )} */}
                </div>
              </div>
              {(props.vm.isEdit) &&
                <>
                  <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="form-group">
                      <CustomInputText
                        errors={undefined}
                        id={"subjectLine"}
                        isrequired={props.vm.isEdit ? true : false}
                        maxLength={undefined}
                        label={"Subject Line"}
                        value={props.vm.subjectLine}
                        placeHolder=""
                        onBlur={() => { }}
                        onChange={(value) => props.vm.setSubjectLine(value)}
                      />
                      {props.vm.isSubjectLineValid &&
                        <div>
                          <label id='error-label' className='color-danger new-line'>Subject line is required</label>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="form-group">
                      <CustomInputText
                        errors={props.vm.ErrorModel.getError("fromEmailAddress")}
                        id={"fromEmailAddress"}
                        isrequired={props.vm.isEdit ? true : false}
                        maxLength={undefined}
                        label={"From Email Address"}
                        value={props.vm.fromEmailAddress}
                        placeHolder=""
                        onBlur={() => { }}
                        onChange={(value) => props.vm.setFromEmailAddress(value)}
                      />
                       {props.vm.isFromEmailAddressValid &&
                        <div>
                          <label id='error-label' className='color-danger new-line'>From email address is required</label>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="form-group">
                      <CustomInputText
                        errors={undefined}
                        id={"fromDisplayName"}
                        isrequired={false}
                        maxLength={undefined}
                        label={"From Display Name"}
                        value={props.vm.fromDisplayName}
                        placeHolder=""
                        onBlur={() => { }}
                        onChange={(value) => props.vm.setFromDisplayName(value)}
                      />
                    </div>
                  </div>
                </>}
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Type"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="type"
                    onBlur={() => { }}
                    value={props.vm.type}
                    options={props.vm.typeList.values}
                    onChange={changeType}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Format"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="format"
                    onBlur={() => { }}
                    value={props.vm.format}
                    options={props.vm.formatList.values}
                    onChange={changeFormat}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Owner"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="owner"
                    onBlur={() => { }}
                    value={props.vm.owner}
                    options={props.vm.ownerList.values}
                    onChange={changeOwner}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Requestor"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="requestor"
                    onBlur={() => { }}
                    value={props.vm.requestor}
                    options={props.vm.requestorList.values}
                    onChange={changeRequestor}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Carrier"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="company"
                    onBlur={() => { }}
                    value={props.vm.company}
                    options={props.vm.companyList.values}
                    onChange={changeCompany}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomDropdown
                    errors={undefined}
                    label="Product Line"
                    placeHolder="Select"
                    isrequired={false}
                    disabled={false}
                    id="productLine"
                    onBlur={() => { }}
                    value={props.vm.productLine}
                    options={props.vm.productLineList.values}
                    onChange={changeProductLine}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputDate
                    value={props.vm.startTime}
                    onChange={(date) => changeStartTime(date)}
                    dateFormat="mm/dd/yy"
                    errors={props.vm.ErrorModel.getError("startTime")}
                    label="Start Time"
                    isrequired={true}
                    minDate={props.vm.minDate}
                    id="startTime"
                    isShowTime={true}
                  />
                  {props.vm.validationErrorDateTime && (
                    <div className="invalid-feedback">{`Start Date time should be less than End Date time`}</div>
                  )}
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputDate
                    value={props.vm.endTime}
                    onChange={(date) => changeEndTime(date)}
                    dateFormat="mm/dd/yy"
                    errors={props.vm.ErrorModel.getError("endTime")}
                    label="End Time"
                    isrequired={true}
                    id="endTime"
                    isShowTime={true}
                    minDate={props.vm.startTime || new Date()}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputText
                    errors={props.vm.ErrorModel.getError("url")}
                    id={"url"}
                    isrequired={false}
                    maxLength={undefined}
                    label={"URL"}
                    value={props.vm.url}
                    placeHolder=""
                    onBlur={() => { }}
                    onChange={changeUrl}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <CustomInputText
                    errors={undefined}
                    id={"Audience"}
                    isrequired={false}
                    maxLength={undefined}
                    label={"Audience"}
                    value={props.vm.audience}
                    placeHolder=""
                    onBlur={() => { }}
                    onChange={changeAudience}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-4">
                <div className="form-group">
                  <IMASInputNum
                    errors={undefined}
                    id={"audience"}
                    isrequired={false}
                    label="Audience Count"
                    value={props.vm.audienceCount}
                    onChange={changeAudienceCount}
                    placeHolder=""
                    onBlur={() => { }}
                  />
                </div>
              </div>

              <div className="p-col-12">
                <Checkbox
                  checked={props.vm.purchasedLead}
                  value="PurchasedLead"
                  onChange={changePurchasedLead}
                  id="purchasedLead"
                ></Checkbox>
                <label className="p-checkbox-label">Purchased Lead</label>
              </div>
              <div className="p-col-12">
                <IMASCancelSaveView
                  onCancel={() => {
                    props.vm.id
                      ? routes.marketingEventDetail.push({
                        marketingEventId: props.vm.id,
                      })
                      : routes.listMarketingEvent.push({});
                  }}
                  onSave={props.vm.save}
                  disabled={props.vm.saveButtonDisabled}
                />
              </div>
              {(props.vm.isEdit &&
                <>
                  <div className="p-col-12 divider"></div>
                  <div className="p-col-12 text-right">
                    <Button
                      id="btnAdd"
                      label="Add List"
                      icon="fa fa-plus"
                      className="primary-button"
                      onClick={() =>
                        props.vm.setAddEditMarketingEventListVisibility(
                          true,
                          false
                        )
                      }
                    />
                  </div>
                  <div className="p-col-12 sm-order-3">
                    <DataTable
                      className="header-align-left datatable-width-auto app-table"
                      onSelectionChange={(e) => onSelectionChange(e.value, e)}
                      onSort={onSort}
                      selectionMode="single"
                      totalRecords={props.vm.totalRecords}
                      lazy={true}
                      paginatorTemplate={
                        props.vm.eventList.values.length > 0
                          ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          : ""
                      }
                      rowsPerPageOptions={
                        props.vm.eventList.values.length > 0 ? [5, 10, 25] : []
                      }
                      autoLayout={true}
                      responsive={true}
                      paginator={true}
                      loading={false}
                      rows={props.vm.rows}
                      first={props.vm.first}
                      onPage={onPageHandle}
                      currentPageReportTemplate={
                        props.vm.eventList.values.length > 0
                          ? "Showing {first} to {last} of {totalRecords} records"
                          : ""
                      }
                      resizableColumns={true}
                      columnResizeMode="expand"
                      value={props.vm.eventList.values}
                      sortField={props.vm.sortColumn}
                      sortOrder={props.vm.sortAscending ? 0 : -1}
                      emptyMessage={"No list for this Marketing Event."}
                    >
                      <Column
                        field={"listName"}
                        header={"List Name"}
                        sortable={false}
                      // style={{ width: "20%" }}
                      />
                      <Column
                        field={"sent"}
                        body={sentTemplate}
                        header={"Sent"}
                        sortable={false}
                        style={{ width: "10%" }}
                      />
                      <Column
                        field={"count"}
                        header={"Count"}
                        sortable={false}
                        style={{ width: "10%" }}
                      />
                      <Column
                        field={"displayStartDate"}
                        header={"Start At"}
                        body={startDateTemplate}
                        sortable={false}
                        style={{ width: "15%" }}
                      />
                      <Column
                        field={"displayEndDate"}
                        header={"Finish By"}
                        body={endDateTemplate}
                        sortable={false}
                        style={{ width: "15%" }}
                      />
                      <Column
                        body={actionTemplate}
                        header={""}
                        style={{ overflow: "visible", width: "120px" }}
                      />
                    </DataTable>
                  </div>
                </>
              )}
            </div>
            <Dialog
              className={`dialog-width`}
              header={props.vm.dialogTitle}
              onHide={() =>
                props.vm.setAddEditMarketingEventListVisibility(false, false)
              }
              visible={props.vm.isAddEditMarketingEventListVisible}
            >

              <AddEditMarketingEventListView
                vm={props.vm.AddEditMarketingEventListViewModel}
                eventId={props.vm.id}
              ></AddEditMarketingEventListView>

            </Dialog>
            <Dialog
              className={`dialog-width`}
              header={"Add Emails"}
              onHide={() => props.vm.setEmailListVisibility(false)}
              visible={props.vm.isEmailListVisible}
            >
              <AddEmailsView
                vm={props.vm.AddEmailsViewModel}
                eventListId={props.vm.selectedEventListId}
              ></AddEmailsView>
            </Dialog>
            <IMASConfirmationAlert
              value="Are you sure you want to delete this marketing event?"
              ok={() => props.vm.onDeleteEventClick()}
              cancel={() => props.vm.setDeleteEventConfirmation(false)}
              isVisible={props.vm.isDeleteEventConfirm}
              onHide={() => props.vm.setDeleteEventConfirmation(false)}
            />
            <IMASConfirmationAlert
              value="Are you sure you want to delete this event list?"
              ok={() => props.vm.onDeleteEventListClick()}
              cancel={() => props.vm.setDeleteEventListConfirmation(false)}
              isVisible={props.vm.isDeleteEventListConfirm}
              onHide={() => props.vm.setDeleteEventListConfirmation(false)}
            />
            <IMASConfirmationAlert
              value="Are you sure you want to clear this marketing list?"
              ok={() => props.vm.onRemoveAllEmailsClick()}
              cancel={() => props.vm.setRemoveAllEmailsConfirmation(false)}
              isVisible={props.vm.isRemoveAllEmailsConfirm}
              onHide={() => props.vm.setRemoveAllEmailsConfirmation(false)}
            />
            {/* </Card> */}
          </>
        ) : (
          <div className="p-col-12">
            <IMASMessagesHandler response={warning()}></IMASMessagesHandler>
          </div>
        )}
    </>
  );
});
